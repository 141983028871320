import { ComponentRef, forwardRef, useImperativeHandle, useState } from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Form, Input, Select, Switch } from "antd";
import { BannerUpload } from "ui";
import { getRand } from "core/utils/common-utils";
import { PRIORITIES_DATA } from "core/constants/statics";
import { getImageSrc } from "core/utils/json-utils";
import { bannerForm } from "../../type";

interface BannerFormProp {
  formData?: bannerForm;
}
interface RefHandler {
  getFormData: () => bannerForm;
  resetForm: () => void;
}
export type BannerFormRef = ComponentRef<typeof BannerForm>;

const BannerForm = forwardRef<RefHandler, BannerFormProp>(
  ({ formData }, ref) => {
    const [refreshKey, setRefreshKey] = useState("");

    const [form] = Form.useForm();

    const formik = useFormik<Partial<bannerForm>>({
      initialValues: {
        id: formData?.id,
        name: formData?.name,
        url: formData?.url,
        description1: formData?.description1,
        description2: formData?.description2,
        landingUrl: formData?.landingUrl,
        image: formData?.image,
        uploadImage: undefined,
        isActive: formData?.isActive,
        priority: formData?.priority,
      },
      enableReinitialize: true,
      onSubmit: () => {},
    });
    const { setFieldValue, values, resetForm } = formik;

    useImperativeHandle(ref, () => ({
      getFormData() {
        return values as bannerForm;
      },
      resetForm() {
        resetForm();
        setRefreshKey(`${getRand()}`);
        form.resetFields();
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Form form={form}>
          <Form.Item name="uploadImage" valuePropName="uploadImage">
            <BannerUpload
              key={`banner-${refreshKey}`}
              value={
                values.image?.url
                  ? {
                      width: values.image!.width,
                      height: values.image!.width,
                      preview: getImageSrc(values.image?.url, true),
                    }
                  : undefined
              }
              name="uploadImage"
              title="تصویر بنر"
              listType="picture-card"
              className="avatar-uploader"
              onBannerChange={(banner) => {
                setFieldValue("image", undefined);
                setFieldValue("uploadImage", banner);
              }}
            />
          </Form.Item>

          <Form.Item
            label="نام بنر"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="name"
            rules={[
              {
                required: true,
                message: "لطفا نام بنر را وارد کنید",
              },
            ]}
          >
            <Field name="name">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="نام بنر"
                  value={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="description1"
            label="توضیحات اول"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="description1">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="توضیحات اول"
                  value={values.description1}
                  onChange={(e) =>
                    setFieldValue("description1", e.target.value)
                  }
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="description2"
            label="توضیحات دوم"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="description2">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="توضیحات دوم"
                  value={values.description2}
                  onChange={(e) =>
                    setFieldValue("description2", e.target.value)
                  }
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="اولویت"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="priority"
          >
            <Select
              key={`priority-${refreshKey}`}
              placeholder="انتخاب کنید"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={PRIORITIES_DATA}
              onChange={(e) => setFieldValue("priority", e)}
              value={values.priority}
            />
          </Form.Item>
          <Form.Item
            label="لینک بنر"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="url"
            rules={[
              {
                required: true,
                message: "لطفا لینک بنر را وارد کنید",
              },
            ]}
          >
            <Field name="url">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لینک بنر"
                  value={values.url}
                  onChange={(e) => setFieldValue("url", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="لینک لندینگ بنر"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="landingUrl"
            rules={[
              {
                required: true,
                message: "لطفا لینک لندینگ بنر را وارد کنید",
              },
            ]}
          >
            <Field name="landingUrl">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لینک لندینگ بنر"
                  value={values.landingUrl}
                  onChange={(e) => setFieldValue("landingUrl", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="isActive"
            label="وضعیت بنر"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="isActive">
              {({ field }: FieldProps) => (
                <Switch
                  {...field}
                  checked={values.isActive}
                  onChange={(checked) => setFieldValue("isActive", checked)}
                />
              )}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>
    );
  }
);

export default BannerForm;
