import { ComponentRef, forwardRef, useImperativeHandle, useState } from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Flex, Form, Input, Select, Switch } from "antd";
import { DatePickerAntd, BannerUpload } from "ui";
import { getRand } from "core/utils/common-utils";
import { PRIORITIES_DATA } from "core/constants/statics";
import { getImageSrc } from "core/utils/json-utils";
import { toDate, toGregorian } from "core/utils/date-time";
import { sliderForm } from "../../type";

interface SliderFormProp {
  formData?: sliderForm;
}
interface RefHandler {
  getFormData: () => sliderForm;
  resetForm: () => void;
}
export type SliderFormRef = ComponentRef<typeof SliderForm>;

const SliderForm = forwardRef<RefHandler, SliderFormProp>(
  ({ formData }, ref) => {
    const [refreshKey, setRefreshKey] = useState("");

    const [form] = Form.useForm();

    const formik = useFormik<Partial<sliderForm>>({
      initialValues: {
        id: formData?.id,
        title: formData?.title ?? "",
        link: formData?.link ?? "",
        description: formData?.description ?? "",
        image: formData?.image,
        uploadImage: undefined,
        isPublished: formData?.isPublished ?? false,
        priority: formData?.priority,
        from: formData?.from ?? "",
        to: formData?.to ?? "",
      },
      enableReinitialize: true,
      onSubmit: () => {},
    });
    const { setFieldValue, values, resetForm } = formik;

    useImperativeHandle(ref, () => ({
      getFormData() {
        return values as sliderForm;
      },
      resetForm() {
        resetForm();
        form.resetFields();
        setRefreshKey(`${getRand()}`);
        form.resetFields();
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Form form={form}>
          <Form.Item name="uploadImage" valuePropName="uploadImage">
            <BannerUpload
              key={`slider-${refreshKey}`}
              value={
                values.image?.url
                  ? {
                      width: values.image!.width,
                      height: values.image!.width,
                      preview: getImageSrc(values.image?.url, true),
                    }
                  : undefined
              }
              name="uploadImage"
              title="تصویر اسلایدر"
              listType="picture-card"
              className="avatar-uploader"
              onBannerChange={(banner) => setFieldValue("uploadImage", banner)}
            />
          </Form.Item>

          <Form.Item
            label="نام اسلایدر"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="title"
            rules={[
              {
                required: true,
                message: "لطفا نام اسلایدر را وارد کنید",
              },
            ]}
          >
            <Field name="title">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="نام اسلایدر"
                  value={values.title}
                  onChange={(e) => setFieldValue("title", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name={"date"}
            label="مهلت نشر"
            labelCol={{ span: 5 }}
            labelAlign="left"
            style={{ margin: 0 }}
          >
            <Flex gap={10}>
              <Form.Item name="from" className="w-100">
                <DatePickerAntd
                  size="lg"
                  label="از:"
                  name={`from-${refreshKey}`}
                  placeholder={toDate(values.from ?? "")}
                  onChange={(e: any) =>
                    setFieldValue("from", toGregorian(e?.format()))
                  }
                />
              </Form.Item>
              <Form.Item name="to" className="w-100">
                <DatePickerAntd
                  size="lg"
                  label="تا:"
                  name={`to-${refreshKey}`}
                  placeholder={toDate(values.to ?? "")}
                  onChange={(e: any) =>
                    setFieldValue("to", toGregorian(e?.format()))
                  }
                />
              </Form.Item>
            </Flex>
          </Form.Item>
          <Form.Item
            name="description"
            label="توضیحات اسلایدر"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="description">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="توضیحات اسلایدر"
                  value={values.description}
                  onChange={(e) => setFieldValue("description", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            label="صفحه ی اسلایدر"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="priority"
            rules={[
              {
                required: true,
                message: "لطفا :صفحه ی اسلایدر را وارد کنید",
              },
            ]}
          >
            <Select
              key={`priority-${refreshKey}`}
              placeholder="انتخاب کنید"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={PRIORITIES_DATA}
              onChange={(e) => setFieldValue("priority", e)}
              value={values.priority}
            />
          </Form.Item>
          <Form.Item
            label="لینک محصول"
            labelCol={{ span: 5 }}
            labelAlign="left"
            name="link"
            rules={[
              {
                required: true,
                message: "لطفا لینک محصول را وارد کنید",
              },
            ]}
          >
            <Field name="link">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  placeholder="لینک محصول"
                  value={values.link}
                  onChange={(e) => setFieldValue("link", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="isPublished"
            label="وضعیت اسلایدر"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Field name="isPublished">
              {({ field }: FieldProps) => (
                <Switch
                  {...field}
                  checked={values.isPublished}
                  onChange={(checked) => setFieldValue("isPublished", checked)}
                />
              )}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>
    );
  }
);

export default SliderForm;
