import { Divider } from "antd";
import { MenuForm } from "./components/form";
import { MenuTable } from "./components/table";
import { EditModal } from "./components/edit-modal";

import styles from "./index.module.scss";
export const MenuSetting = () => {
  return (
    <div className={styles.menu_setting_container}>
      <h2>تعریف منو</h2>
      <Divider className="mb-6" />
      <MenuForm type="add" />
      <MenuTable />
      <EditModal />
    </div>
  );
};
