import { Button, Input } from "antd";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useForgotPassword } from "core/services/authentication/useForgotPassword";
import { ForgetPassSchema } from "./schema";

import "./index.scss";

export default function ForgetPassForm() {
  const {
    sendForgetOTP,
    forgetOTPLoading,
    confirmForgotPassword,
    confirmLoading,
  } = useForgotPassword();

  return (
    <Formik
      initialValues={{ mobileNumber: "" }}
      validationSchema={ForgetPassSchema}
      onSubmit={(values) => {
        sendForgetOTP(values.mobileNumber, {
          onSuccess: () => {},
        });

        // confirmForgotPassword({
        //   otp: 'string',
        //   mobileNumber: 'string',
        //   password: 'string',
        //   confirmPassword: 'string'
        // }, {
        //   onSuccess: () => {},
        // })
      }}
    >
      {({ isSubmitting }) => (
        <Form className="login-form">
          <div className="login-form__mobile_number">
            <Field
              name="mobileNumber"
              type="text"
              placeholder="شماره موبایل"
              as={Input}
            />
            <ErrorMessage
              name="mobileNumber"
              component="div"
              style={{
                fontSize: "12px",
                color: "red",
                textAlign: "right",
                width: "100%",
              }}
            />
          </div>

          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form__button"
            loading={isSubmitting}
          >
            ارسال کد به شماره موبایل
          </Button>
        </Form>
      )}
    </Formik>
  );
}
