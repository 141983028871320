import { Select as AntdSelect } from "antd";
import { SelectProps } from "antd/es/select";

export const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  placeholder = "لطفا انتخاب کنید",
  ...rest
}) => {
  return (
    <AntdSelect
      value={value}
      onChange={onChange}
      className={value ? "ant-select-has-value" : "ant-select-no-value"}
      placeholder={placeholder}
      {...rest}
    />
  );
};
