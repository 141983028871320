import { Row, Col, Flex } from "antd";
import { DUMMY_DATA } from "../api/dummy-data";
import { OrderDetailsBox } from "./order-details-box";
import { CustomerDetailsBox } from "./customer-details";
import { FileDetailsBox } from "./file-details";
import { Address } from "./address";
import { OrdersDetailsTable } from "./table";

import styles from "./index.module.scss";

export const OrderDetails = () => {
  return (
    <div>
      <Flex vertical gap={25}>
        <Row gutter={[25, 25]}>
          <Col span={8} className={styles.card}>
            <OrderDetailsBox
              id="094984111"
              type={"آنلاین"}
              date={"02/10/1402 02:50"}
              delivery={"باربری آذرخش"}
            />
          </Col>
          <Col span={8} className={styles.card}>
            <CustomerDetailsBox
              name={"علی کلاهدوزها"}
              email={"alikolahdozha@gmail.com"}
              phoneNumber={"09305429530"}
            />
          </Col>
          <Col span={8} className={styles.card}>
            <FileDetailsBox
              factorId={"#INV-094984111"}
              delivery={"#SHP-094984111"}
              point={"600"}
            />
          </Col>
        </Row>
        <Row gutter={[25, 25]}>
          <Col span={12} className={styles.address_card}>
            <Address
              address={
                "ایران تهران، خیابان آرژانتین، خیابان کرمان، میدان ابوذر، پلاک12 واحد 12 کد پستی: 664433535"
              }
              icon={"sr-map-placemarker"}
              title={"آدرس سفارش دهنده"}
            />
          </Col>
          <Col span={12} className={styles.address_card}>
            <Address
              address={
                "ایران تهران، خیابان آرژانتین، خیابان کرمان، میدان ابوذر، پلاک12 واحد 12 کد پستی: 664433535"
              }
              icon={"sr-truck-v2"}
              title={"آدرس حمل و نقل کالا"}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <OrdersDetailsTable data={DUMMY_DATA} />
          </Col>
        </Row>
      </Flex>
    </div>
  );
};
