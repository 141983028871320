import { AlignType } from "rc-table/lib/interface";
export const PRODUCTS_COLUMN = [
  {
    title: "محصول",
    dataIndex: "product",
    key: "product",
  },
  {
    title: "تعداد/مقدار",
    dataIndex: "count",
    key: "count",
    align: "end" as AlignType,
  },
];
