import { useState } from "react";
import { Button, Flex, Input, Modal, Select, Switch, Table } from "antd";
import { Icon } from "ui";
import { IconSearch } from "components/icons/iconSearch";
import styles from "./styles.module.scss";

const columns = [
  { dataIndex: "label", align: "right" },
  {
    dataIndex: "isEnabled",
    align: "left",
    render: (isEnabled) => <Switch checked={isEnabled} />,
  },
];

const permissionList = [
  { isEnabled: true, label: "دسترسی به پنل مالی" },
  { isEnabled: false, label: "دسترسی به پنل فروشگاه" },
  { isEnabled: true, label: "دسترسی به پنل مدیریت لندینگ ها" },
  { isEnabled: false, label: "دسترسی به پنل دسترسی" },
  { isEnabled: true, label: "دسترسی به پنل محصولات" },
];

const RoleManagementShowListModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  function openModal() {
    setIsModalOpen(true);
  }
  function closeModal() {
    setIsModalOpen(false);
  }
  return (
    <>
      <Button onClick={openModal} className={styles["button-primary"]}>
        افزودن دسترسی
        <Icon type="sr-plus" />
      </Button>

      <Modal
        title="لیست نقش ها"
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        width={650}
        footer={() => (
          <>
            <Button onClick={closeModal}>لغو</Button>
            <Button type="primary">ثبت</Button>
          </>
        )}
      >
        <Flex vertical className={styles["permission-table"]}>
          <Flex gap={12}>
            <Input
              size="large"
              className={styles["permission-search"]}
              placeholder="جستجوی نوع نقش"
              prefix={<IconSearch />}
            />
            <Select
              size="large"
              className={styles["permission-filter"]}
              placeholder="فیلتر"
              options={[{ label: "انتخاب شده ها", value: "1" }]}
            />
          </Flex>
          <Table
            showHeader={false}
            pagination={false}
            columns={columns}
            dataSource={permissionList}
          />
        </Flex>
      </Modal>
    </>
  );
};

export default RoleManagementShowListModal;
