type role =
  | "Seller"
  | "Designer"
  | "Content"
  | "Analyse"
  | "Service"
  | "Test"
  | "Analyist"
  | "Admin"
  | "Operator"
  | "Customer";
export const rolesDescription = (role: role | string) => {
  switch (role) {
    case "Seller":
      return "";
    case "Designer":
      return "بهترین برای طراحان و گرافیست های شرکت";
    case "Content":
      return "بهترین برای بوجود آوردن محتوا و بارگذاری فایل ها";
    case "Analyse":
      return "...بهترین برای بررسی کلی وبسایت / فروش/ سئو و";
    case "Service":
      return "";
    case "Test":
      return "";
    case "Analyist":
      return "";
    case "Admin":
      return "بهترین برای مشاغل مهم و مدیران شرکت";
    case "Operator":
      return "";
    case "Customer":
      return "";
    default:
      return "";
  }
};
