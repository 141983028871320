import { create } from "zustand";
import { filter, Key } from "interface";
import { bannerForm, bannerManagementFilter, bannersInfo } from "../type";
type BannerManagementStore = {
  isEdit: boolean;
  formData?: Partial<bannerForm>;
  headerInfo: bannersInfo;
  setHeaderInfo?: (info?: bannersInfo) => void;
  selectedBannersId?: Key[];
  filter?: filter<bannerManagementFilter>[];
  setIsEdit: (val: boolean) => void;
  setFormData: (formData?: bannerForm) => void;
  setFilter: (filter: filter<bannerManagementFilter>[]) => void;
  setSelectedBannersId: (value: Key[]) => void;
};

export const useBannerManagementStore = create<BannerManagementStore>((set) => {
  return {
    isEdit: false,
    setFormData: (value: Partial<bannerForm> | undefined) => {
      set({ formData: value });
    },
    headerInfo: {
      isActiveTotal: 0,
      totals: 0,
      totalSize: 0,
    },
    setHeaderInfo: (info: bannersInfo | undefined) => {
      set({ headerInfo: info });
    },
    setFilter: (value: filter<bannerManagementFilter>[]) => {
      set({ filter: value });
    },
    setSelectedBannersId: (value: Key[]) => {
      set({ selectedBannersId: value });
    },
    setIsEdit: (value: boolean) => {
      set({ isEdit: value });
    },
  };
});
