import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { USER_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";

import { userForm } from "../type";

const addUser = async ({ data }: { data: userForm }) => {
  const formData = new FormData();
  formData.append("FirstName", data.firstName ?? "");
  formData.append("LastName", data.lastName ?? "");
  formData.append("RoleId", data.roleId ?? "0");
  formData.append("MobileNumber", data.phoneNumber ?? "");
  formData.append("Email", data.email ?? "");
  console.log(data);
  if (data.avatar) {
    formData.append("Width", String(data.avatar.width));
    formData.append("Height", String(data.avatar.height));
    formData.append("File", data.avatar.file);
  }
  const res = await instance.post(USER_END_POINTS.add, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [USER_END_POINTS.add, USER_END_POINTS.list],
    mutationFn: addUser,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در ایجاد کاربر"}`);
    },
    onSuccess: () => {
      toast.success(`کاربر موفقیت آمیز اضافه شد`);
      queryClient.invalidateQueries({ queryKey: [USER_END_POINTS.list] });
    },
  });
};
