export const IconMessage = ({ size = 24, color = '#000000' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height={size}
    viewBox='0 0 18.893 19.179'
  >
    <path
      id='sr-chatbubble'
      d='M3.9,4.627C2.944,5.712,2.718,7.245,2.718,9.158a8.759,8.759,0,0,0,.938,4.636c.663,1.036,1.852,1.8,4.215,1.8a.859.859,0,0,1,.687.344l1.889,2.519,1.889-2.519a.859.859,0,0,1,.687-.344c2.362,0,3.552-.769,4.215-1.8a8.759,8.759,0,0,0,.938-4.636c0-1.913-.227-3.447-1.181-4.532-.939-1.067-2.778-1.909-6.548-1.909S4.838,3.56,3.9,4.627ZM2.61,3.492C4.032,1.875,6.487,1,10.446,1s6.414.875,7.837,2.492c1.407,1.6,1.61,3.715,1.61,5.666a10.371,10.371,0,0,1-1.209,5.562c-1,1.561-2.68,2.494-5.226,2.588l-1.638,2.184a1.718,1.718,0,0,1-2.748,0L7.435,17.309c-2.545-.094-4.227-1.028-5.226-2.588A10.37,10.37,0,0,1,1,9.158C1,7.207,1.2,5.091,2.61,3.492Z'
      transform='translate(-1 -1)'
      fill={color}
      fillRule='evenodd'
    />
  </svg>
);
