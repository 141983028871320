import { Input, Form, Flex } from "antd";
import { Field, FieldProps, useFormik, FormikProvider } from "formik";
import { Button } from "ui";

export const ProfileForm = () => {
  const [form] = Form.useForm();
  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form form={form} className="box">
        <h1 className="fs-small">پروفایل</h1>
        <br />
        <Form.Item
          name="name"
          label="نام"
          required
          labelCol={{ span: 3 }}
          labelAlign="left"
          wrapperCol={{ span: 22 }}
        >
          <Field name="name">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="email"
          label="ایمیل"
          required
          labelCol={{ span: 3 }}
          labelAlign="left"
          wrapperCol={{ span: 22 }}
        >
          <Field name="email">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="description"
          label="توضیحات"
          labelCol={{ span: 3 }}
          labelAlign="left"
          wrapperCol={{ span: 22 }}
        >
          <Field name="description">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Flex justify="flex-end">
          <Button size="large" type="success-75">
            ذخیره تغییرات
          </Button>
        </Flex>
      </Form>
    </FormikProvider>
  );
};
