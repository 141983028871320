import { Col, Row } from "antd";
import { DUMMY_DATA } from "../../api/dummy-data";
import { AddAdvertiseCard } from "./add-advertise";
import { CompaniesAdvertiseViewsCount } from "./companies-advertise-views-count";
import { AllAdvertiseViewsCard } from "./all-advertise-views-count";
import { ActiveBrandsWithAdvertise } from "./active-brans-with-advertise";

import styles from "../index.module.scss";

export const CardsContainer = () => {
  return (
    <div className={styles.cards_container}>
      <Row gutter={20}>
        <Col span={8}>
          <AddAdvertiseCard />
        </Col>
        <Col span={8}>
          <Row gutter={[20, 20]} className="d-flex flex-column h-100">
            <Col className="flex-1 ">
              <CompaniesAdvertiseViewsCount />
            </Col>
            <Col className="flex-1">
              <AllAdvertiseViewsCard />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row gutter={[20, 20]} className="d-flex flex-column h-100">
            <Col className="flex-1 ">
              <CompaniesAdvertiseViewsCount />
            </Col>
            <Col className="flex-1">
              <ActiveBrandsWithAdvertise data={DUMMY_DATA} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
