import { Flex } from "antd";
import { Button, Icon } from "ui";

export const PaymentMethod = () => {
  return (
    <div className="box">
      <Flex align="center" justify="space-between">
        <h1>روش های پرداختی</h1>
        <Button type="success-75" size="large">
          <Icon type="sr-plus-insquare" size={22}/>
          افزودن کارت جدید
        </Button>
      </Flex>
    </div>
  );
};
