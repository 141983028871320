export const IconImage = ({ size = 24, color = "#000000" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 22 22">
    <path
      id="sr-scenery"
      d="M1,4A3,3,0,0,1,4,1H20a3,3,0,0,1,3,3V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3ZM4,3A1,1,0,0,0,3,4V14.586l2.293-2.293a1,1,0,0,1,1.414,0L10,15.586l5.293-5.293a1,1,0,0,1,1.414,0L21,14.586V4a1,1,0,0,0-1-1ZM21,17.414l-5-5-5.293,5.293a1,1,0,0,1-1.414,0L6,14.414l-3,3V20a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1Z"
      transform="translate(-1 -1)"
      fill="#3f4254"
      fillRule="evenodd"
    />
  </svg>
);
