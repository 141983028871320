import { Card } from "antd";
import LoginForm from "../../forms/LoginForm";

export default function Login() {
  return (
    <Card className="auth__card" bordered={false}>
      <div className="auth__card__top">
        <h1 className="auth__card__top_title">ورود</h1>
        <p className="auth__card__top_content">ورود به پنل کاربری ادمین</p>
      </div>

      <LoginForm />
    </Card>
  );
}
