import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { ResponseDTO } from "interface";
import { SETTINGS_END_POINTS } from "core/constants/endpoints";

const getData = async (): Promise<ResponseDTO<any>> => {
  const res = await instance.get(SETTINGS_END_POINTS.get_terms);

  return res.data;
};

type OptionsType = UseQueryOptions<ResponseDTO<any>, unknown, ResponseDTO<any>>;
export const useGetTerms = (config?: OptionsType) => {
  return useQuery({
    queryKey: [SETTINGS_END_POINTS.get_terms],
    queryFn: () => getData(),
    ...config,
  });
};
