import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Select, Table, Typography } from "antd";
import { PaymentStateLabel } from "ui";
import { ORDERS_MANAGEMENT_COLUMNS } from "../../constant";

import styles from "../index.module.scss";
interface OrdersManagementTableProp {
  data: any;
}
export const OrdersManagementTable: React.FC<OrdersManagementTableProp> = ({
  data,
}) => {
  const navigate = useNavigate();
  const dataSource = useMemo(() => {
    return data?.map((item: any) => {
      return {
        buyer: item.buyer,
        buyerValue: item.buyer,
        offerAmount: (
          <Typography className="color-stroke">{item.offerAmount}</Typography>
        ),
        purchaseAmount: (
          <Typography className="color-stroke">{item.offerAmount}</Typography>
        ),
        releaseDate: (
          <Typography className="color-stroke">{item.releaseDate}</Typography>
        ),
        orderDate: (
          <Typography className="color-stroke">{item.orderDate}</Typography>
        ),
        changesDate: (
          <Typography className="color-stroke">{item.orderDate}</Typography>
        ),
        id: <strong className="color-stroke">{item.id}</strong>,
        status: (
          <Flex align="center" justify="center">
            <PaymentStateLabel state={item.status} />
          </Flex>
        ),

        purchaseAmountValue: item.offerAmount,
        offerAmountValue: item.offerAmount,
        idValue: item.id,
        releaseDateValue: item.releaseDate,
        orderDateValue: item.releaseDate,
        action: (
          <Select
            className={styles.table_select_container}
            placeholder={"عملیات"}
            onChange={(e) => {
              navigate(e);
            }}
            options={[
              {
                value: "/orders/orders_management/" + item.id,
                label: <label className="color-stroke">جزئیات</label>,
              },
              {
                value: "/orders/orders_management/" + item.id + "/edit",
                label: <label className="color-stroke">ویرایش</label>,
              },
            ]}
          />
        ),
      };
    });
  }, [data]);
  return <Table dataSource={dataSource} columns={ORDERS_MANAGEMENT_COLUMNS} />;
};
