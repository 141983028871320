import React, { CSSProperties } from "react";
import { icon } from "./types";

import "./style.css";
export interface IconProp {
  type: icon;
  className?: string;
  style?: CSSProperties;
  size?: number;
}

export const Icon: React.FC<IconProp> = ({
  type,
  className = "",
  style,
  size,
}) => {
  return (
    <span
      className={`icon-${type} ${className}`}
      style={{ fontSize: size, ...style }}
    ></span>
  );
};
