import { useMemo } from "react";
import { Button, Flex, Table, Typography } from "antd";
import { StatusLabel } from "ui";
import { Extended_TABLE_COLUMN } from "../../constant";

import styles from "../index.module.scss";

export const AdvertiseManagementTable = ({ data }: { data: any }) => {
  const dataSource = useMemo(() => {
    return data.map((item: any) => {
      return {
        nameValue: item.name,
        name: (
          <Flex gap={10} className={styles.name}>
            <img src="/assets/temp/user.png" alt="piza" />
            <Flex vertical align="start">
              <label className="color-primary">{item.name}</label>
              <label>{item.url}</label>
            </Flex>
          </Flex>
        ),
        clickCount: <div>{item.clickCount}</div>,
        rowColorStatus: <div>{item.status}</div>,
        status: (
          <Flex align="center" justify="center">
            <StatusLabel status={item.status} />
          </Flex>
        ),
        releaseDateValue: item.releaseDate,
        releaseDate: (
          <Typography className="text-danger">{item.releaseDate}</Typography>
        ),
        renewalDateValue: item.releaseDate,
        renewalDate: (
          <Typography className="text-danger">{item.releaseDate}</Typography>
        ),
        details: <Button>...</Button>,
      };
    });
  }, [data]);
  return (
    <Table
      dataSource={dataSource}
      columns={Extended_TABLE_COLUMN}
      rowClassName={(record) =>
        record.rowColorStatus ? styles.active : styles.deactive
      }
    />
  );
};
