import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { SETTINGS_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";

const updateData = async (data: { text: string }) => {
  const res = await instance.put(SETTINGS_END_POINTS.update_terms, data);

  return res.data;
};

export const useUpdateTerms = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      SETTINGS_END_POINTS.update_terms,
      SETTINGS_END_POINTS.get_terms,
    ],
    mutationFn: updateData,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در اعمال تغییرات"}`);
    },
    onSuccess: () => {
      toast.success(`تغییرات با موفقیت اعمال گردید`);
      queryClient.invalidateQueries({
        queryKey: [SETTINGS_END_POINTS.get_terms],
      });
    },
  });
};
