import { Flex, Button } from "antd";
import { Carousel } from "ui";
import { DUMMY_DATA_2 } from "../../api/dummy-data";

import styles from "../index.module.scss";
export const LatestAds = () => {
  return (
    <Flex vertical gap={20} className={styles.latest_ads_container}>
      <Flex justify="space-between" align="center" className={styles.last_ads}>
        <div>
          <h1>آخرین تبلیغات اضافه شده به سایت</h1>
          <h2 className="color-stroke">
            تعداد فایل های آپلود شده 328 , 2,6 گیگابایت فضای استفاده شده از سرور
          </h2>
        </div>
        <Button>مشاهده</Button>
      </Flex>
      <Carousel
        data={DUMMY_DATA_2.map((item) => {
          return {
            title: item.name,
            subTitle: `تعداد تبلیغات کلی این شرکت روی وبسایت ${item.clickCount} عدد`,
            ...item,
          };
        })}
        selectedItemId={(id) =>
          console.log(DUMMY_DATA_2.find((item) => item.id === id))
        }
      />
    </Flex>
  );
};
