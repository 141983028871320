import { useRef } from "react";
import { Modal, Button } from "ui";

import { useUpdateMenu } from "../../api/update-menu";
import { MenuForm, MenuFormRef } from "../form";
export const EditModal = () => {
  const formRef = useRef<MenuFormRef>(null);

  const { mutate: updateMutate, isPending } = useUpdateMenu();

  return (
    <Modal
      name="MENU_EDIT_MODAL"
      title={"اصلاح منو"}
      okButtonProps={{
        loading: false,
      }}
      footer={(onCancel, onOk) => (
        <>
          <Button
            size="large"
            onClick={() => {
              onCancel();
            }}
          >
            انصراف
          </Button>
          <Button
            type="primary"
            size="large"
            loading={isPending}
            onClick={() => {
              const data = formRef.current!.getFormData();
              updateMutate(data, {
                onSuccess: () => {
                  formRef.current?.resetForm();
                  onOk();
                },
              });
            }}
          >
            ذخیره
          </Button>
        </>
      )}
    >
      <MenuForm type="edit" key={"edit"} ref={formRef} />
    </Modal>
  );
};
