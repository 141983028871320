import { lazy } from "react";

export { default as DashboardLayout } from "components/layout/DashboardLayout";
export { default as ForgetPassword } from "components/common/forgetPassword/ForgetPassword";
export { default as Login } from "components/common/login/Login";

export { default as StoresManagementTable } from "components/common/StoresManagementTable";
export { default as ProductTable } from "components/common/ProductTable";
export { default as ProductCategoryTable } from "components/common/ProductCategory/ProductCategoryTable";
export { default as SpinnerFullPage } from "components/common/SpinnerFullPage";

export { default as ChangePassword } from "pages/auth/changePassword";
export { default as OtpCodeVerify } from "pages/auth/otpCodeVerify";

export { AdvertiseManagement } from "pages/section-management/advertise-management/components";
export { MenuSetting } from "pages/catalog/menu-setting";
export { UserManagement } from "pages/dashboard/user-management/components";
export { PrivacyManagement } from "pages/section-management/privacy-management/components";
export { TermsManagement } from "pages/section-management/terms-management/components";
export { AboutUsManagement } from "pages/section-management/about-us-management/components";
export { SEOManagement } from "pages/section-management/seo-management/components";
export { OrdersManagement } from "pages/orders/orders-management/components";
export { OrderDetails } from "pages/orders/order-details/components";
export { OrderEdit } from "pages/orders/order-edit/components";
export { VendeesManagement } from "pages/orders/vendees-management/components";
export { OverView } from "pages/dashboard/overview/components";
export { CustomersCarts } from "pages/orders/customers-carts/components";
export { RoleManagement } from "pages/dashboard/role-management/components";
export { SpecsList } from "pages/catalog/specs-list/components";
export { BannerManagement } from "pages/section-management/banners-management/components";
export const NotFoundPage = lazy(() => import("pages/not-found"));
export const AuthPage = lazy(() => import("pages/auth/index"));

export { Settings } from "pages/dashboard/setting/components";
export const AddProduct = lazy(() => import("pages/catalog/AddProduct/index"));
export const SeoDetail = lazy(
  () => import("pages/catalog/SeoDetail/index.jsx")
);
export const Landing = lazy(() => import("pages/catalog/Landing"));
export const AddLanding = lazy(() => import("pages/catalog/AddLanding/index"));
export const AddProductCategory = lazy(
  () => import("pages/catalog/AddProductCategory/index")
);
