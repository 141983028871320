import { Card } from "antd";
import ForgetPassForm from "../../forms/ForgetPassForm";

export default function ForgetPassword() {
  return (
    <Card className="auth__card" bordered={false}>
      <div className="auth__card__top">
        <h1 className="auth__card__top_title">بازیابی رمز عبور</h1>
      </div>

      <ForgetPassForm />
    </Card>
  );
}
