import { AlignType } from "rc-table/lib/interface";

export const USER_MANAGEMENT_COLUMN = [
  {
    title: "کاربران",
    dataIndex: "user",
    key: "user",
  },
  {
    title: "سِمَت",
    dataIndex: "roles",
    key: "roles",
    align: "center" as AlignType,
  },
  {
    title: "اخرین ورود",
    dataIndex: "lastLoginDate",
    key: "lastLoginDate",
    align: "center" as AlignType,
  },
  {
    title: "خروج",
    dataIndex: "lastLogoutDate",
    key: "lastLogoutDate",
    align: "center" as AlignType,
  },
  {
    title: "ورود دو عاملی",
    dataIndex: "twoFactorLogin",
    key: "twoFactorLogin",
    align: "center" as AlignType,
  },

  {
    title: "تاریخ ثبت کاربر",
    dataIndex: "registerDate",
    key: "registerDate",
    align: "center" as AlignType,
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
  },
];
