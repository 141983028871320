import { Flex, Spin } from "antd";
import "./index.scss";
function SpinnerFullPage() {
  return (
    <Flex align="center" justify="center" className="container">
      <Spin size="large" />
    </Flex>
  );
}

export default SpinnerFullPage;
