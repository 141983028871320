import { useMutation } from "@tanstack/react-query";
import { ROLE_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { toast } from "react-toastify";

export const useMutateRole = () => useMutation({
    mutationFn: (data: any) => data.id ? instance.put(ROLE_END_POINTS.update, {
        ...data
    }) : instance.post(ROLE_END_POINTS.add, {
        ...data
    }),
    onError: (e: any) => {
        toast.error(`${e.response?.data?.Message || "خطا در ایجاد نقش"}`);
    }
})