import React, { useEffect, useState } from "react";
import { Upload, UploadFile, UploadProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { RcFile } from "antd/lib/upload";
interface FileWithDimensions extends File {
  width?: number;
  height?: number;
}
export type AvatarType = FileWithDimensions[] | undefined;

interface AvatarUploadProps extends UploadProps {
  name: string;
  label?: React.ReactNode;
  url?: string;
}

export const AvatarUpload: React.FC<AvatarUploadProps> = ({
  onChange,
  name,
  label = <UserOutlined />,

  url,
  ...rest
}) => {
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  useEffect(() => {
    if (url) {
      const file: UploadFile = {
        uid: String(Date.now()), // Unique uid
        name: "avatar.jpg",
        status: "done",
        url: url,
        lastModified: Date.now(),
        lastModifiedDate: new Date(),
        originFileObj: new File([url], "avatar.jpg", {
          type: "image/jpeg",
        }) as RcFile,
      };
      setFileList([file]);
    } else {
      setFileList([]);
    }
  }, [url]);

  return (
    <Upload
      name={name}
      fileList={fileList}
      onChange={(e) => {
        setFileList(e.fileList);
        onChange?.(e);
      }}
      beforeUpload={(file) => {
        const isLegitimate =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/webp";
        if (!isLegitimate) {
          toast.error("فقط فایل های JPG/WebP/PNG مجاز هستند");
        }
        return isLegitimate || Upload.LIST_IGNORE;
      }}
      customRequest={(ops) => {
        ops.onSuccess?.(<></>);
      }}
      {...rest}
    >
      {label}
    </Upload>
  );
};
