import { Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Flex, Table } from "antd";
import { CopyButton, ActionButton } from "ui";
import { SEO_MANAGEMENT_ENDPOINTS } from "core/constants/endpoints";

import { useAppStore } from "store";
import { MENU_SETTING_TABLE_COLUMN } from "../../constant";
import { MenuManagementType } from "../../type";
import { useMenuSettingStore } from "../../store";
import { useListMenus } from "../../api/list-menus";
import { useDeleteMenu } from "../../api/delete-menu";

export const MenuTable = () => {
  const [page, setPage] = useState(1);

  const { setModalVisibility } = useAppStore((state) => state);
  const { setEditedItem } = useMenuSettingStore();

  const { data, isPending } = useListMenus({
    queryKey: [{ page }, SEO_MANAGEMENT_ENDPOINTS.list],
  });

  const { mutate: deleteMenu } = useDeleteMenu();

  const list = useMemo(() => {
    return (data?.data?.data ?? []).map(
      (item: MenuManagementType, index: number) => {
        return {
          index: <center>{index + 1}</center>,
          name: (
            <div>
              <Link to={"#"}>{item.name}</Link>&nbsp;
              <span>{item.name}</span>
              {item.parentName && (
                <Fragment>
                  <span>/</span>
                  <span>{item.parentName}</span>
                </Fragment>
              )}
            </div>
          ),
          url: (
            <Flex gap={45} className="color-stroke">
              {item.url ?? "-"}
              <CopyButton value={item.url} />
            </Flex>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="edit"
                onClick={() => {
                  setEditedItem(item);
                  setModalVisibility("MENU_EDIT_MODAL");
                }}
              />
              <ActionButton
                type="remove"
                onClick={() => deleteMenu(item.id!)}
              />
            </Flex>
          ),
        };
      }
    );
  }, [data]);

  return (
    <Table
      dataSource={list}
      columns={MENU_SETTING_TABLE_COLUMN}
      loading={isPending}
      className="mt-4"
      rowKey={"id"}
      pagination={{
        showSizeChanger: false,
        total: data?.data.totals,
        onChange: (page) => {
          setPage(page);
        },
      }}
    />
  );
};
