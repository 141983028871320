export const DUMMY_DATA = [
  {
    name: "محصول شماره 1",
    price: "240,000,000",
    count: 15,
    img: "/assets/temp/user.png",
    id: "26464das1064",
  },
  {
    name:"محصول شماره 2",
    price: "240,000,000",
    count: 15,
    img: "/assets/temp/user.png",
    id: "26464163064",
  },
  {
    name: "محصول شماره 3",
    price: "240,000,000",
    count: 5,
    img: "/assets/temp/user.png",
    id: "264641asdas064",
  },
  {
    name:"محصول شماره 4",
    price: "240,000,000",
    count: 10,
    img: "/assets/temp/user.png",
    id: "264641342064",
  },
];
