import React from "react";
import { Button as AntdButton, ButtonProps } from "antd";

import styles from "./index.module.scss";
import classNames from "classnames";
import { IconEye } from "components/icons/iconEye";
import { IconDelete } from "components/icons/iconDelete";
import { IconEditPencil } from "components/icons/iconEditPencil";

interface actionButtonProp extends Omit<ButtonProps, "type"> {
  type?: "view" | "remove" | "edit";
  ref?: any;
}
export const ActionButton: React.FC<actionButtonProp> = ({
  children,
  className,
  type = "view",
  disabled,
  ...rest
}) => {
  const ButtonClassName = classNames(className, [
    disabled && styles["action-disabled"],
    type === "view" && styles["view"],
    type === "remove" && styles["remove"],
    type === "edit" && styles["edit"],
  ]);
  return (
    <AntdButton disabled={disabled} className={ButtonClassName} {...rest}>
      {type === "view" && <IconEye />}
      {type === "remove" && <IconDelete color="#F5222D" />}
      {type === "edit" && <IconEditPencil />}
    </AntdButton>
  );
};
