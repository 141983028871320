import { useEffect, useMemo, useState } from "react";
import { Flex, Table } from "antd";
import {
  BrandAvatar,
  CopyButton,
  StatusLabel,
  ActionButton,
  FileSizeLabel,
} from "ui";
import { useAppStore } from "store";
import { useSliderManagementStore } from "../../store";
import { useDeleteSlider, useGetSliders } from "../../api";
import { SLIDER_MANAGEMENT_COLUMN } from "../../constant";
import { slider } from "../../type";
import { toDate, toDateTime } from "core/utils/date-time";
import { getImageSrc } from "core/utils/json-utils";

const rowSelection = {
  onChange: () => {},
};

const SliderManagementTable = () => {
  const [page, setPage] = useState(1);

  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setHeaderInfo, setFormData, setIsEdit, filter } =
    useSliderManagementStore((state) => state);

  const { data, isPending } = useGetSliders({
    page: page,
    pageSize: 10,
    inputParams: {
      filters: filter,
      sort: {
        propertyName: "id",
        ascending: false,
      },
    },
  });
  const { mutate: deleteSliderMutation } = useDeleteSlider();

  useEffect(() => {
    if (data?.data)
      setHeaderInfo?.({
        totals: data.data.gridDataSourceResult.totals,
        isActiveTotal: data.data.isActiveTotal,
        totalSize: data.data.totalSize,
      });
  }, [data?.data]);

  const dataSource = useMemo(() => {
    return (
      (data?.data?.gridDataSourceResult?.data ?? []).map((item: slider) => {
        return {
          key: item.id,
          image: (
            <BrandAvatar
              img={getImageSrc(item.image?.url, true)}
              label={item.title}
            />
          ),
          title: (
            <Flex vertical align="start" gap="5px">
              <strong className="color-primary">{item?.title ?? "-"}</strong>
              <label className="color-stroke">
                تاریخ ثبت: {toDateTime(item?.date)}
              </label>
            </Flex>
          ),
          date: (
            <Flex vertical gap="5px">
              <strong className="color-stroke">
                از تاریخ: {toDate(item?.from)}
              </strong>
              <strong className="color-stroke">
                تا تاریخ: {toDate(item?.to)}
              </strong>
            </Flex>
          ),
          size: <FileSizeLabel value={item?.image?.fileBytes} />,
          link: <CopyButton value={item.link} />,
          status: (
            <Flex align="center" justify="center">
              <StatusLabel status={item.isPublished} />
            </Flex>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="remove"
                onClick={() => deleteSliderMutation(item.id)}
              />
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setIsEdit(true);
                  setModalVisibility("MAIN_SLIDERS_MANAGEMNT_MODAL");
                }}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={SLIDER_MANAGEMENT_COLUMN}
      dataSource={dataSource}
      rowSelection={rowSelection}
      loading={isPending}
      pagination={{
        showSizeChanger: false,
        total: data?.data?.gridDataSourceResult?.totals ?? 0,
        onChange: setPage,
      }}
    />
  );
};

export default SliderManagementTable;
