import { Space } from "antd";
import { ActionBar } from "./actionbar";
import Header from "./header";
import BannerManagementModal from "./modal";
import BannerManagementTable from "./table";

export const BannerManagement = () => {
  return (
    <div>
      <Space direction="vertical" size="large" className="w-100">
        <Header />
        <ActionBar />
      </Space>
      <BannerManagementTable />
      <BannerManagementModal />
    </div>
  );
};
