import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { TECH_SPEC_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { specType } from "../type";

const addSpec = async ({ data }: { data: specType }) => {
  const res = await instance.post(TECH_SPEC_ENDPOINTS.add, {
    ...data,
  });

  return res.data;
};

export const useAddTechnicalSpecification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [TECH_SPEC_ENDPOINTS.add, TECH_SPEC_ENDPOINTS.list],
    mutationFn: addSpec,
    onSuccess: () => {
      toast.success("ویژگی با موفقیت ایجاد شد");
      queryClient.invalidateQueries([
        TECH_SPEC_ENDPOINTS.list,
      ] as InvalidateQueryFilters);
    },
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data?.Message || "خطا در ایجاد ویژگی"}`);
    },
  });
};
