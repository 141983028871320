import { Col, Flex, Row } from "antd";
import { Icon } from "ui";
import { DUMMY_DATA } from "../../api";
import { TransactionsTable } from "./transactions-table";

export const OverView = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={8} className="box">
        <Flex vertical gap={17}>
          <h1>امتیاز کاربر</h1>
          <Flex gap={14} align="center">
            <Icon className="text-warning" type="sr-crown" size={24} />
            <Flex vertical>
              <Flex gap={7}>
                <h1>452</h1>
                <label className="color-stroke">امتیاز به دست آمده</label>
              </Flex>
              <label className="color-stroke fs-x-small">
                با هر خرید امتیاز جایزه بگیرید.
              </label>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col span={24}>
        <TransactionsTable data={DUMMY_DATA} />
      </Col>
    </Row>
  );
};
