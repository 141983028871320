import { AlignType } from "rc-table/lib/interface";
export const TRANSACTION_TABLE_COLUMN = [
  {
    title: "شماره فاکتور",
    dataIndex: "id",
    key: "id",
    align: "center" as AlignType,
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
  },
  {
    title: "مبلغ پرداختی ( تومان)",
    dataIndex: "price",
    key: "price",
    align: "center" as AlignType,
  },
  {
    title: "امتیاز دریافتی",
    dataIndex: "pint",
    key: "pint",
    align: "center" as AlignType,
  },
  {
    title: "تاریخ",
    dataIndex: "date",
    key: "date",
    align: "center" as AlignType,
  },
];
