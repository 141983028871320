import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { DASHBOARD_END_POINTS } from 'core/constants/endpoints';
import instance from 'core/constants/request';

type QueryOptionsWithoutQueryFn = Omit<UseQueryOptions<any>, 'queryFn'>

export const useGetLatestContents = (options?: QueryOptionsWithoutQueryFn) =>
    useQuery<any>({
        retry: 0,
        ...(options ?? {}),
        queryFn: async () => {
            const result = await instance.get(DASHBOARD_END_POINTS.last_contents)

            return result.data?.data ?? {};
        },
        queryKey: options?.queryKey ? [DASHBOARD_END_POINTS.last_contents, ...options.queryKey] : [DASHBOARD_END_POINTS.last_contents]
    })
