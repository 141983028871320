import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { SimpleResponseDTO } from "interface";
import { SETTINGS_END_POINTS } from "core/constants/endpoints";
import { AboutUsType } from "../type";

const getData = async (): Promise<SimpleResponseDTO<AboutUsType>> => {
  const res = await instance.get(SETTINGS_END_POINTS.get_aboutus);

  return res.data;
};

type OptionsType = UseQueryOptions<
  SimpleResponseDTO<AboutUsType>,
  unknown,
  SimpleResponseDTO<AboutUsType>
>;
export const useGetAboutUs = (config?: OptionsType) => {
  return useQuery({
    queryKey: [SETTINGS_END_POINTS.get_aboutus],
    queryFn: () => getData(),
    ...config,
  });
};
