import { ComponentRef, forwardRef, useImperativeHandle } from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { Form, Input, Switch } from "antd";
import { specType } from "../../type";

interface SpecFormProp {
  formData?: specType;
}
interface RefHandler {
  getFormData: () => specType;
  resetForm: () => void;
}
export type SpecFormRef = ComponentRef<typeof SpecForm>;

const SpecForm = forwardRef<RefHandler, SpecFormProp>(({ formData }, ref) => {
  const [form] = Form.useForm();

  const formik = useFormik<Partial<specType>>({
    initialValues: {
      id: formData?.id,
      name: formData?.name,
      description: formData?.description,
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });
  const { setFieldValue, values, resetForm } = formik;

  useImperativeHandle(ref, () => ({
    getFormData() {
      return values as specType;
    },
    resetForm() {
      resetForm();
    },
  }));

  return (
    <FormikProvider value={formik}>
      <Form form={form}>
        <Form.Item
          label="نام ویژگی"
          labelCol={{ span: 5 }}
          labelAlign="left"
          name="name"
          rules={[
            {
              required: true,
              message: "لطفا نام ویژگی را وارد کنید",
            },
          ]}
        >
          <Field name="name">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="نام ویژگی"
                value={values.name}
                onChange={(e) => setFieldValue("name", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="توضیحات ویژگی"
          labelCol={{ span: 5 }}
          labelAlign="left"
          name="description"
        >
          <Field name="description">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="توضیحات ویژگی"
                value={values.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>
      </Form>
    </FormikProvider>
  );
});

export default SpecForm;
