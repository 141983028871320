import { InvalidateQueryFilters, useMutation, useQueryClient } from "@tanstack/react-query";
import { USER_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { toast } from "react-toastify";

export const useBlockUser = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (id: any) => instance.post(`${USER_END_POINTS.block}/${id}`),
        onSuccess: (res) => {
            queryClient.invalidateQueries([USER_END_POINTS.list] as InvalidateQueryFilters);
            toast.success(`کاربر با موفقیت مسدود گردید`);
        },
        onError: (e: any) => {
            toast.error(`${e.response?.data?.Message || "خطا در مسدود کردن کاربر"}`);
        }
    })
}