import React from "react";
import { Divider, Flex, Typography } from "antd";
import { Icon } from "ui";

interface OrderDetailsBoxProp {
  type: string;
  date: string;
  delivery: string;
  id: string;
}
export const OrderDetailsBox: React.FC<OrderDetailsBoxProp> = ({
  type,
  date,
  delivery,
  id,
}) => {
  return (
    <div className="box h-100">
      <h1>جزئیات سفارش شماره {id}</h1>
      <br />
      <br />
      <Flex className="color-stroke" justify="space-between">
        <Flex align="center" gap={10}>
          <Icon type="sr-Calendar-v3" size={21} />
          <Typography className="color-stroke"> تاریخ ثبت سفارش : </Typography>
        </Flex>
        <Typography className="color-stroke"> {date} </Typography>
      </Flex>
      <Divider />
      <Flex className="color-stroke" justify="space-between">
        <Flex align="center" gap={10}>
          <Icon type="sr-wallet-bifold" size={21} />
          <Typography className="color-stroke"> روش پرداخت : </Typography>
        </Flex>
        <Typography className="color-stroke"> {type} </Typography>
      </Flex>
      <Divider />
      <Flex className="color-stroke" justify="space-between">
        <Flex align="center" gap={10}>
          <Icon type="sr-truck-v2" size={21} />
          <Typography className="color-stroke"> تاریخ ثبت سفارش : </Typography>
        </Flex>
        <Typography className="color-stroke"> {delivery} </Typography>
      </Flex>
    </div>
  );
};
