import React, { useMemo } from "react";
import { Flex, Table, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Icon, Select } from "ui";
import { CUSTOMERS_CART_TABLE_COLUMN } from "../../constant";

interface CustomersCartsTableProp {
  data: any;
}
export const CustomersCartsTable: React.FC<CustomersCartsTableProp> = ({
  data,
}) => {
  const navigate = useNavigate();
  const dataSource = useMemo(() => {
    return data?.map((item: any) => {
      return {
        buyer: item.buyer,
        buyerValue: item.buyer,
        offerAmount: (
          <Typography className="color-stroke">{item.offerAmount}</Typography>
        ),
        purchaseAmount: (
          <Typography className="color-stroke">{item.offerAmount}</Typography>
        ),
        changesDate: (
          <Typography className="color-stroke">{item.orderDate}</Typography>
        ),
        id: <strong className="color-stroke">{item.id}</strong>,
        idValue: item.id,
        listOfItems: (
          <Flex justify="center" gap={8} align="center">
            <Icon type="sr-eye-open" size={24} className="color-stroke" />
            <Link to={"orders/customers-cart/" + item.id}> مشاهده</Link>
          </Flex>
        ),
        action: (
          <Select
            placeholder={"عملیات"}
            onChange={(e) => {
              navigate(e);
            }}
            options={[
              {
                value: "/orders/customers_carts/" + item.id,
                label: <label className="color-stroke">جزئیات</label>,
              },
              {
                value: "/orders/customers_carts/" + item.id + "/edit",
                label: <label className="color-stroke">ویرایش</label>,
              },
            ]}
          />
        ),
      };
    });
  }, [data]);
  return (
    <Table dataSource={dataSource} columns={CUSTOMERS_CART_TABLE_COLUMN} />
  );
};
