import "./index.scss";

export default function SettingSectionsLayout({ title, children }) {
  return (
    <section className="section-container">
      <h2>{title}</h2>
      <div className="section">{children}</div>
    </section>
  );
}
