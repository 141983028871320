import { create } from "zustand";
import { filter, Key } from "interface";
import { user, userForm, userManagementFilter } from "../type";
type UserManagementStore = {
  isEdit: boolean;
  formData?: userForm;
  selectedUsersId?: Key[];
  filter?: filter<userManagementFilter>[];
  setIsEdit: (val: boolean) => void;
  setFormData: (formData?: userForm) => void;
  setFilter: (filter: filter<userManagementFilter>[]) => void;
  setSelectedUsersId: (value: Key[]) => void;
};

export const useUserManagementStore = create<UserManagementStore>((set) => {
  return {
    isEdit: false,
    setFormData: (value: userForm | undefined) => {
      set({ formData: value });
    },
    setFilter: (value: filter<userManagementFilter>[]) => {
      set({ filter: value });
    },
    setSelectedUsersId: (value: Key[]) => {
      set({ selectedUsersId: value });
    },
    setIsEdit: (value: boolean) => {
      set({ isEdit: value });
    },
  };
});
