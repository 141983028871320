import { create } from "zustand";

type specsListStore = {
  isEdit: boolean;
  formData?: any;
  setIsEdit: (val: boolean) => void;
  setFormData: (formData?: any) => void;
};

export const useSpecsListStore = create<specsListStore>((set) => {
  return {
    isEdit: false,
    setFormData: (value: any | undefined) => {
      set({ formData: value });
    },
    setIsEdit: (value: boolean) => {
      set({ isEdit: value });
    },
  };
});
