import { useMemo, useState } from "react";
import { Flex, Table } from "antd";
import {
  BrandAvatar,
  CopyButton,
  StatusLabel,
  ActionButton,
  FileSizeLabel,
} from "ui";
import { toDateTime } from "core/utils/date-time";
import { getImageSrc } from "core/utils/json-utils";
import { useAppStore } from "store";
import { useBannerManagementStore } from "../../store";
import { useDeleteBanner, useGetBanners } from "../../api";
import { SLIDER_MANAGEMENT_COLUMN } from "../../constant";
import { banner } from "../../type";

const rowSelection = {
  onChange: () => {},
};

const BannerManagementTable = () => {
  const [page, setPage] = useState(1);

  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setHeaderInfo, setFormData, setIsEdit, filter } =
    useBannerManagementStore((state) => state);

  const { data, isPending } = useGetBanners({
    page: page,
    pageSize: 10,
    inputParams: {
      filters: filter,
      sort: {
        propertyName: "id",
        ascending: false,
      },
    },
  });
  const { mutate: deleteBannerMutation } = useDeleteBanner();

  const dataSource = useMemo(() => {
    return (
      (data?.data?.data ?? []).map((item: banner) => {
        return {
          key: item.id,
          image: (
            <BrandAvatar
              img={getImageSrc(item.image?.url, true)}
              label={item.name}
            />
          ),
          title: (
            <Flex vertical align="start" gap="5px">
              <strong className="color-primary">{item?.name ?? "-"}</strong>
              <label className="color-stroke">
                تاریخ ثبت: {toDateTime(item?.date)}
              </label>
            </Flex>
          ),
          page: <strong className="color-primary">-</strong>,
          size: <FileSizeLabel value={item?.image?.fileBytes} />,
          link: <CopyButton value={item.url} />,
          status: (
            <Flex align="center" justify="center">
              <StatusLabel status={item.isActive} />
            </Flex>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="remove"
                onClick={() => deleteBannerMutation(item.id)}
              />
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setIsEdit(true);
                  setModalVisibility("MAIN_BANNERS_MANAGEMENT_MODAL");
                }}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={SLIDER_MANAGEMENT_COLUMN}
      dataSource={dataSource}
      rowSelection={rowSelection}
      loading={isPending}
      pagination={{
        showSizeChanger: false,
        total: data?.data?.totals ?? 0,
        onChange: setPage,
      }}
    />
  );
};

export default BannerManagementTable;
