import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { SEO_MANAGEMENT_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { SimpleResponseDTO } from "interface";
import { menuParentType } from "../type";

type OptionsType = UseQueryOptions<
  SimpleResponseDTO<menuParentType[]>,
  unknown,
  SimpleResponseDTO<menuParentType[]>
>;

const listFn = async (): Promise<SimpleResponseDTO<menuParentType[]>> => {
  const res = await instance.get(SEO_MANAGEMENT_ENDPOINTS.flat);

  return res.data;
};

export const useListMenuParents = (options?: OptionsType) =>
  useQuery({
    queryKey: [SEO_MANAGEMENT_ENDPOINTS.flat],
    queryFn: listFn,
    ...options,
  });
