import { Modal as AntdModal, ModalProps } from "antd";
import React, { useEffect } from "react";

import { useAppStore } from "store";

interface CustomModalProps extends Omit<ModalProps, "footer"> {
  name?: string;
  footer: (cancel: () => void, ok: () => void) => React.ReactNode;
  onModalCancel?: () => void;
}

export const Modal: React.FC<CustomModalProps> = ({
  name,
  children,
  onModalCancel,
  onClose,
  footer,
  ...rest
}) => {
  const { modalvisibilty, setModalVisibility } = useAppStore((state) => state);

  return (
    <AntdModal
      open={modalvisibilty === name}
      width={650}
      onCancel={(e) => {
        onClose?.(e);
        setModalVisibility(null);
      }}
      footer={footer(
        () => {
          setModalVisibility(null);
        },
        () => {
          setModalVisibility(null);
        }
      )}
      {...rest}
    >
      {children}
    </AntdModal>
  );
};
