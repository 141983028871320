import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Flex } from "antd";
import { Icon } from "ui";
import styles from "./index.module.scss";
interface CounterButtonProp {
  maxValue?: number;
  countValue: (CounterButton: number) => void;
}
export const CounterButton: React.FC<CounterButtonProp> = ({
  maxValue,
  countValue,
}) => {
  const [counterButtonValue, setCounterButtonValue] = useState(1);
  useEffect(() => {
    countValue(counterButtonValue);
  }, [counterButtonValue]);

  return (
    <Flex gap={26}>
      <button
        className={styles.btn}
        onClick={() => {
          if (maxValue) {
            if (counterButtonValue >= maxValue) {
            } else {
              setCounterButtonValue((pre) => pre + 1);
            }
          } else {
            setCounterButtonValue((pre) => pre + 1);
          }
        }}
        disabled={maxValue ? counterButtonValue >= maxValue : false}
      >
        <Icon type="sr-plus" />
      </button>
      <strong className="color-stroke"> {counterButtonValue}</strong>
      <button
        className={classNames([styles.btn])}
        onClick={() => {
          setCounterButtonValue((pre) => pre - 1);
         
        }}
        disabled={counterButtonValue <= 0}
      >
        <Icon type="sr-minus" />
      </button>
    </Flex>
  );
};
