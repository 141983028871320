import { ReactNode } from "react";
import { ConfigProvider } from "antd";
export const AntdProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#30B9D3",
          colorLink: "#30B9D3",
        },
        components: {
          Button: {
            defaultBg: "#F5F8FA",
            defaultActiveBg: "#F5F8FA",
            defaultHoverBg: "#F5F8FA",
            defaultColor: "#B5B5C3",
            defaultBorderColor: "#F5F8FA",
          },
          Input: {
            controlHeight: 40,
            fontSizeLG: 14,
            controlHeightLG: 46,
            colorBorder: "#F5F8FA",
            colorBgContainer: "#F9F9F9",
          },
          Checkbox: {
            colorBgContainer: "#F3F6F9",
            colorBorder: "#F3F6F9",
            controlInteractiveSize: 24,
            borderRadius: 6,
            
          },
          Select: {
            controlHeight: 40,
            controlHeightLG: 46,
            selectorBg: "#F9F9F9",
            colorBorder: "#F5F8FA",
            optionSelectedColor: "#3F4254",
            optionSelectedBg: "#e6f7ff",
          },
          Form: {
            labelColor: "var(--font-color-primary)",
          },

          Table: {
            headerColor: "var(--font-color-stroke)",
            rowHoverBg: "inherit",
          },
        },
        cssVar: true,
      }}
    >
      {children}
    </ConfigProvider>
  );
};
