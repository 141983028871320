import { Col, Row } from "antd";
import { ProfileForm } from "./profile-form";
import { Addresses } from "./addresses";

export const Setting = () => {
  return (
    <Row gutter={[25, 15]}>
      <Col span={24}>
        <ProfileForm />
      </Col>
      <Col span={24}>
        <Addresses />
      </Col>
    </Row>
  );
};
