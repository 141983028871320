import { Tabs } from "antd";
import { DUMMY_DATA } from "../api/dummy-data";
import { AdvertiseManagementTable } from "./table";
import { CardsContainer } from "./cards";
import { LatestAds } from "./latest-ads";
import { AdvertiseModal } from "./modal";

import styles from "./index.module.scss";
 
export const AdvertiseManagement = () => {
  const tabs = [
    {
      key: "extended",
      label: "تمدید شده ها",
      children: <AdvertiseManagementTable data={DUMMY_DATA} />,
    },
    {
      key: "expired",
      label: "منقضی شده ها",
      children: <AdvertiseManagementTable data={DUMMY_DATA} />,
    },
    {
      key: "active",
      label: "فعال",
      children: <AdvertiseManagementTable data={DUMMY_DATA} />,
    },
    {
      key: "running_out",
      label: "رو به اتمام",
      children: <AdvertiseManagementTable data={DUMMY_DATA} />,
    },
  ];
  return (
    <div className={styles.advertise_management}>
      <CardsContainer />
      <br />
      <div className={styles.table_container}>
        <LatestAds />
        <Tabs className={styles.tabs} defaultActiveKey="extended" items={tabs} />
      </div>
      <AdvertiseModal />
    </div>
  );
};
