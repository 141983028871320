export const IconBook = ({ size = 24, color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox="0 0 15.439 14.958"
  >
    <g id="sr-book" transform="translate(-1 -1.686)">
      <path
        id="Path_1457"
        name="Path 1457"
        d="M17.679,7.383a.7.7,0,0,0-.34-1.362l-2.807.7a.7.7,0,0,0,.34,1.362Z"
        transform="translate(-3.877 -1.286)"
        fill={color}
      />
      <path
        id="Path_1458"
        name="Path 1458"
        d="M17.679,11.383a.7.7,0,0,0-.34-1.362l-2.807.7a.7.7,0,0,0,.34,1.362Z"
        transform="translate(-3.877 -2.479)"
        fill={color}
      />
      <path
        id="Path_1459"
        name="Path 1459"
        d="M4.021,10.532a.7.7,0,0,1,.851-.511l2.807.7a.7.7,0,0,1-.34,1.362l-2.807-.7A.7.7,0,0,1,4.021,10.532Z"
        transform="translate(-0.895 -2.479)"
        fill={color}
      />
      <path
        id="Path_1460"
        name="Path 1460"
        d="M4.021,6.532a.7.7,0,0,1,.851-.511l2.807.7a.7.7,0,0,1-.34,1.362l-2.807-.7A.7.7,0,0,1,4.021,6.532Z"
        transform="translate(-0.895 -1.286)"
        fill={color}
      />
      <path
        id="Path_1461"
        name="Path 1461"
        d="M1,3.793A2.105,2.105,0,0,1,3.71,1.776l5.009,1.5,5.009-1.5a2.105,2.105,0,0,1,2.71,2.017v9.6a2.105,2.105,0,0,1-1.693,2.064L8.857,16.63a.7.7,0,0,1-.339-.016L2.5,14.809A2.105,2.105,0,0,1,1,12.793Zm13.132-.672a.7.7,0,0,1,.9.672v6.788a.7.7,0,0,1-.564.688l-5.05,1.01V4.534Zm.614,9.525a2.119,2.119,0,0,0,.289-.079v.822a.7.7,0,0,1-.564.688l-5.05,1.01V13.71ZM2.5,12l5.518,1.655V15L2.9,13.465a.7.7,0,0,1-.5-.672V11.97Zm.4-1.344a.7.7,0,0,1-.5-.672V3.793a.7.7,0,0,1,.9-.672L8.018,4.534v7.658Z"
        transform="translate(0 0)"
        fill={color}
        fillRule="evenodd"
      />
    </g>
  </svg>
);
