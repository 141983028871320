import { Flex } from "antd";
import { Button, Icon } from "ui";
import { useAppStore } from "store";
import { useSpecsListStore } from "../../store";

export const ActionBar = () => {
  const { setModalVisibility } = useAppStore((state) => state);
  const { setFormData, setIsEdit } = useSpecsListStore((state) => state);

  return (
    <Flex className="box w-100" gap={10} justify="space-between" align="center">
      <strong className="color-primary">لیست ویژگی ها</strong>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          setFormData(undefined);
          setIsEdit(false);
          setModalVisibility("SPECS_MANAGEMENT_MODAL");
        }}
      >
        <Icon type="sr-plus" />
        <span>افزودن ویژگی </span>
      </Button>
    </Flex>
  );
};
