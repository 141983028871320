import { AlignType } from "rc-table/lib/interface";
export const CUSTOMERS_CART_TABLE_COLUMN = [
  {
    title: "خریدار",
    dataIndex: "buyer",
    key: "buyer",
    sorter: (a: any, b: any) => a.buyerValuer.localeCompare(b.name),
  },
  //offerAmount
  {
    title: "شناسه",
    dataIndex: "id",
    key: "id",
    align: "center" as AlignType,
    sorter: (a: any, b: any) => a.idValue - b.idValue,
  },
  {
    title: "لیست کالاهای سبد",
    dataIndex: "listOfItems",
    key: "listOfItems",
    align: "center" as AlignType,
  },
  {
    title: "تخفیف",
    dataIndex: "offerAmount",
    key: "offerAmount",
    align: "center" as AlignType,
    sorter: (a: any, b: any) => a.offerAmountValue - b.idValue,
  },
  {
    title: "مبلغ خرید(ریال)",
    dataIndex: "purchaseAmount",
    key: "purchaseAmount",
    align: "center" as AlignType,
    sorter: (a: any, b: any) => a.purchaseAmount.localeCompare(b.name),
  },
  {
    title: "تاریخ آخرین تغییرات",
    dataIndex: "changesDate",
    key: "changesDate",
    align: "center" as AlignType,
    sorter: (a: any, b: any) => a.changesDate.localeCompare(b.name),
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
  },
];
