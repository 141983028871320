import { AlignType } from "rc-table/lib/interface";

export const MENU_SETTING_TABLE_COLUMN = [
  {
    title: "ردیف",
    dataIndex: "index",
    key: "index",
  },
  {
    title: "نام دسته بندی و دسته های زیرمجموعه",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "URL",
    dataIndex: "url",
    key: "url",
    align: "center" as AlignType,
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
  },
];
