import { Card } from "antd";

import OtpCodeForm from "components/forms/OtpCodeForm";
function OtpCodeVerify() {
  return (
    <Card className="auth__card" title="کد تایید">
      <OtpCodeForm />
    </Card>
  );
}

export default OtpCodeVerify;
