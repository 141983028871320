import { Row, Col, Flex } from "antd";
import { Button } from "ui";
import { DetailsForm } from "./order-details-form";
import { SelectProduct } from "./select-product";
import { DUMMY_DATA } from "../api";
import { DeliveryDetails } from "./delivery-details";

export const OrderEdit = () => {
  return (
    <div>
      <Row gutter={20}>
        <Col span={10}>
          <DetailsForm id="#INV-094984111" />
        </Col>
        <Col span={14}>
          <Flex vertical gap={20}>
            <SelectProduct data={DUMMY_DATA} />
            <DeliveryDetails />
          </Flex>
          <br />
          <Flex gap={10} justify="flex-end">
            <Button type="default" size="large">
              لغو کردن
            </Button>
            <Button size="large" type="primary">
              ذخیره تغییرات
            </Button>
          </Flex>
        </Col>
      </Row>
    </div>
  );
};
