import styles from "./index.module.scss";

export const FileSizeLabel = ({
  value = undefined,
  className,
}: {
  value?: number;
  className?: string;
}) => {
  return (
    <div className={`${styles.file_size} ${className}`}>
      {value ? `${value / 1000} KB` : "-"}
    </div>
  );
};
