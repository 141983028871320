import { AlignType } from "rc-table/lib/interface";

export const BRAND_MANAGEMENT_COLUMN = [
  {
    title: "تصویر و نام برند",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "شناسه برند",
    dataIndex: "id",
    key: "id",
    align: "center" as AlignType,
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
  },
];
