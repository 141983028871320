export const IconDrop = ({
  height = 27.39,
  width = 19.624,
  color = "#30b9d3",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 19.624 27.39"
  >
    <path
      id="drop"
      d="M755.756,349.413a9.767,9.767,0,0,1-18.954,0,9.982,9.982,0,0,1,3.129-10.12c3.363-2.9,6.175-4.433,6.348-9.864.174,5.431,2.986,6.964,6.349,9.864A9.979,9.979,0,0,1,755.756,349.413Z"
      transform="translate(-736.467 -329.43)"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
