export const IconEmail = ({ size = 24, color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox="0 0 11.407 9.333"
  >
    <path
      d="M1,4.556A1.556,1.556,0,0,1,2.556,3h8.3a1.556,1.556,0,0,1,1.556,1.556v6.222a1.556,1.556,0,0,1-1.556,1.556h-8.3A1.556,1.556,0,0,1,1,10.778Zm1.556-.519a.519.519,0,0,0-.519.519V5.3L6.7,8.1,11.37,5.3V4.556a.519.519,0,0,0-.519-.519ZM11.37,6.508,7.237,8.988a1.037,1.037,0,0,1-1.067,0L2.037,6.508v4.269a.519.519,0,0,0,.519.519h8.3a.519.519,0,0,0,.519-.519Z"
      transform="translate(-1 -3)"
      fill="#d6d7dd"
      fillRule="evenodd"
    />
  </svg>
);
