import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";
type StateType = "ACCEPTED" | "SUCCESS" | "PENDING" | "FAIL";
interface PaymentStateLabelProp {
  state: StateType;
}

export const stateMessage = (key: StateType) => {
  switch (key) {
    case "SUCCESS":
      return "پرداخت شده";
    case "FAIL":
      return "پرداخت ناموفق";
    case "PENDING":
      return "در حال بررسی";
    case "ACCEPTED":
      return "تایید شده";
    default:
      return "در حال بررسی";
  }
};

export const PaymentStateLabel: React.FC<PaymentStateLabelProp> = ({
  state,
}) => {
  const stateClass = classNames([
    styles.container,
    state === "ACCEPTED" && styles.accepted,
    state === "SUCCESS" && styles.success,
    state === "PENDING" && styles.pending,
    state === "FAIL" && styles.fail,
  ]);
  return <label className={stateClass}>{stateMessage(state)}</label>;
};
