import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { SEO_MANAGEMENT_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO, SimpleResponseDTO } from "interface";
import { menuSettingForm } from "../type";

const addFn = async (
  data: menuSettingForm
): Promise<SimpleResponseDTO<number>> =>
  instance.post(SEO_MANAGEMENT_ENDPOINTS.add, {
    parentId: data.parentId,
    name: data.name,
    description: "",
    isActive: true,
    priority: data.priority,
    hasUrl: true,
    url: data.url,
  });

export const useAddMenu = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addFn,
    onSuccess: () => {
      toast.success("منو با موفقیت ایجاد گردید");
      queryClient.invalidateQueries([
        SEO_MANAGEMENT_ENDPOINTS.list,
      ] as InvalidateQueryFilters);
    },
    onError: ({ response }: AxiosError<ErrorDTO>) => {
      toast.error(`${response?.data?.Message || "خطا در ایجاد منو"}`);
    },
  });
};
