export const DUMMY_DATE = [
  {
    buyer: "جناب آقای هوشیار",
    id: "094984111",
    status: "ACCEPTED",
    data: "",
    offerAmount: "40,000,000",
    purchaseAmount: "40,000,000",
    releaseDate: "02/10/1402 02:50",
    orderDateValue: "02/10/1402 02:50",
    orderDate: "02/10/1402 02:50",
    changesDate: "02/10/1402 02:50",
    changesDateValue: "02/10/1402 02:50",
  },
  {
    buyer: "جناب آقای هوشیار",
    id: "094984111",
    status: "ACCEPTED",
    data: "",
    offerAmount: "40,000,000",
    purchaseAmount: "40,000,000",
    releaseDate: "02/10/1402 02:50",
    orderDateValue: "02/10/1402 02:50",
    orderDate: "02/10/1402 02:50",
    changesDate: "02/10/1402 02:50",
    changesDateValue: "02/10/1402 02:50",
  },
  {
    buyer: "جناب آقای هوشیار",
    id: "094984111",
    status: "SUCCESS",
    data: "",
    offerAmount: "40,000,000",
    purchaseAmount: "40,000,000",
    releaseDate: "02/10/1402 02:50",
    orderDateValue: "02/10/1402 02:50",
    orderDate: "02/10/1402 02:50",
    changesDate: "02/10/1402 02:50",
    changesDateValue: "02/10/1402 02:50",
  },
  {
    buyer: "جناب آقای هوشیار",
    id: "094984111",
    status: "PENDING",
    data: "",
    offerAmount: "40,000,000",
    purchaseAmount: "40,000,000",
    releaseDate: "02/10/1402 02:50",
    orderDateValue: "02/10/1402 02:50",
    orderDate: "02/10/1402 02:50",
    changesDate: "02/10/1402 02:50",
    changesDateValue: "02/10/1402 02:50",
  },
  {
    buyer: "جناب آقای هوشیار",
    id: "094984111",
    status: "FAIL",
    data: "",
    offerAmount: "40,000,000",
    purchaseAmount: "40,000,000",
    releaseDate: "02/10/1402 02:50",
    orderDateValue: "02/10/1402 02:50",
    orderDate: "02/10/1402 02:50",
    changesDate: "02/10/1402 02:50",
    changesDateValue: "02/10/1402 02:50",
  },
];
