import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $getNodeByKey,
  $isElementNode,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isParentElementRTL,
  $isAtNodeEnd,
  $getSelectionStyleValueForProperty,
  $patchStyleText,
} from "@lexical/selection";
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils";
import { $isListNode, ListNode } from "@lexical/list";
import { createPortal } from "react-dom";
import { $isHeadingNode } from "@lexical/rich-text";
import {
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages,
} from "@lexical/code";

import DropDown, { DropDownItem } from "ui/lexical-editor/ui/dropdown";
import { InsertInlineImageDialog } from "ui/lexical-editor/plugins/InlineImagePlugin";
import useModal from "../hooks/useModal";
import DropdownColorPicker from "../ui/dropdown-color-picker";
import { InsertTableDialog } from "./TablePlugin";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import BlockOptionsDropdownList from "../ui/block-options-dropdown-list";
import FontSize from "../ui/font-size";
import {
  blockTypeToBlockNamePersian,
  useToolbarState,
} from "../context/ToolbarContext";
import { $isTableSelection } from "@lexical/table";
import ElementFormatDropdown from "../ui/element-format-dropdown";
import { InsertImageDialog } from "./ImagesPlugin";
import { sanitizeUrl } from "../utils/url";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";

const LowPriority = 1;

const supportedBlockTypes = new Set([
  "paragraph",
  "quote",
  "code",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "ul",
  "ol",
]);

function Divider() {
  return <div className="v-divider" />;
}

function Select({ onChange, className, options, value }) {
  return (
    <select className={className} onChange={onChange} value={value}>
      <option hidden={true} value="" />
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

export default function ToolbarPlugin({ setIsLinkEditMode, isPopup = false }) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);

  const { toolbarState, updateToolbarState } = useToolbarState();

  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [modal, showModal] = useModal();

  const { ref: intersectorRef, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    // set `toolbarIsInViewPort` to false if main toolbar (not pop-up toolbar) was out of viewport
    if (!isPopup) {
      updateToolbarState("toolbarIsInViewPort", inView);
    }
  }, [inView]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          updateToolbarState("blockType", type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          updateToolbarState("blockType", type);
          if ($isCodeNode(element)) {
            updateToolbarState(
              "codeLanguage",
              element.getLanguage() || getDefaultCodeLanguage()
            );
          }
        }
      }

      // Update text format
      updateToolbarState("isBold", selection.hasFormat("bold"));
      updateToolbarState("isItalic", selection.hasFormat("italic"));
      updateToolbarState("isUnderline", selection.hasFormat("underline"));
      updateToolbarState(
        "isStrikethrough",
        selection.hasFormat("strikethrough")
      );
      updateToolbarState("isCode", selection.hasFormat("code"));
      updateToolbarState("isRTL", $isParentElementRTL(selection));
      updateToolbarState(
        "fontColor",
        $getSelectionStyleValueForProperty(selection, "color", "#000")
      );
      updateToolbarState(
        "bgColor",
        $getSelectionStyleValueForProperty(
          selection,
          "background-color",
          "#fff"
        )
      );

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        updateToolbarState("isLink", true);
      } else {
        updateToolbarState("isLink", false);
      }

      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        );
      }
      // If matchingParent is a valid node, pass it's format type
      updateToolbarState(
        "elementFormat",
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
            ? node.getFormatType()
            : parent?.getFormatType() || "left"
      );
    }

    if ($isRangeSelection(selection) || $isTableSelection(selection)) {
      // Update text format
      updateToolbarState("isBold", selection.hasFormat("bold"));
      updateToolbarState("isItalic", selection.hasFormat("italic"));
      updateToolbarState("isUnderline", selection.hasFormat("underline"));
      updateToolbarState(
        "isStrikethrough",
        selection.hasFormat("strikethrough")
      );
      updateToolbarState("isSubscript", selection.hasFormat("subscript"));
      updateToolbarState("isSuperscript", selection.hasFormat("superscript"));
      updateToolbarState("isCode", selection.hasFormat("code"));
      updateToolbarState(
        "fontSize",
        $getSelectionStyleValueForProperty(selection, "font-size", "16px")
      );
    }
  }, [editor, updateToolbarState]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          updateToolbarState("canUndo", payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          updateToolbarState("canRedo", payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback(
    (e) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(e.target.value);
          }
        }
      });
    },
    [editor, selectedElementKey]
  );

  const insertLink = useCallback(() => {
    if (!toolbarState.isLink) {
      setIsLinkEditMode(true);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"));
    } else {
      setIsLinkEditMode(false);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, setIsLinkEditMode, toolbarState.isLink]);

  const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {
      editor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: "historic" } : {}
      );
    },
    [editor]
  );

  const onFontColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ color: value }, skipHistoryStack);
    },
    [applyStyleText]
  );

  const onBgColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ "background-color": value }, skipHistoryStack);
    },
    [applyStyleText]
  );

  return (
    <div
      className={classNames(["toolbar", isPopup && "pop-up"])}
      ref={(el) => {
        intersectorRef(el);
        toolbarRef.current = el;
      }}
    >
      <button
        disabled={!toolbarState.canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND);
        }}
        className="toolbar-item spaced"
        aria-label="Undo"
      >
        <i className="format undo" />
      </button>
      <button
        disabled={!toolbarState.canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND);
        }}
        className="toolbar-item"
        aria-label="Redo"
      >
        <i className="format redo" />
      </button>
      <Divider />
      <FontSize
        selectionFontSize={toolbarState.fontSize.slice(0, -2)}
        editor={editor}
        // disabled={!isEditable}
      />
      <Divider />
      {supportedBlockTypes.has(toolbarState.blockType) && (
        <>
          <button
            className="toolbar-item block-controls"
            onClick={() =>
              setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
            }
            aria-label="Formatting Options"
          >
            <span className={"icon block-type " + toolbarState.blockType} />
            <span className="text">
              {blockTypeToBlockNamePersian?.[toolbarState.blockType]}
            </span>
            <i className="chevron-down" />
          </button>
          {showBlockOptionsDropDown &&
            createPortal(
              <BlockOptionsDropdownList
                editor={editor}
                blockType={toolbarState.blockType}
                toolbarRef={toolbarRef}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
              />,
              toolbarRef.current
            )}
          <Divider />
        </>
      )}
      <ElementFormatDropdown
        // disabled={!isEditable}
        value={toolbarState.elementFormat}
        editor={editor}
        isRTL={toolbarState.isRTL}
        portalRef={toolbarRef}
      />
      <Divider />
      {toolbarState.blockType === "code" ? (
        <>
          <Select
            className="toolbar-item code-language"
            onChange={onCodeLanguageSelect}
            options={codeLanguges}
            value={toolbarState.codeLanguage}
          />
          <i className="chevron-down inside" />
        </>
      ) : (
        <>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
            }}
            className={
              "toolbar-item spaced " + (toolbarState.isBold ? "active" : "")
            }
            aria-label="Format Bold"
          >
            <i className="format bold" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
            }}
            className={
              "toolbar-item spaced " + (toolbarState.isItalic ? "active" : "")
            }
            aria-label="Format Italics"
          >
            <i className="format italic" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
            }}
            className={
              "toolbar-item spaced " +
              (toolbarState.isUnderline ? "active" : "")
            }
            aria-label="Format Underline"
          >
            <i className="format underline" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
            }}
            className={
              "toolbar-item spaced " +
              (toolbarState.isStrikethrough ? "active" : "")
            }
            aria-label="Format Strikethrough"
          >
            <i className="format strikethrough" />
          </button>
          <button
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
            }}
            className={
              "toolbar-item spaced " + (toolbarState.isCode ? "active" : "")
            }
            aria-label="Insert Code"
          >
            <i className="format code" />
          </button>
          <button
            onClick={insertLink}
            className={
              "toolbar-item spaced " + (toolbarState.isLink ? "active" : "")
            }
            aria-label="Insert Link"
          >
            <i className="format link" />
          </button>
          <Divider />
          <DropdownColorPicker
            disabled={false}
            buttonClassName="toolbar-item color-picker"
            buttonAriaLabel="Formatting text color"
            buttonIconClassName="icon font-color"
            color={toolbarState.fontColor}
            onChange={onFontColorSelect}
            title="text color"
            portalRef={toolbarRef.current}
          />
          <DropdownColorPicker
            disabled={false}
            buttonClassName="toolbar-item color-picker"
            buttonAriaLabel="Formatting background color"
            buttonIconClassName="icon bg-color"
            color={toolbarState.bgColor}
            onChange={onBgColorSelect}
            title="bg color"
            portalRef={toolbarRef.current}
          />

          <>
            <Divider />
            <DropDown
              disabled={false}
              buttonClassName="toolbar-item spaced"
              buttonLabel="افزودن"
              buttonAriaLabel="Insert specialized editor node"
              buttonIconClassName="icon plus"
              portalRef={toolbarRef.current}
            >
              <DropDownItem
                onClick={() => {
                  editor.dispatchCommand(
                    INSERT_HORIZONTAL_RULE_COMMAND,
                    undefined
                  );
                }}
                className="item"
              >
                <i className="icon horizontal-rule" />
                <span className="text">خط افقی</span>
              </DropDownItem>
              <DropDownItem
                onClick={() => {
                  showModal("افزودن تصویر", (onClose) => (
                    <InsertImageDialog
                      activeEditor={editor}
                      onClose={onClose}
                    />
                  ));
                }}
                className="item"
              >
                <i className="icon image" />
                <span className="text">تصویر</span>
              </DropDownItem>
              <DropDownItem
                onClick={() => {
                  showModal("افزودن تصویر خطی", (onClose) => (
                    <InsertInlineImageDialog
                      activeEditor={editor}
                      onClose={onClose}
                    />
                  ));
                }}
                className="item"
              >
                <i className="icon image" />
                <span className="text">تصویر خطی</span>
              </DropDownItem>
              <DropDownItem
                onClick={() => {
                  showModal("افزودن جدول", (onClose) => (
                    <InsertTableDialog
                      activeEditor={editor}
                      onClose={onClose}
                    />
                  ));
                }}
                className="item"
              >
                <i className="icon table" />
                <span className="text">جدول</span>
              </DropDownItem>
            </DropDown>
          </>
        </>
      )}
      {modal}
    </div>
  );
}
