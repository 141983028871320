import React, { useState } from "react";
import { useField } from "formik";
import classNames from "classnames";
import { ConfigProvider } from "antd";
import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali";
import fa_IR from "antd/lib/locale/fa_IR";

import styles from "./index.module.scss";
import "./index.scss";

interface DatePickerProps {
  name: string;
  format?: string;
  label?: string;
  direction?: "rtl" | "ltr";
  className?: string;
  size?: "sm" | "lg" | "md";
  placeholder?: string;
  type?: "antd" | "formik";
}

export const DatePicker: React.FC<DatePickerProps> = ({
  name="name",
  format = "dddd D MMMM",
  direction = "rtl",
  label = "تاریخ",
  className,
  size = "md",
  placeholder,
  type = "formik",
}) => {
 
  const [value, setValue] = useState(null);

  
  const [field, meta, helpers] = useField(name);

  const handleChange = (date: any) => {
    if (type === "formik") {
      helpers.setValue(date);
    } else {
      setValue(date);
    }
  };

  return (
    <div
      className={classNames([
        styles.datePicker_container,
        "datePicker-container",
        size === "lg" && styles.large,
        className,
      ])}
    >
      <span className="color-stroke">{label}</span>
      <ConfigProvider locale={fa_IR} direction={direction}>
        <JalaliLocaleListener />
        <DatePickerJalali
          format={format}
          value={type === "formik" ? field.value : value}
          onChange={handleChange}
          onBlur={type === "formik" ? field.onBlur : undefined}
          placeholder={placeholder}
        />
      </ConfigProvider>
      {type === "formik" && meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </div>
  );
};
