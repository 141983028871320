import { Input, Rate, Select, Table } from "antd";
import { IconSearch } from "components/icons/iconSearch";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { IconChevronLeft } from "components/icons/iconChervronLeft";
import { useGetProducts } from "core/services/product/useGetProducts";
import AddProductModal from "../AddProductModal";
import styles from "./index.module.scss";
import { getImageSrc } from "core/utils/json-utils";
import { PRODUCT_ENDPOINTS } from "core/constants/endpoints";
import { BrandAvatar } from "ui";

const ProductTable = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { data: products, isPending } = useGetProducts({
    queryKey: [{ page, search }, PRODUCT_ENDPOINTS.list],
  });

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const getColumns = useCallback(
    () => [
      {
        title: <div className={styles["column"]}>محصول</div>,
        width: "30%",
        render: (product) => {
          return (
            <div className={styles["product_name_info"]}>
              <div>
                <BrandAvatar
                  img={getImageSrc(product?.thumbnail?.url, true)}
                  label={product?.name}
                />
              </div>
              <div>{product.name}</div>
            </div>
          );
        },
      },
      {
        title: <div className={styles["column"]}>شناسه</div>,
        width: "10%",
        align: "center",
        render: (product) => {
          return (
            <div className={styles["product_id"]}>
              <div>{product?.code ?? "-"}</div>
            </div>
          );
        },
      },
      {
        title: <div className={styles["column"]}>مقدار</div>,
        sorter: (a, b) => a?.enumerationUnitValue - b?.enumerationUnitValue,
        width: "10%",
        align: "center",
        render: (product) => {
          return (
            <div className={styles["product_id"]}>
              <div>{product?.enumerationUnitValue ?? "-"}</div>
            </div>
          );
        },
      },
      {
        title: <div className={styles["column"]}>قیمت (تومان) </div>,
        sorter: (a, b) => a?.price - b?.price,
        width: "15%",
        align: "center",
        render: (product) => {
          return (
            <div className={styles["product_id"]}>
              <div>{product?.price ?? 0}</div>
            </div>
          );
        },
      },
      {
        title: <div className={styles["column"]}>امتیاز محصول</div>,
        sorter: (a, b) => a?.rating - b?.rating,
        width: "15%",
        align: "center",
        render: (product) => {
          return (
            <div className={styles["product_score"]}>
              <Rate value={product?.rating ?? 0} />
            </div>
          );
        },
      },
      {
        title: <div className={styles["column"]}> وضعیت </div>,
        width: "10%",
        align: "center",
        render: (product) => (
          <div className={styles["status"]}>
            <div
              style={
                product?.status === 1
                  ? { background: "#E8FFF3", color: "#50CD89" }
                  : product?.status === 0
                    ? {
                        background: "rgba(246, 192, 46, 0.1)",
                        color: "#F6C02E",
                        appearance: "10%",
                      }
                    : { background: "#F5222D", color: "#FFFFFF" }
              }
            >
              {product?.statusPersianName ?? "نامشخص"}
            </div>
          </div>
        ),
        sorter: (a, b) => a?.registerDate - b?.registerDate,
      },
      // {
      //   title: <div className={styles["column"]}> سئو محصول </div>,
      //   render: () => (
      //     <div className={styles["product_seo"]}>
      //       <Link to="/catalog/product/seo_details">مدیریت سئو</Link>

      //       <div>
      //         <IconChevronLeft className={styles["icon"]} />
      //       </div>
      //     </div>
      //   ),
      //   sorter: (a, b) => a?.registerDate - b?.registerDate,
      // },
      {
        title: <div className={styles["column"]}> عملیات</div>,
        align: "center",
        render: (product) => (
          <div className={styles["product_actin"]}>
            <Select
              defaultValue={"operation"}
              style={{ width: 120 }}
              onChange={() => {}}
              options={[
                {
                  value: "operation",
                  label: (
                    <Link to={`/catalog/product/${product.id}/edit`}>
                      ویرایش مشخصات
                    </Link>
                  ),
                },
                { value: "publish", label: "منتشر شده" },
                { value: "keep", label: "نگه داشتن" },
                { value: "diactive", label: "غیر فعال", disabled: true },
              ]}
            />
          </div>
        ),
      },
    ],
    []
  );
  return (
    <div
      style={{
        marginBlock: 30,
      }}
    >
      <div className={styles["table_filter"]}>
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          addonBefore={<IconSearch />}
          placeholder="جستجوی محصول"
        />

        <div className={styles["filters-container"]}>
          <div>
            {/* <SliderFilterModal /> */}
            <Select
              className={styles["filter_status"]}
              defaultValue={"operation"}
              style={{ width: 120 }}
              onChange={() => {}}
              options={[
                { value: "operation", label: "عملیات" },
                { value: "publish", label: "منتشر شده" },
                { value: "keep", label: "نگه داشتن" },
                { value: "diactive", label: "غیر فعال", disabled: true },
              ]}
            />
          </div>

          <div>
            <AddProductModal />
          </div>
        </div>
      </div>
      <Table
        locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
        className={styles["table"]}
        rowSelection={rowSelection}
        columns={getColumns()}
        dataSource={products?.data?.data ?? []}
        loading={isPending}
        pagination={{
          showSizeChanger: false,
          total: products?.data?.totals ?? 0,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </div>
  );
};

export default ProductTable;
