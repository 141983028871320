export const IconUser = ({ size = 24, color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox="0 0 10.862 11.833"
  >
    <g transform="translate(-2.362 -1)">
      <path
        d="M12.762,4.381A3.381,3.381,0,1,1,9.381,1,3.381,3.381,0,0,1,12.762,4.381Zm-1.127,0A2.254,2.254,0,1,1,9.381,2.127,2.254,2.254,0,0,1,11.635,4.381Z"
        transform="translate(-1.588)"
        fill="#d6d7dd"
        fillRule="evenodd"
      />
      <path
        d="M7.793,14a7.076,7.076,0,0,0-3.7.857,3.981,3.981,0,0,0-1.608,1.767,1.253,1.253,0,0,0,.176,1.35,1.547,1.547,0,0,0,1.189.533h7.889a1.547,1.547,0,0,0,1.189-.533,1.253,1.253,0,0,0,.176-1.35,3.981,3.981,0,0,0-1.608-1.767A7.075,7.075,0,0,0,7.793,14ZM3.509,17.093a2.856,2.856,0,0,1,1.163-1.269,5.975,5.975,0,0,1,3.121-.7,5.974,5.974,0,0,1,3.121.7,2.855,2.855,0,0,1,1.163,1.269.159.159,0,0,1,.019.091.141.141,0,0,1-.034.069.426.426,0,0,1-.324.128H3.848a.425.425,0,0,1-.324-.128.14.14,0,0,1-.034-.069A.16.16,0,0,1,3.509,17.093Z"
        transform="translate(0 -5.675)"
        fill="#d6d7dd"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
