import React from "react";
import { Form, Flex } from "antd";
import { Button, Icon, SearchInput } from "ui";
import { useAppStore } from "store";
import { useBannerManagementStore } from "../../store";
import { bannerManagementFilter } from "../../type";

interface FormValues {
  search: string;
}
interface BannersActionBar {
  className?: string;
}

export const ActionBar: React.FC<BannersActionBar> = ({ className }) => {
  const { setModalVisibility } = useAppStore((state) => state);
  const { setFilter, setFormData, setIsEdit } = useBannerManagementStore(
    (state) => state
  );
  const [form] = Form.useForm<FormValues>();
  const onValuesChangeHandler = (
    changedValues: FormValues,
    allValues: FormValues
  ) => {
    setFilter(
      Object.entries(changedValues)
        .filter(([_, value]) => value.length > 0)
        .map(([key, value]) => {
          return {
            propertyName: key as bannerManagementFilter,
            operation: "contains",
            value: value as string,
          };
        })
    );
  };
  return (
    <Flex className={`${className} box w-100`} gap={10} justify="space-between">
      <Form
        form={form}
        className="w-100"
        onValuesChange={onValuesChangeHandler}
      >
        <Flex>
          <Form.Item name={"name"}>
            <SearchInput size="large" placeholder="جستجوی بنر " />
          </Form.Item>
        </Flex>
      </Form>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          setFormData(undefined);
          setIsEdit(false);
          setModalVisibility("MAIN_BANNERS_MANAGEMENT_MODAL");
        }}
      >
        <Icon type="sr-plus" />
        <span>افزودن بنر </span>
      </Button>
    </Flex>
  );
};
