import { ActionBar } from "./actionbar";
import UserManagementModal from "./modal";
import UserManagementTable from "./table";

export const UserManagement = () => {
  return (
    <div>
      <ActionBar />
      <UserManagementTable />
      <UserManagementModal />
    </div>
  );
};
