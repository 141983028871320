import { Flex } from "antd";

import styles from "./index.module.scss";

export const StatusLabel = ({
  status,
  className,
}: {
  status: boolean;
  className?: string;
}) => {
  if (status) {
    return (
      <div className={`${styles.active} ${!status && styles.active_disable} `}>
        فعال
      </div>
    );
  } else {
    return (
      <div
        className={`${styles.deactive} ${status && styles.deactive_disable}`}
      >
        غیر فعال
      </div>
    );
  }
};
