import { Card, Typography, Button, Select } from "antd";
import { IconImage } from "components/icons";
import { useGetLatestContents } from "core/services/dashboard/useGetLastContents";
const { Title, Text, Link } = Typography;
import "./index.scss";
const LatestContent = () => {
  const { data } = useGetLatestContents();
  const showLoadMore = () => data?.list?.page < data?.list?.totalPages;
  return (
    <Card bordered={false} style={{ width: "100%", flexGrow: 1 }}>
      <div className="latest-content">
        <div className="latest-content__head">
          <Title level={5}>آخرین محتواهای تولید شده</Title>
          <Text className="latest-content__text">
            تعداد فایل های آپلود شده {data?.filesCount ?? 0} ,{" "}
            {data?.filesVolume ?? 0} گیگابایت فضای استفاده شده از سرور
          </Text>
        </div>

        <div className="latest_content_Produce_filter">
          <Select
            defaultValue="همه"
            style={{ width: 120 }}
            options={[
              { value: "همه", label: "همه" },
              { value: "محتوا", label: "محتوا" },
              { value: "سیستمی", label: "سیستمی" },
              { value: "فروش", label: "فروش" },
              { value: "کاربر", label: "کاربر", disabled: true },
              { value: "سفارش", label: "سفارش", disabled: true },
              { value: "فروشگاه", label: "فروشگاه", disabled: true },
            ]}
          />
        </div>
      </div>

      <div className="posts">
        {(data?.list?.data ?? []).map((item, index) => (
          <div className="posts__post" key={index}>
            <div className="posts__post__right">
              <IconImage size={22} />

              <div className="posts__post__right__info">
                <Title level={5}>{item?.name ?? "-"}</Title>
                <Text>{item?.daysAgo ?? 0} روز پیش</Text>
              </div>
            </div>

            <div className="posts__post__left">
              <Link className="posts__post__left__link">
                {item?.creatorUser ?? "-"}
              </Link>
            </div>
          </div>
        ))}

        {showLoadMore() && (
          <Button type="default" style={{ marginTop: "12px" }}>
            مشاهده بیشتر
          </Button>
        )}
      </div>
    </Card>
  );
};

export default LatestContent;
