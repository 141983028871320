export const DUMMY_DATA = [
  {
    id: "#6464987",
    status: "SUCCESS",
    price: "540,000,000",
    point: "450",
    date: "02/10/1402 02:50",
  },
  {
    id: "#6464987",
    status: "SUCCESS",
    price: "540,000,000",
    point: "450",
    date: "02/10/1402 02:50",
  },
  {
    id: "#6464987",
    status: "SUCCESS",
    price: "540,000,000",
    point: "450",
    date: "02/10/1402 02:50",
  },
  {
    id: "#6464987",
    status: "SUCCESS",
    price: "540,000,000",
    point: "450",
    date: "02/10/1402 02:50",
  },
  {
    id: "#6464987",
    status: "FAIL",
    price: "540,000,000",
    point: "450",
    date: "02/10/1402 02:50",
  },
];
