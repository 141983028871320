import { Line } from "react-chartjs-2";
import { ChartData, ChartOptions, LineProps } from "chart.js";
import "chart.js/auto";

interface LineChartProps {
  data: ChartData<"line">; // Required data prop for the line chart
  options?: ChartOptions<"line">; // Optional options prop for customization
}

export const LineChart: React.FC<LineChartProps> = ({ data }) => {
  const options: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },

      y: {
        grid: {
          drawTicks: false,
          color: "#B5B5C3",
          tickBorderDashOffset:5
           
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleColor: "#3F4254",
        titleFont: {
          size: 8,
          family: "IRANSans",
        },
        bodyColor: "#3D4051",
        backgroundColor: "white",
        bodyFont: {
          size: 10,
          family: "IRANSans",
        },
      },
    },
  };
  return <Line data={data} options={options} />;
};
