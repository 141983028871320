import { Col, Row } from "antd";
import { SecuritySetting } from "./security-setting";
import { SecurityLogin } from "./security-login";
import { PaymentMethod } from "./payment-method";

export const AdvanceSetting = () => {
  return (
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <SecuritySetting />
      </Col>
      <Col span={24}>
        <SecurityLogin />
      </Col>
      <Col span={24}>
        <PaymentMethod />
      </Col>
    </Row>
  );
};
