import { Flex } from "antd";
import { LineChart } from "ui";
import styles from "../../index.module.scss";

export const CompaniesAdvertiseViewsCount = () => {
  const data = {
    labels: [
      "پنجشنبه 25 آبان",
      "پنجشنبه 25 آبان",
      "پنجشنبه 25 آبان 3",
      "پنجشنبه 25 آبان 4",
      "پنجشنبه 25 آبان 5",
      "پنجشنبه 25 آبان 6",
      "پنجشنبه 25 آبان 7",
    ],
    datasets: [
      {
        label: "بازدید",
        data: [120, 190, 300, 200, 280, 190, 300, 200, 280],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#3F4254",
        tension: 0.4,
        pointRadius: 5,
        pointHoverRadius: 5,
        pointBorderColor: "#3D4051",
        pointBackgroundColor: "#30B9D3",
      },
    ],
  };

 
  return (
    <Flex vertical justify="space-between" className={styles.card}>
      <div className="box">
        <Flex gap={6} align="center">
          <h1>4,676,372</h1>
          <label className="color-stroke">بازدید</label>
        </Flex>
        <label className="color-stroke">کربن اکتیو جاکوبی</label>
      </div>
      <div className={styles.line_container}>
        <LineChart data={data}   />
      </div>
    </Flex>
  );
};
