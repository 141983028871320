import { useRef } from "react";
import { Button } from "antd";
import { toast } from "react-toastify";
import { Modal } from "ui";
import { useSliderManagementStore } from "../../store";
import { useAddSlider } from "../../api";
import { useUpdateSlider } from "../../api";
import SliderForm, { SliderFormRef } from "../form";
import { sliderForm } from "../../type";

const SliderManagementModal = () => {
  const { setFormData, formData, isEdit } = useSliderManagementStore(
    (state) => state
  );
  const updateSliderQuery = useUpdateSlider();
  const addSliderQuery = useAddSlider();
  const loading = isEdit
    ? updateSliderQuery.isPending
    : addSliderQuery.isPending;

  const formRef = useRef<SliderFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    const values = formRef.current?.getFormData();
    setFormData(values);

    if (!values?.uploadImage && !isEdit) {
      toast.error("تصویر اسلایدر را مشخص کنید");
      return;
    }

    if (isEdit) {
      updateSliderQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    } else {
      addSliderQuery.mutate(
        {
          data: values!,
        },
        {
          onSuccess: () => {
            formRef.current?.resetForm();
            onSuccess();
          },
        }
      );
    }
  };

  return (
    <Modal
      name="MAIN_SLIDERS_MANAGEMNT_MODAL"
      title={isEdit ? "ویرایش اسلایدر" : "افزودن اسلایدر جدید"}
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
        formRef.current?.resetForm();
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <SliderForm ref={formRef} formData={formData as sliderForm} />
    </Modal>
  );
};
export default SliderManagementModal;
