import { create } from "zustand";
import { menuSettingForm } from "../type";
type MenuSettingStore = {
  editedItem?: menuSettingForm;
  setEditedItem: (editedItem: menuSettingForm) => void;
};

export const useMenuSettingStore = create<MenuSettingStore>((set) => {
  return {
    editedItem: undefined,
    setEditedItem: (value) => {
      set({ editedItem: value });
    },
  };
});
