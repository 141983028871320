import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BANNER_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { bannerForm } from "../type";

const addBanner = async ({ data }: { data: bannerForm }) => {
  const formData = new FormData();
  formData.append("Priority", String(data.priority ?? "0"));
  formData.append("AltText", "");
  formData.append("Name", data.name);
  formData.append("Url", data.url);
  formData.append("LandingUrl", data.landingUrl ?? "0");
  formData.append("IsActive", String(data.isActive ?? false));
  formData.append("Description1", data?.description1 ?? "");
  formData.append("Description2", data?.description2 ?? "");
  if (data.uploadImage) {
    formData.append("Width", String(data.uploadImage.width));
    formData.append("Height", String(data.uploadImage.height));
    formData.append("SizePercentage", "100");
    formData.append("File", data.uploadImage.file);
  }

  const res = await instance.post(BANNER_END_POINTS.add, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [BANNER_END_POINTS.add, BANNER_END_POINTS.list],
    mutationFn: addBanner,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد بنر"}`);
    },
    onSuccess: () => {
      toast.success(`بنر با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [BANNER_END_POINTS.list],
      });
    },
  });
};
