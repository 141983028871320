export const DUMMY_DATA = [
  {
    amount: "15",
    product: "محصول شماره 1",
    date: "02/10/1402 02:50",
    price: "540,000,000",
    totalPrice: "540,000,000",
    id: "094984111",
  },
];
