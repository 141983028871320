import { Button, Input } from "antd";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLogin } from "core/services/authentication/useLogin";
import { LoginSchema } from "./schema";

import "./index.scss";

const LoginForm = () => {
  const { mutate: doLogin, isPending } = useLogin();

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting }) => {
        doLogin(
          {
            username: values.email,
            password: values.password
          },
        {
          onSettled: () => setSubmitting(false)
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form className="login-form">
          <div className="login-form__email">
            <Field name="email" type="email" placeholder="ایمیل" as={Input} />
            <ErrorMessage
              name="email"
              component="div"
              style={{
                fontSize: "12px",
                color: "red",
                textAlign: "right",
                width: "100%",
              }}
            />
          </div>

          <div className="login-form__password">
            <Field
              name="password"
              type="password"
              placeholder="پسورد"
              as={Input}
            />
            <ErrorMessage
              name="password"
              component="div"
              style={{
                fontSize: "12px",
                color: "red",
                textAlign: "right",
                width: "100%",
              }}
            />

            <Link
              to="/auth/forget-password"
              className="login-form__password_forgot"
            >
              کلمه عبور خود را فراموش کردید؟
            </Link>
          </div>

          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form__button"
            loading={isSubmitting}
          >
            ورود
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
