import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { RequestDTO, ResponseDTO } from "interface";
import { HOME_END_POINTS } from "core/constants/endpoints";
import { slider, sliderManagementFilter } from "../type";

type slidersRequestType = RequestDTO<sliderManagementFilter>;
const getSliders = async (
  slidersRequestType: slidersRequestType
): Promise<any> => {
  const res = await instance.post(
    HOME_END_POINTS.sliders_list,
    slidersRequestType
  );

  return res.data;
};

type UseGetUserOptions = UseQueryOptions<any, unknown, any>;
export const useGetSliders = (
  params: slidersRequestType,
  config?: UseGetUserOptions
) => {
  return useQuery({
    queryKey: [
      HOME_END_POINTS.sliders_list,
      params.page,
      params.pageSize,
      ...(params.inputParams.filters ? params.inputParams.filters : []),
    ],
    queryFn: () => getSliders(params),
    ...config,
  });
};
