import { Breadcrumb } from "antd";
import { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

const translateToPersian = (englishPath) => {
  // Implement your translation logic here
  // For demonstration purposes, I'm using a simple mapping
  const translations = {
    dashboard: "دشبورد",
    settings: "تنظیمات حساب کاربری",
    user_management: "مدیریت کاربران",
    brand_management: "مدیریت برند ها",
    stores_management: "مدیریت فروشگاه",
    role_management: "مدیریت نقش ها",
    section_management: "مدیریت بخش ها",
    slider_management: "مدیریت اسلایدر های صفحه اصلی",
    banner_management: "مدیریت بنر ها",
    catalog: "کاتالوگ",
    product: "محصولات",
    add_product: "اضافه کردن محصولات",
    menu_setting: " تنظیمات منو",
    edit: "ویرایش",
    overview: "نمای کلی",
    advertise_management: "مدیریت تبلیفات",
    privacy_management: "حریم خصوصی",
    terms_management: "قوانین سایت",
    about_us_management: "درباره ما",
    seo_management: "مدیریت سئو",
    landing: "لندینگ",
    product_category: "دسته بندی محصول",
    menu_setting: "تنظیمات منو",
    specs: "لیست ویژگی ها",
    stores: "فروشگاه ها",
    requests: "درخواست ها",
    orders: "سفارشات",
    orders_management: "مدیریت سفارشات",
    vendees_management: "مدیریت خریداران",
    customers_carts: "سبد خرید مشتریان",
    // Add more translations as needed
  };

  return translations[englishPath] || englishPath;
};

const MyBreadcrumb = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter(Boolean);

  const getBreadcrumbItems = useCallback(
    () =>
      paths.map((path, index) => ({
        title: (
          <Link to={`/${paths.slice(0, index + 1).join("/")}`}>
            {translateToPersian(path)}
          </Link>
        ),
      })),
    [paths]
  );

  return <Breadcrumb items={getBreadcrumbItems()} />;
};

export default MyBreadcrumb;
