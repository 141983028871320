import { useMutation } from "@tanstack/react-query";
import { AUTH_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "core/utils/auth-utils";

export const refreshToken = async () => {
  const refreshToken = getRefreshToken() ?? "";
  const { data } = await instance.post(AUTH_END_POINTS.refresh, {
    refreshToken,
  });
  return data;
};

type LoginData = {
  username: string;
  password: string;
};
export const useLogin = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: LoginData) =>
      instance.post(AUTH_END_POINTS.login, {
        ...data,
      }),
    onSuccess: (res) => {
      if (res.data.StatusCode === 500) {
        toast.error(` ${res.data.Message}`);
      } else {
        setAccessToken(res?.data?.data?.accessToken);
        setRefreshToken(res?.data?.data?.refreshToken);

        toast.success("ورود موفقیت آمیز بود.");
        navigate("/dashboard/overview");
      }
    },
    onError: (e: any) => {
      console.error("Login failed:", e);
      toast.error(`${e.response?.data?.Message || "خطای در ورود"}`);
    },
  });
};
