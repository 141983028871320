import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Select, Table, Typography } from "antd";
import { StatusLabel } from "ui";
import { VENDEES_TABLE_COLUMN } from "../../constant";

import styles from "../index.module.scss";

interface VendeesTableProp {
  data: any;
}
export const VendeesTable: React.FC<VendeesTableProp> = ({ data }) => {
  const navigate = useNavigate();
  const dataSource = useMemo(() => {
    return data?.map((item: any) => {
      return {
        name: item.name,
        nameValue: item.name,
        status: (
          <Flex align="center" justify="center">
            <StatusLabel status={item.status} />
          </Flex>
        ),
        email: <Typography className="color-stroke">{item.email}</Typography>,
        id: <strong className="color-stroke">{item.id}</strong>,
        ipAddress: <strong className="color-stroke">{item.ipAddress}</strong>,
        initialDate: (
          <Typography className="color-stroke">{item.initalDate}</Typography>
        ),
        action: (
          <Select
            className={styles.table_select_container}
            placeholder={"عملیات"}
            onChange={(e) => {
              navigate(e);
            }}
            options={[
              {
                value: "/orders/vendees_management/" + item.id,
                label: <label className="color-stroke">جزئیات</label>,
              },
            ]}
          />
        ),
      };
    });
  }, [data]);
  return (
    <Table
      className={styles.table_container}
      dataSource={dataSource}
      columns={VENDEES_TABLE_COLUMN}
    />
  );
};
