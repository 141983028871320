import { Form, Flex } from "antd";
import { Field, FieldProps, Formik } from "formik";
import { DatePickerFormik, Icon, SearchInput, Button } from "ui";
import { useAppStore } from "store";

export const ActionBar = () => {
  const { setModalVisibility } = useAppStore((state) => state);
  const [form] = Form.useForm<any>();
  const initialValues = {
    name: undefined,
    adsPage: undefined,
    url: undefined,
    status: true,
  };
  return (
    <Flex gap={10} className="w-100" justify="space-between">
      <Formik<any>
        onSubmit={(values) => {}}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form form={form} className="w-100">
            <Flex gap={10} justify="space-between">
              <Form.Item wrapperCol={{ span: 30 }}>
                <Field name="search">
                  {({ field }: FieldProps) => (
                    <SearchInput
                      size="large"
                      placeholder="جستجوی خریدار"
                      {...field}
                    />
                  )}
                </Field>
              </Form.Item>
              <Form.Item>
                <Field name="initialDate">
                  {({ field }: FieldProps) => (
                    <DatePickerFormik
                      size="lg"
                      label="تاریخ عضویت"
                      placeholder=""
                      {...field}
                    />
                  )}
                </Field>
              </Form.Item>
            </Flex>
          </Form>
        )}
      </Formik>
      <Flex gap={10}>
        <Button size="large" type="success" variant="outlined">
          <Icon type="sr-download" />
          <span>خروجی اکسل</span>
        </Button>
        <Button
          onClick={() => setModalVisibility("VENDEE_ADD_MODAL")}
          size="large"
          type="primary"
        >
          <Icon type="sr-plus" />
          <span>افزودن کاربر</span>
        </Button>
      </Flex>
    </Flex>
  );
};
