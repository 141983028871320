import { Outlet } from "react-router-dom";

import MyBreadcrumb from "./Mybreadcrum";
import Aside from "./Aside";
import Header from "./Header";

import "./index.scss";

const DashboardLayout = ({ children, usersettings }) => {
  return (
    <div className="dashboard-layout">
      <header className="dashboard-layout__header">
        <Header usersettings={usersettings} />
      </header>

      <div className="dashboard-layout__wrapper">
        <aside className="dashboard-layout__aside custom-scroll">
          <Aside />
        </aside>

        <main className="dashboard-layout__main">
          <div>
            <MyBreadcrumb />
          </div>
          {<Outlet />}
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
