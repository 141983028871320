import { ActionBar } from "./actionbar";
import SpecModal from "./modal";
import SpecsListTable from "./table";

export const SpecsList = () => {
  return (
    <div>
      <ActionBar />
      <SpecsListTable />
      <SpecModal />
    </div>
  );
};
