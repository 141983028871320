import Cookies from "js-cookie";

// Function to check if the user is authenticated
export const isAuthenticated = () => {
  const token = Cookies.get("token");

  return !!token;
};

export const setAccessToken = (token: string): void => {
  Cookies.set("token", token);
};

export const getAccessToken = (): string | undefined => {
  return Cookies.get("token");
};

export const removeAccessToken = (): void => {
  Cookies.remove("token");
};

export const setRefreshToken = (token: string): void => {
  Cookies.set("refresh", token);
};

export const getRefreshToken = (): string | undefined => {
  return Cookies.get("refresh");
};

export const removeRefreshToken = (): void => {
  Cookies.remove("refresh");
};
