import { Flex, Table } from "antd";
import { useMemo } from "react";
import { ORDERS_DETAILS_COLUMNS } from "../../constant";

import styles from "../index.module.scss";
interface OrdersManagementTableProp {
  data: any;
}
export const OrdersDetailsTable: React.FC<OrdersManagementTableProp> = ({
  data,
}) => {
  const dataSource = useMemo(() => {
    return data?.map((item: any) => {
      return {
        product: (
          <Flex vertical gap={5}>
            <strong className={styles.table_product_title}>
              {item.product}
            </strong>
            <label className="color-stroke"> تاریخ ارسال {item.date} </label>
          </Flex>
        ),
        productValue: item.product,
        amount: <strong className="color-stroke">{item.amount}</strong>,
        id: <strong className="color-stroke">{item.id}</strong>,
        price: <strong className="color-stroke">{item.price}</strong>,
        totalPrice: <strong className="color-stroke">{item.totalPrice}</strong>,
      };
    });
  }, [data]);
  return (
    <Flex vertical gap={10} className="box">
      <h1>سفارش شماره #INV-094984111</h1>
      <Table
        className={styles.table_container}
        dataSource={[
          ...dataSource,
          {
            price: <strong className="color-stroke"> جمع کل پرداختی :</strong>,
            totalPrice: <strong className="color-stroke">100,000,000</strong>,
          },
          {
            price: <strong className="color-stroke">هزینه ارسال :</strong>,
            totalPrice: <strong className="color-stroke">200,000</strong>,
          },
          {
            price: <h1>قابل پرداخت</h1>,
            totalPrice: <h1>120,000,000</h1>,
          },
        ]}
        columns={ORDERS_DETAILS_COLUMNS}
      />
    </Flex>
  );
};
