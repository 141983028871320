import { Button, Form, Input } from "antd";
import { ErrorMessage, Field, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useChangePassword } from "core/services/authentication/useChangePassword";

import { authChangePassSchema } from "./schema";
export default function AutnChangePassForm() {
  const navigate = useNavigate();
  const { mutate, isPending } = useChangePassword();

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      validationSchema={authChangePassSchema}
      onSubmit={(data) => {
        mutate(data);
      }}
    >
      {({ validateForm, isValid, submitForm }) => (
        <Form className="login-form">
          <div className="login-form__password">
            <Field
              name="currentPassword"
              type="password"
              placeholder="پسورد قبلی"
              as={Input}
            />
            <ErrorMessage name="currentPassword" component="div" />
          </div>
          <div className="login-form__password">
            <Field
              name="newPassword"
              type="password"
              placeholder="پسورد جدید"
              as={Input}
            />
            <ErrorMessage name="newPassword" component="div" />
          </div>
          <div className="login-form__password">
            <Field
              name="confirmNewPassword"
              type="password"
              placeholder="تکرار پسورد جدید"
              as={Input}
            />
            <ErrorMessage name="confirmNewPassword" component="div" />
          </div>

          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form__button"
            loading={isPending}
            onClick={() => {
              validateForm().then(() => {
                if (isValid) {
                  submitForm();
                }
              });
            }}
          >
            تغییر پسورد
          </Button>
          <Button
            htmlType="reset"
            type="text"
            className="reset-button"
            onClick={() => {
              navigate("/dashboard/overview");
            }}
          >
            لغو
          </Button>
        </Form>
      )}
    </Formik>
  );
}
