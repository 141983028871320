import { Flex } from "antd";
import { BrandAvatar } from "ui";

import styles from "../../index.module.scss";

export const ActiveBrandsWithAdvertise = ({ data }: { data: any }) => {
  return (
    <Flex vertical justify="space-between" className={`${styles.card} box`}>
      <div>
        <h1>15,243</h1>
        <label className="color-stroke">تعداد کلی بنرد های دارای تبلیغ</label>
      </div>

      <Flex vertical gap={10}>
        <h1>برند های دارای تبلیغات فعال</h1>
        <Flex className={styles.brand_avatar_container}>
          {[{ name: 4 }, ...data.slice(0, 8)].map((item: any) => {
            return (
              <BrandAvatar
                img={item.img}
                label={item.name}
                className={styles.brands_avatar}
              />
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};
