import { useMutation } from "@tanstack/react-query";
import { AUTH_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type ChangePasswordData = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const useChangePassword = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: ChangePasswordData) =>
      instance.post(AUTH_END_POINTS.change_password, {
        ...data,
      }),
    onSuccess: (res) => {
      if (res.data.StatusCode === 500) {
        toast.error(` ${res.data.Message}`);
      } else {
        toast.success("پسورد با موفقیت تغییر کرد");
        navigate("/");
      }
    },
    onError: (e: any) => {
      toast.error(`${e.response?.data?.Message || "خطای در تغییر پسورد"}`);
    },
  });
};
