import { ReactNode } from "react";
import {
  Mutation,
  MutationCache,
  Query,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "core/utils/auth-utils";
import { refreshToken } from "core/services/authentication/useLogin";

let isRedirecting = false;
let isRefreshing = false;
let failedQueue: {
  query?: Query<unknown, unknown, unknown>;
  mutation?: Mutation<unknown, unknown, unknown, unknown>;
  variables?: unknown;
}[] = [];

const errorHandler = (
  error: unknown,
  query?: Query<unknown, unknown, unknown>,
  mutation?: Mutation<unknown, unknown, unknown, unknown>,
  variables?: unknown
) => {
  const errorResponse = (error as AxiosError<any>)?.response;

  if (errorResponse?.status === 401) {
    if (mutation) refreshTokenAndRetry(undefined, mutation, variables);
    else refreshTokenAndRetry(query);
  } else console.error(errorResponse?.data);
};

export const queryErrorHandler = (
  error: Error,
  query: Query<unknown, unknown, unknown>
) => {
  errorHandler(error, query);
};

export const mutationErrorHandler = (
  error: unknown,
  variables: unknown,
  context: unknown,
  mutation: Mutation<unknown, unknown, unknown, unknown>
) => {
  errorHandler(error, undefined, mutation, variables);
};

const processFailedQueue = () => {
  failedQueue.forEach(({ query, mutation, variables }) => {
    if (mutation) {
      const { options } = mutation;
      mutation.setOptions(options);
      mutation.execute(variables);
    }
    if (query) query.fetch();
  });
  isRefreshing = false;
  failedQueue = [];
};

const refreshTokenAndRetry = async (
  query?: Query<unknown, unknown, unknown>,
  mutation?: Mutation<unknown, unknown, unknown, unknown>,
  variables?: unknown
) => {
  try {
    if (!isRefreshing) {
      isRefreshing = true;
      failedQueue.push({ query, mutation, variables });

      const refreshData = await refreshToken();

      setAccessToken(refreshData?.data?.accessToken);
      setRefreshToken(refreshData?.data?.refreshToken);

      processFailedQueue();
    } else failedQueue.push({ query, mutation, variables });
  } catch (e) {
    removeAccessToken();
    removeRefreshToken();

    if (!isRedirecting) {
      isRedirecting = true;
      window.location.reload();
    }
  }
};

export const ClientProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: queryErrorHandler,
    }),
    mutationCache: new MutationCache({
      onError: mutationErrorHandler,
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
