import React from "react";
import { Flex } from "antd";

import styles from "../index.module.scss";
interface ProductCardProp {
  name: string;
  price: string;
  count: number;
  img: string;
  id: string;
}
export const ProductCard: React.FC<ProductCardProp> = ({
  name,
  price,
  count,
  img,
  id,
}) => {
  return (
    <div className={styles.product_card}>
      <Flex gap={10}>
        <img src={img} alt={name} width={30} height={30} />
        <Flex vertical gap={5}>
          <h1>{name}</h1>
          <label className="color-primary fs-x-small">
            قیمت محصول : {price} تومان
          </label>
          <label className="color-stroke fs-x-small">شناسه محصول : {id}</label>
          <label className="color-stroke  fs-x-small">
            تعداد/مقدار : {count} عدد
          </label>
        </Flex>
      </Flex>
    </div>
  );
};
