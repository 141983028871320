import { Input, Form, Flex  } from "antd";
import { Field, FieldProps, Formik } from "formik";
import { DatePickerFormik, Icon } from "ui";

export const ActionBar = () => {
  const [form] = Form.useForm<any>();
  const initialValues = {
    name: undefined,
    adsPage: undefined,
    url: undefined,
    status: true,
  };
  return (
    <Flex gap={10} justify="space-between">
      <Formik<any>
        onSubmit={(values) => {}}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form form={form} className="w-100">
            <Flex gap={10} justify="space-between">
              <Form.Item>
                <Field name="search">
                  {({ field }: FieldProps) => (
                    <Input
                      size="large"
                      {...field}
                      addonBefore={<Icon type="sr-search" />}
                      placeholder="جستجوی "
                    />
                  )}
                </Field>
              </Form.Item>
              <Form.Item>
                <Field name="data">
                  {({ field }: FieldProps) => (
                    <DatePickerFormik
                      size="lg"
                      label="تاریخ:"
                      placeholder=""
                      {...field}
                    />
                  )}
                </Field>
              </Form.Item>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
