export const DUMMY_DATA = [
  {
    name: "آنتی اسکالانت فلوکن 260",
    count: 2,
    type: "محصول",
    status: true,
    date: "02/10/1402 02:50",
    id: "228",
  },
  {
    name: "آنتی اسکالانت فلوکن 260",
    count: 2,
    type: "محصول",
    status: true,
    date: "02/10/1402 02:50",
    id: "203.88.228",
  },
  {
    name: "آنتی اسکالانت فلوکن 260",
    count: 1,
    type: "محصول",
    status: true,
    date: "02/10/1402 02:50",
    id: "2r8.228",
  },
  {
    name: "دستگاه تصفیه آب خانگی مدل SC11S5",
    count: 1,
    type: "محصول",
    status: true,
    date: "02/10/1402 02:50",
    id: "2t2218",
  },
  {
    name: "لندینگ کربن اکتیو",
    count: 24,
    type: "محصول",
    status: false,
    date: "02/10/1402 02:50",
    id: "209r.38",
  },
];
