import { Doughnut } from "react-chartjs-2";
import { Flex } from "antd";
import { ChartData, ChartOptions } from "chart.js";
import "chart.js/auto";

interface DoughnutProps {
  data: ChartData<"doughnut">;
  options?: ChartOptions<"doughnut">;
  size?: string;
}

export const DoughnutChart: React.FC<DoughnutProps> = ({
  data,
  size = "80px",
}) => {
  const options = {
    cutout: "80%",
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleColor: "#3F4254",
        titleFont: {
          size: 8,
          family: "IRANSans",
        },
        bodyColor: "#3D4051",
        backgroundColor: "white",
        bodyFont: {
          size: 10,
          family: "IRANSans",
        },
      },
    },
  };
  const dataset = data.datasets[0];
  const labels = data.labels || [];
  return (
    <Flex gap={40}>
      <div style={{ width: size, height: size }}>
        <Doughnut data={data} options={options} />
      </div>

      <Flex vertical gap={10}>
        {labels &&
          labels.map((value, index) => {
            //@ts-ignore
            if (data.datasets[0].backgroundColor[index] !== "#E4E6EF") {
              return (
                <Flex
                  key={index}
                  align="center"
                  gap={26}
                  justify="space-between"
                >
                  <Flex gap={10} justify="center" align="center">
                    <span
                      style={{
                        width: "6px",
                        height: "3px",
                        //@ts-ignore
                        backgroundColor: dataset.backgroundColor[index],
                      }}
                    ></span>
                    <span className="color-stroke"> {value as string}</span>
                  </Flex>
                  <span className="color-primary">{dataset.data[index]}</span>
                </Flex>
              );
            }
          })}
      </Flex>
    </Flex>
  );
};
