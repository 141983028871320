import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { USER_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";

import { userForm } from "../type";

const updateUser = async ({ data }: { data: userForm }) => {
  const formData = new FormData();
  formData.append("FirstName", data.firstName ?? "");
  formData.append("LastName", data.lastName ?? "");
  formData.append("RoleId", data.roleId ?? "0");
  formData.append("MobileNumber", data.phoneNumber ?? "");
  formData.append("Email", data.email ?? "");

  if (data.avatar) {
    console.log(data.avatar, "data.avatar");
    formData.append("Width", String(data.avatar.width));
    formData.append("Height", String(data.avatar.height));
    formData.append("File", data.avatar.file);
  }
  const res = await instance.post(USER_END_POINTS.update, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [USER_END_POINTS.update, USER_END_POINTS.list],
    mutationFn: updateUser,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در ویرایش کاربر"}`);
    },
    onSuccess: () => {
      toast.success(`کاربر با موفقیت ویرایش گردید`);
      queryClient.invalidateQueries({ queryKey: [USER_END_POINTS.list] });
    },
  });
};
