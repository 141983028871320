interface RightArrow {
  height?: number | string;
  width?: number | string;
  className?: string;
  color?: string;
}
export const RightArrow = ({
  height = "11.654",
  width = "6.474",
  className,
  color = "#b5b5c3",
}: RightArrow) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox="0 0 6.474 11.654"
  >
    <path
      id="sr-chevron-up"
      d="M8.369,6.19a.647.647,0,0,1,.916,0l5.179,5.179a.647.647,0,0,1-.916.916L8.827,7.563,4.105,12.285a.647.647,0,0,1-.916-.916Z"
      transform="translate(12.474 -3) rotate(90)"
      fill={color}
      fill-rule="evenodd"
    />
  </svg>
);
