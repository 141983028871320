import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { STORES_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { UserSetting } from "domain/api/dashboard/userSetting";
import moment from "moment";
import { toast } from "react-toastify";

export const useGetStores = () =>
  useMutation({
    mutationFn: (params: any) => {
      let filters = params.filters ?? [];
      if (params.search && params.search.length > 0) {
        filters = [
          ...filters,
          {
            propertyName: "name",
            operation: "contains",
            value: params.search,
          },
        ];
      }

      const queryData = {
        page: params.page,
        inputParams: {
          filters,
        },
      };

      return instance.post(STORES_END_POINTS.list, {
        ...queryData,
      });
    },
    onError: (err: any) => {
      console.error(err);
      toast.error(
        `${err.response?.data?.Message || "خطا در دریافت لیست فروشگاه ها"}`
      );
    },
  });
