export default function IconCamera({
  width = 22,
  height = 20,
  color = "#b7b7c4",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 20"
    >
      <g id="sr-camera-add" transform="translate(-1 -2)">
        <path
          id="Path_566"
          data-name="Path 566"
          d="M10,2a1,1,0,0,0,0,2h5.279l.772,2.316A1,1,0,0,0,17,7h3a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,0-2,0v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V8a3,3,0,0,0-3-3H17.721l-.772-2.316A1,1,0,0,0,16,2Z"
          fill={color}
        />
        <path
          id="Path_567"
          data-name="Path 567"
          d="M8,7A1,1,0,0,0,8,5H6V3A1,1,0,0,0,4,3V5H2A1,1,0,0,0,2,7H4V9A1,1,0,0,0,6,9V7Z"
          fill={color}
        />
        <path
          id="Path_568"
          data-name="Path 568"
          d="M17,13a5,5,0,1,1-5-5A5,5,0,0,1,17,13Zm-2,0a3,3,0,1,1-3-3A3,3,0,0,1,15,13Z"
          fill={color}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
