import { ToastContainer } from "react-toastify";
import { AntdProvider } from "providers/antd-provider";
import { RouterProvider } from "providers/router-provider";
import { ClientProvider } from "providers/client-provider";

function App() {
  return (
    <AntdProvider>
      <ClientProvider>
        <RouterProvider />
      </ClientProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AntdProvider>
  );
}

export default App;
