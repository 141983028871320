import { AlignType } from "rc-table/lib/interface";

export const SEO_TABLE_COLUMN = [
  {
    title: "نام سئو",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: "تعداد سئو",
    dataIndex: "count",
    key: "count",
    align: "center" as AlignType,
  },
  {
    title: "نوع",
    dataIndex: "type",
    key: "type",
    align: "center" as AlignType,
  },
  {
    title: "آی دی ",
    dataIndex: "id",
    key: "id",
    align: "center" as AlignType,
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
  },

  {
    title: "تاریخ ثبت",
    dataIndex: "date",
    key: "date",
    align: "center" as AlignType,
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
  },
];
