import { useMemo } from "react";
import { Divider, Flex, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { useUpdateUserSettings } from "core/services/dashboard/useUpdateUserSettings";
import { useGetUserSettings } from "core/services/dashboard/useGetUserSettings";
import { useQueryClient } from "@tanstack/react-query";
import { DASHBOARD_END_POINTS } from "core/constants/endpoints";
import { toast } from "react-toastify";
import { removeNulls } from "core/utils/json-utils";
import { getRand } from "core/utils/common-utils";
import { Profile } from "pages/dashboard/profile";
import SettingSectionsLayout from "./SettingSectionsLayout";
import SettingForm from "./SettingForm";
import LoginMethodsSection from "./LoginMethodsSection";

const MENU_ITEMS = [
  {
    label: <Link to={"/dashboard/overview"}>نمای کلی</Link>,
    key: "/dashboard/overview",
  },
  {
    label: <Link to={"/dashboard/settings"}>تنظیمات</Link>,
    key: "/dashboard/settings",
  },
];

export const Settings = () => {
  const navigator = useNavigate();
  const queryClient = useQueryClient();

  const { data: usersettings } = useGetUserSettings();
  const { mutate, isPending: isUpdating } = useUpdateUserSettings();

  const performSettingsUpdate = (data: any) => {
    // Convert null values to false (because server does not recognize null as Boolean)
    data.alerts = removeNulls(data.alerts);
    data.communicationWays = removeNulls(data.communicationWays);
    data.notifications = removeNulls(data.notifications);

    mutate(data, {
      onSuccess: (res) => {
        console.log("onSuccess", data);
        //@ts-ignore
        queryClient.invalidateQueries([DASHBOARD_END_POINTS.user_setting]);
        toast.success("تغییرات اعمال شدند");
      },
      onError: (err) => {
        //@ts-ignore
        toast.error(`${err.response?.data?.Message || "خطا در اعمال تغییرات"}`);
        console.log("onError", err);
      },
    });
  };

  const settingsForm = useMemo(
    () => (
      <div className="mt-5">
        <SettingForm
          key={usersettings?.userId ?? getRand()}
          usersettings={usersettings}
          loading={isUpdating}
          onSubmit={performSettingsUpdate}
        />
      </div>
    ),
    [usersettings]
  );

  return (
    <Flex vertical gap={16}>
      <Profile />
      <Divider style={{ marginBottom: 0, marginTop: 0 }} />
      <Menu
        onClick={(e) => navigator(e.key)}
        defaultSelectedKeys={["/dashboard/settings"]}
        mode="horizontal"
        items={MENU_ITEMS}
        style={{ fontWeight: "500" }}
      />
      <div>
        <SettingSectionsLayout title="تنظیمات حساب کاربری">
          <Link to="/dashboard/settings">{settingsForm}</Link>
        </SettingSectionsLayout>

        <SettingSectionsLayout title="روش ها ی ورود به سیستم">
          <LoginMethodsSection />
        </SettingSectionsLayout>

        {/* <SettingSectionsLayout title="اطلاع رسانی">
        <WarningSections
          usersettings={usersettings}
          onSubmit={performSettingsUpdate}
        />
      </SettingSectionsLayout> */}

        {/* <SettingSectionsLayout title="تنظیمات هشدارها">
        <WarningSettingSection
          usersettings={usersettings}
          onSubmit={performSettingsUpdate}
        />
      </SettingSectionsLayout> */}
      </div>
    </Flex>
  );
};
