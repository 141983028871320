import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { DASHBOARD_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { UserSetting } from "domain/api/dashboard/userSetting";

type UpdateUserType = Partial<UserSetting>;

type ApiResponse = {
  data: any;
};

type QueryOptionsWithoutMutationFn = Omit<
  UseMutationOptions<ApiResponse, AxiosError, UpdateUserType, unknown>,
  "mutationFn"
>;

export const useUpdateUserSettings = (
  options?: QueryOptionsWithoutMutationFn
) =>
  useMutation<ApiResponse, AxiosError, UserSetting>({
    retry: 0,
    ...options,
    mutationFn: async (data: any) => {
      const formData = new FormData();
      formData.append("UserId", data.userId);
      formData.append("ImageWidth", data.imageWidth ?? "0");
      formData.append("ImageHeight", data.imageHeight ?? "0");
      formData.append("ImageSizePercentage", data.imageSizePercentage ?? "1");
      // formData.append("ImageDescription", data.imageDescription ?? "");
      // formData.append("ImageAltText", data.imageAltText ?? "");
      formData.append("Name", data.name ?? "");
      formData.append("Family", data.family ?? "");
      formData.append("Company", data.company ?? "");
      formData.append("Phone", data.phone ?? "0");
      formData.append("WebSite", data.webSite ?? "");
      formData.append("Country", data.country ?? "0");
      formData.append("Language", data.language ?? "0");
      formData.append("Currency", data.currency ?? "0");
      formData.append("CommunicationWays.Phone", data.communicationWays.phone);
      formData.append("CommunicationWays.Email", data.communicationWays.email);
      formData.append("MarketingAccess", data.marketingAccess ?? "false");
      formData.append("Email", data.email ?? "");
      formData.append("TwoFactorEnabled", data.twoFactorEnabled ?? "false");
      formData.append(
        "Notifications.SuccessPayments",
        data.notifications.successPayments
      );
      formData.append(
        "Notifications.FailedPayments",
        data.notifications.failedPayments
      );
      formData.append(
        "Notifications.CustomerPaymentDiscrepancy",
        data.notifications.customerPaymentDiscrepancy
      );
      formData.append(
        "Notifications.RepaymentNotifies",
        data.notifications.repaymentNotifies
      );
      formData.append(
        "Notifications.PaidInvoices",
        data.notifications.paidInvoices
      );
      formData.append("Alerts.SendAlerts.Phone", data.alerts.sendAlerts.phone);
      formData.append("Alerts.SendAlerts.Email", data.alerts.sendAlerts.email);
      formData.append("Alerts.AddNewUser.Phone", data.alerts.sendAlerts.phone);
      formData.append("Alerts.AddNewUser.Email", data.alerts.sendAlerts.email);
      formData.append("Alerts.PaidOrders.Phone", data.alerts.sendAlerts.phone);
      formData.append("Alerts.PaidOrders.Email", data.alerts.sendAlerts.email);
      formData.append("Alerts.NewsLetter.Phone", data.alerts.sendAlerts.phone);
      formData.append("Alerts.NewsLetter.Email", data.alerts.sendAlerts.email);

      const searchParams = new URLSearchParams(
        formData as unknown as Record<string, string>
      ).toString();

      const fileFormData = new FormData();
      fileFormData.append("File", data?.imageFile ?? "");

      const result = await instance.put<ApiResponse>(
        `${DASHBOARD_END_POINTS.update_user_setting}?${searchParams}`,
        fileFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      return result.data;
    },
  });
