export const IconEditPencil = ({
  size = 15,

  color = "#b5b5c3",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 15 15"
  >
    <path
      id="sr-pencil"
      d="M12.72,2.22a.75.75,0,0,1,1.061,0l3,3a.75.75,0,0,1,0,1.061L6.28,16.78a.75.75,0,0,1-.53.22h-3A.75.75,0,0,1,2,16.25v-3a.75.75,0,0,1,.22-.53ZM12.061,5,14,6.939,15.189,5.75,13.25,3.811Zm.879,3L11,6.061,4.561,12.5,6.5,14.439Zm-7.5,7.5L3.5,13.561V15.5Z"
      transform="translate(-2 -2)"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
