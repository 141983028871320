import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Form,
  Input,
  Switch,
  Upload,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./index.scss";
import { useMemo, useState } from "react";
import { AvatarUpload } from "ui";
import { getImageSrc } from "core/utils/json-utils";
export default function SettingForm({ usersettings, loading, onSubmit }) {
  const [selectedImage, setSelectedImage] = useState(null);

  const [form] = Form.useForm();

  const getCommunicationWaysArray = () => {
    const waysObject = usersettings?.communicationWays ?? {};
    return Object.keys(waysObject).filter((k) => waysObject[k]);
  };

  const initialValues = {
    ...usersettings,
    communicationWays: getCommunicationWaysArray(),
  };

  const onSubmitClick = () => {
    let formValues = form.getFieldsValue();
    formValues.communicationWays = {
      email: formValues.communicationWays.includes("email"),
      phone: formValues.communicationWays.includes("phone"),
    };

    onSubmit?.({
      ...usersettings,
      ...formValues,
      imageWidth: selectedImage?.width,
      imageHeight: selectedImage?.height,
      imageFile: selectedImage?.file,
    });
  };

  const onFormReset = () => {
    form.resetFields();
  };

  const handleUploadChange = (info) => {
    const file = info.fileList?.[0]?.originFileObj;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          setSelectedImage({
            file: file,
            width: width,
            height: height,
          });
        };
        img.src = e.target?.result;
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Form className="form" form={form} initialValues={initialValues}>
      <Form.Item
        name="image"
        label="آواتار:"
        labelCol={{ span: 4 }}
        labelAlign="left"
        wrapperCol={{ span: 20 }}
        valuePropName="image"
      >
        <AvatarUpload
          url={
            usersettings?.imageUrl
              ? getImageSrc(usersettings?.imageUrl, true)
              : undefined
          }
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          onChange={handleUploadChange}
        />
      </Form.Item>
      <Form.Item
        label="نام کامل"
        labelCol={{ span: 4 }}
        labelAlign="left"
        style={{
          marginBottom: 0,
        }}
      >
        <div className="setting_fullName_container">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "لطفا نام خود را وارد کنید",
              },
            ]}
            style={{
              display: "inline-block",
              width: "50%",
            }}
          >
            <Input placeholder="نام" />
          </Form.Item>
          <Form.Item
            name="family"
            rules={[
              {
                required: true,
                message: "لطفا نام خانوادگی خود را وارد کنید",
              },
            ]}
            style={{
              display: "inline-block",
              width: "50%",
            }}
          >
            <Input placeholder="نام خانوادگی" />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        name="phone"
        label="شماره تماس"
        labelCol={{ span: 4 }}
        labelAlign="left"
        className="w-100"
        rules={[
          {
            required: true,
            message: "لطفا شماره تماس خود را وارد کنید",
          },
        ]}
      >
        <Input placeholder=" شماره تماس" />
      </Form.Item>
      <Form.Item
        name="email"
        label="ایمیل"
        labelCol={{ span: 4 }}
        labelAlign="left"
        className="w-100"
        rules={[{ required: true, message: "لطفا ایمیل خود را وارد کنید" }]}
      >
        <Input placeholder="ایمیل" />
      </Form.Item>
      {/* <Form.Item
        name="webSite"
        label="وبسایت شرکت:"
        labelCol={{ span: 4 }}
        labelAlign="left"
      >
        <Input placeholder="وبسایت شرکت" />
      </Form.Item> */}

      <Form.Item
        name="communicationWays"
        label="نحوه ارتباطات:"
        labelCol={{ span: 4 }}
        labelAlign="left"
      >
        <Checkbox.Group>
          <Checkbox
            value="phone"
            style={{
              lineHeight: "32px",
            }}
          >
            تلفن همراه
          </Checkbox>

          <Checkbox
            value="email"
            style={{
              lineHeight: "32px",
            }}
          >
            ایمیل
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name="marketingAccess"
        label="دسترسی برای بازاریابی:"
        valuePropName="checked"
        labelCol={{ span: 4 }}
        labelAlign="left"
      >
        <Switch />
      </Form.Item>
      <Divider />
      <Form.Item className="submit-button">
        <Button
          htmlType="reset"
          type="text"
          className="reset-button"
          onClick={onFormReset}
        >
          لغو کردن
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onSubmitClick}
          loading={loading}
        >
          ذخیره تغییرات
        </Button>
      </Form.Item>
    </Form>
  );
}
