import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { TECH_SPEC_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { specType } from "../type";

const updateSpec = async ({ data }: { data: specType }) => {
  const res = await instance.put(TECH_SPEC_ENDPOINTS.update, data);

  return res.data;
};

export const useUpdateTechnicalSpecification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [TECH_SPEC_ENDPOINTS.update, TECH_SPEC_ENDPOINTS.list],
    mutationFn: updateSpec,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در ویرایش ویژگی"}`);
    },
    onSuccess: () => {
      toast.success(`ویژگی با موفقیت ویرایش شد`);
      queryClient.invalidateQueries({
        queryKey: [TECH_SPEC_ENDPOINTS.list],
      });
    },
  });
};
