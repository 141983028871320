import { Form, Flex } from "antd";
import { Button, DatePickerAntd, Icon, SearchInput } from "ui";
import { useAppStore } from "store";
import { useUserManagementStore } from "../../store";
import { userManagementFilter } from "../../type";
interface FormValues {
  search: string;
  toDate: string;
  fromDate: string;
}
export const ActionBar = () => {
  const { setModalVisibility } = useAppStore((state) => state);
  const { setFilter, setFormData, setIsEdit } = useUserManagementStore(
    (state) => state
  );
  const [form] = Form.useForm<FormValues>();
  const onValuesChangeHandler = (
    changedValues: FormValues,
    allValues: FormValues
  ) => {
    setFilter(
      Object.entries(changedValues)
        .filter(([_, value]) => value?.length > 0)
        .map(([key, value]) => {
          return {
            propertyName: key as userManagementFilter,
            operation: "contains",
            value: value as string,
          };
        })
    );
  };
  return (
    <Flex className="box w-100" gap={10} justify="space-between">
      <Form
        form={form}
        className="w-100"
        onValuesChange={onValuesChangeHandler}
      >
        <Flex gap={10}>
          <Form.Item name={"firstName"}>
            <SearchInput size="large" placeholder="جستوجوی کاربر " />
          </Form.Item>
          <Flex gap={10}>
            <Form.Item name={"fromDate"}>
              <DatePickerAntd
                size="lg"
                label="از:"
                placeholder=""
                name={"fromDate"}
              />
            </Form.Item>
            <Form.Item name={"toDate"}>
              <DatePickerAntd
                size="lg"
                label="تا:"
                placeholder=""
                name={"toDate"}
              />
            </Form.Item>
          </Flex>
        </Flex>
      </Form>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          setFormData(undefined);
          setIsEdit(false);
          setModalVisibility("USER_MANAGEMENT_MODAL");
        }}
      >
        <Icon type="sr-plus" />
        <span>افزودن کاربر </span>
      </Button>
    </Flex>
  );
};
