export const IconHome = ({ size = 24, color = '#000000' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height={size}
    viewBox='0 0 14.88 14.958'
  >
    <path
      id='sr-home'
      d='M9.546,1.213a2.029,2.029,0,0,0-2.212,0L1.923,4.73A2.029,2.029,0,0,0,1,6.432v7.382a2.029,2.029,0,0,0,2.029,2.029H13.851a2.029,2.029,0,0,0,2.029-2.029V6.432a2.029,2.029,0,0,0-.923-1.7ZM8.072,2.347a.676.676,0,0,1,.737,0L14.22,5.865a.676.676,0,0,1,.308.567v7.382a.676.676,0,0,1-.676.676H11.146v-5.1A2.544,2.544,0,0,0,8.44,7.05,2.544,2.544,0,0,0,5.735,9.392v5.1H3.029a.676.676,0,0,1-.676-.676V6.432a.676.676,0,0,1,.308-.567ZM7.087,14.491H9.793v-5.1c0-.427-.474-.989-1.353-.989s-1.353.562-1.353.989Z'
      transform='translate(-1 -0.885)'
      fill={color}
      fillRule='evenodd'
    />
  </svg>
);
