import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import LoginMethodPiece from "./LoginMethodPiece";
import "./index.scss";
export default function LoginMethodsSection() {
  const navigate = useNavigate();

  return (
    <>
      <LoginMethodPiece
        methodName="پسورد"
        methodAction="تغییر پسورد"
        onAction={() => {
          navigate("/auth/change-password");
        }}
      >
        <Input
          type="password"
          placeholder="***********"
          variant="borderless"
          disabled
        />
      </LoginMethodPiece>
    </>
  );
}
