import React, { useEffect, useState } from "react";
import { Button, Input, Select, Space, Table, Tag } from "antd";
import styles from "./StoresManagementTable.module.scss";
import { useGetStores } from "core/services/store/useGetStores";
import { toDateTime } from "core/utils/date-time";

const columns = [
  {
    title: <div className="column">نام فروشگاه</div>,
    render: (store) => {
      return <div>{store?.name ?? "-"}</div>;
    },
  },
  {
    title: <div className="column">لینک فروشگاه</div>,
    render: (store) => {
      return <div>{store?.link ?? "-"}</div>;
    },
  },
  {
    title: <div className="column">آخرین بازدید</div>,
    render: (store) => {
      return <div>{store?.lastVisit ? toDateTime(store.lastVisit) : "-"}</div>;
    },
  },
  {
    title: <div className="column">تاریخ ثبت فروشگاه</div>,
    render: (store) => {
      return (
        <div>
          {store?.registerDatePersian ? store.registerDatePersian : "-"}
        </div>
      );
    },
  },
  {
    title: <div className="column">امتیاز</div>,
    render: (store) => {
      return <div>{store?.rate ?? "-"}</div>;
    },
  },
  {
    title: <div className="column">تعداد فروش</div>,
    render: (store) => {
      return <div>{store?.sellCount ?? "-"}</div>;
    },
  },
  {
    title: <div className="column">تعداد سفارش</div>,
    render: (store) => {
      return <div>{store?.orderCount ?? "-"}</div>;
    },
  },
  {
    title: <div className="column">عملیات</div>,
    dataIndex: "action",
    render: () => {
      return (
        <Select
          defaultValue="نوع عملیات"
          style={{ width: 120 }}
          className={styles["primary"]}
          options={[
            {
              value: "Delete",
              label: "حذف فروشگاه",
            },
            {
              value: "Edit",
              label: "ویرایش کردن",
            },
          ]}
        />
      );
    },
  },
];

const StoresManagementTable = () => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [search, setSearch] = useState("");
  const [refresher, setRefresher] = useState(0);

  const { mutate: loadList, isPending } = useGetStores();

  useEffect(() => {
    loadList(
      { page, search },
      {
        onSuccess: (res) => {
          const storesList = (res.data?.data?.data ?? []).map((item) => ({
            ...item,
            key: item.id,
          }));

          if (storesList) {
            setList(storesList);
            setTotalItems(res?.data?.data?.totals ?? 1);
          }
        },
      }
    );
  }, [page, search, refresher]);

  return (
    <div
      style={{
        marginBottom: 16,
      }}
      className={styles["table-container"]}
    >
      <Table
        locale={{ emptyText: 'هیچ داده ای وجود ندارد' }}
        className="table"
        columns={columns}
        dataSource={list}
        loading={isPending}
        pagination={{
          showSizeChanger: false,
          total: totalItems,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </div>
  );
};
export default StoresManagementTable;
