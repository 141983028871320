import { InvalidateQueryFilters, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { USER_END_POINTS } from 'core/constants/endpoints'
import instance from 'core/constants/request'
import { toast } from 'react-toastify'

type ApiResponse = {
    data: any
}

type QueryOptionsWithoutMutationFn = Omit<UseMutationOptions<ApiResponse, AxiosError, number, unknown>, 'mutationFn'>

export const useDeleteUser = (options?: QueryOptionsWithoutMutationFn) => {
    const queryClient = useQueryClient()

    return useMutation<ApiResponse, AxiosError, number>({
        retry: 0,
        ...options,
        mutationFn: async (id: number) => {
            const result = await instance.delete<ApiResponse>(`${USER_END_POINTS.delete}`, {
                data: {
                    id
                }
            })

            return result.data
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries([USER_END_POINTS.list] as InvalidateQueryFilters);
            toast.success(`کاربر با موفقیت حذف گردید`);
        },
        onError: (e: any) => {
            toast.error(`${e.response?.data?.Message || "خطا در حذف کاربر"}`);
        }
    })
}    
