import { Space } from "antd";
import { ActionBar } from "./actionbar";
import Header from "./header";
import SliderManagementModal from "./modal";
import SliderManagementTable from "./table";

export const SliderManagement = () => {
  return (
    <div>
      <Space direction="vertical" size="large" className="w-100">
        <Header />
        <ActionBar />
      </Space>
      <SliderManagementTable />
      <SliderManagementModal />
    </div>
  );
};
