import { Button } from "antd";
import styles from "./index.module.scss";
const PopularProduct = () => {
  return (
    <div className={styles["popular_product_container"]}>
      <div className={styles["popular_product_info"]}>
        <div className={styles["popular_product_visits_num"]}>
          <div>23254</div>
          <div>تعداد بازدید</div>
        </div>
        <div className={styles["popular_product_info_divider"]}></div>
        <div className={styles["popular_product_detail"]}>
          <div>محصول شماره یک</div>
          <div>
            <span>نام فروشنده </span>
            <span>Ak Designer</span>
          </div>
        </div>
      </div>
      <div>
        <Button>مشاهده</Button>
      </div>
    </div>
  );
};

export default PopularProduct;
