import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Flex, Table } from "antd";
import { Key, TableRowSelection } from "antd/es/table/interface";
import { Select, StatusLabel } from "ui";
import { SEO_TABLE_COLUMN } from "../../constant";

export const SEOTable = ({ data }: { data: any }) => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };
  const dataSource = useMemo(() => {
    return data.map((item: any) => {
      return {
        key: item.id,
        name: item.name,
        count: <label className="color-stroke">{item.count}</label>,
        type: <label className="color-stroke">{item.type}</label>,
        status: (
          <Flex justify="center">
            <StatusLabel status={item.status} />
          </Flex>
        ),
        date: <label className="color-stroke">{item.date}</label>,
        id: <label className="color-stroke">{item.id}</label>,
        action: (
          <Select
            placeholder={"عملیات"}
            style={{ width: 120 }}
            onChange={(e) => {
              navigate(e);
            }}
            options={[
              {
                value: `edit`,
                label: "ویرایش",
              },
              {
                value: "add",
                label: "اضافه کردن",
              },
            ]}
          />
        ),
      };
    });
  }, [data]);
  return (
    <Table
      dataSource={dataSource}
      columns={SEO_TABLE_COLUMN}
      rowSelection={rowSelection}
    />
  );
};
