import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { sliderForm } from "../type";

const updateSlider = async ({ data }: { data: sliderForm }) => {
  const update = {
    ...data,
    width: data.image?.width ?? 0,
    height: data.image?.height ?? "0",
    sizePercentage: data.image?.sizePercentage ?? "1",
  };
  const res = await instance.put(HOME_END_POINTS.update_slider, update);

  return res.data;
};

export const useUpdateSlider = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [HOME_END_POINTS.update_slider, HOME_END_POINTS.sliders_list],
    mutationFn: updateSlider,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در ویرایش اسلایدر"}`);
    },
    onSuccess: () => {
      toast.success(`اسلایدر با موفقیت ویرایش شد`);
      queryClient.invalidateQueries({
        queryKey: [HOME_END_POINTS.sliders_list],
      });
    },
  });
};
