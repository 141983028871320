import { useMemo } from "react";
import { Flex, Table } from "antd";
import { ActionButton } from "ui";
import { useListTechnicalSpecifications } from "pages/catalog/specs-list/api/useListTechnicalSpecifications";
import { useDeleteTechnicalSpecification } from "pages/catalog/specs-list/api/useDeleteTechnicalSpecification";
import { useAppStore } from "store";
import { useSpecsListStore } from "../../store";
import { SPECS_LIST_COLUMN } from "../../constant";
import { specType } from "../../type";

const SpecsListTable = () => {
  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const { setFormData, setIsEdit } = useSpecsListStore((state) => state);

  const { data, isPending } = useListTechnicalSpecifications();
  const { mutate: deleteSpecMutation } = useDeleteTechnicalSpecification();

  const dataSource = useMemo(() => {
    return (
      (data ?? []).map((item: specType, index: number) => {
        return {
          key: item.id,
          index: (
            <Flex gap={16} align="center" justify="center">
              <label className="color-primary">{index + 1}</label>
            </Flex>
          ),
          name: <strong className="color-link">{item?.name ?? "ندارد"}</strong>,
          description: (
            <label className="color-primary">{item?.description ?? "-"}</label>
          ),
          action: (
            <Flex gap={8} justify="center">
              <ActionButton
                type="remove"
                onClick={() => deleteSpecMutation({ id: item.id })}
              />
              <ActionButton
                type="edit"
                onClick={() => {
                  setFormData(item);
                  setIsEdit(true);
                  setModalVisibility("SPECS_MANAGEMENT_MODAL");
                }}
              />
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);

  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      columns={SPECS_LIST_COLUMN}
      dataSource={dataSource}
      loading={isPending}
      pagination={false}
    />
  );
};

export default SpecsListTable;
