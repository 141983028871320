import PopularProduct from "./PopularProduct";
import styles from "./index.module.scss";
const PopularProductList = () => {
  return (
    <div className={styles["popular_product_container"]}>
      <h2>محصولات پر بازدید</h2>
      <div>
        <PopularProduct />
        <PopularProduct />
        <PopularProduct />
      </div>
    </div>
  );
};

export default PopularProductList;
