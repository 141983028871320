import { Input, Form } from "antd";
import { Field, FieldProps, useFormik, FormikProvider } from "formik";

export const SecuritySetting = () => {
  const [form] = Form.useForm();
  const formik = useFormik({
    initialValues: {
      telephone: "09305429530",
      password: "09305429530",
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form form={form} className="box">
        <h1 className="fs-small">پروفایل</h1>
        <br />
        <Form.Item
          name="telephone"
          label="نام"
          required
          labelCol={{ span: 3 }}
          labelAlign="left"
          wrapperCol={{ span: 22 }}
        >
          <Field name="telephone">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="password"
          label="کلمه عبور"
          required
          labelCol={{ span: 3 }}
          labelAlign="left"
          wrapperCol={{ span: 22 }}
        >
          <Field name="password">
            {({ field }: FieldProps) => <Input type="password" {...field} />}
          </Field>
        </Form.Item>
      </Form>
    </FormikProvider>
  );
};
