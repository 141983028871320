import { ActionBar } from "./actionbar";
import BrandManagementModal from "./modal";
import BrandManagementTable from "./table";

export const BrandManagement = () => {
  return (
    <div>
      <ActionBar />
      <BrandManagementTable />
      <BrandManagementModal />
    </div>
  );
};
