import { Avatar } from "antd";
import { IconEmail, IconMapPin, IconUser } from "../../../components/icons";
import { useGetUserSettings } from "core/services/dashboard/useGetUserSettings";
import { useGetUserInfo } from "core/services/dashboard/useGetUserInfo";
import { toDate, toTime } from "core/utils/date-time";
import styles from "./index.module.scss";

export const Profile = () => {
  const { data: usersettings } = useGetUserSettings();
  const { data: userInfo } = useGetUserInfo();

  const getAvatar = () => {
    const urlImage = usersettings?.image ?? "";
    if (urlImage.length > 0 && urlImage !== "string") {
      return urlImage.includes("http")
        ? urlImage
        : `${process.env.REACT_APP_BASE_URL}${urlImage}`;
    } else {
      return null;
    }
  };

  return (
    <div className={styles["profile_container"]}>
      <div className={styles["profile_info_container"]}>
        <div>
          {getAvatar() ? (
            <Avatar src={getAvatar()} size={160} shape="square" />
          ) : (
            <div className={styles["avatar-placeholder"]}>
              <img src="/assets/logo/logo-typo.svg" alt="avatar" width="70%" />
            </div>
          )}
        </div>
        <div className={styles["profoile_info_detail"]}>
          <div className={styles["profile_info_last_login"]}>
            <span>
              {usersettings?.name ?? "-"} {usersettings?.family ?? "-"}
            </span>
            <span>آخرین ورود</span>
            <span>{toTime(userInfo?.lastLogin)}</span>
            <span>{toDate(userInfo?.lastLogin)}</span>
          </div>
          <div className={styles["profile_info"]}>
            <div>
              <span>
                <IconEmail size={11} />
              </span>
              <span>{usersettings?.email ?? "-"}</span>
            </div>
            <div>
              <span>
                <IconMapPin size={11} />
              </span>
              <span>{userInfo?.address ?? "-"}</span>
            </div>
            <div>
              <span>
                <IconUser size={11} />
              </span>
              <span>{userInfo?.jobPosition ?? "-"}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
