import { AlignType } from "rc-table/lib/interface";

export const SLIDER_MANAGEMENT_COLUMN = [
  {
    title: "تصویر بنر",
    dataIndex: "image",
    key: "image",
    align: "center" as AlignType,
  },
  {
    title: "نام بنر و تاریخ ثبت",
    dataIndex: "title",
    key: "title",
    align: "center" as AlignType,
  },
  {
    title: "نام صفحه یا دسته ی موردنظر",
    dataIndex: "page",
    key: "page",
    align: "center" as AlignType,
  },
  {
    title: "حجم فایل",
    dataIndex: "size",
    key: "size",
    align: "center" as AlignType,
  },
  {
    title: "لینک",
    dataIndex: "link",
    key: "link",
    align: "center" as AlignType,
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
  },
];
