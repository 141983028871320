import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateNodesFromDOM, $generateHtmlFromNodes } from "@lexical/html";
import { $getRoot, CLEAR_HISTORY_COMMAND } from "lexical";
import { useEffect } from "react";
import { $patchStyleText } from "@lexical/selection";

type Props = {
  state: string;
  onChange?: (html: string) => void;
};

const RestoreEditorStatePlugin: React.FC<Props> = ({ state, onChange }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if ((state ?? "").trim().length === 0) {
      editor.update(() => {
        const selection = $getRoot().select();
        $patchStyleText(selection, { color: "#000", "font-size": "16px" });
      });
    } else {
      editor.update(() => {
        const selection = $getRoot().select();
        // remove previos nodes and clear the editor
        for (let node of selection.getNodes()) {
          node.remove();
        }
        $getRoot().clear();

        //create new notes and insert into root selection
        const parser = new DOMParser();
        const dom = parser.parseFromString(state, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);

        selection.insertNodes(nodes);
      });
    }
  }, [state]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const htmlString = $generateHtmlFromNodes(editor, null);
        onChange?.(htmlString);
      });
    });
  }, [editor]);

  return null;
};

export default RestoreEditorStatePlugin;
