import { useMemo, useState } from "react";
import { Col, Row, Flex, Divider, Input, Table } from "antd";
import { TableRowSelection, Key } from "antd/es/table/interface";
import { Icon, CounterButton } from "ui";
import { ProductCard } from "../product-card";
import { PRODUCTS_COLUMN } from "../../constant";

import styles from "../index.module.scss";

interface SelectProductProp {
  data: any;
}
export const SelectProduct: React.FC<SelectProductProp> = ({ data }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };
  const dataSource = useMemo(() => {
    return data?.map((item: any) => {
      return {
        key: item.id,
        product: (
          <Flex gap={10} className={styles.table_product_card}>
            <img src={item.img} alt={item.name} width={30} height={30} />
            <Flex vertical gap={5}>
              <h1 className="fs-x-small">{item.name}</h1>
              <label className="color-primary fs-xx-small">
                قیمت محصول : {item.price} تومان
              </label>
              <label className="color-stroke fs-xx-small">
                شناسه محصول : {item.id}
              </label>
            </Flex>
          </Flex>
        ),
        count: (
          <Flex justify="flex-end">
            <CounterButton countValue={(value) => console.log(value)} />
          </Flex>
        ),
      };
    });
  }, [data]);
  return (
    <Flex vertical gap={10} className="box">
      <h1>انتخاب محصولات</h1>
      <label>محصولات این سفارش را انتخاب و اضافه کنید</label>
      <Row gutter={[10, 10]} className={styles.product_card_container}>
        {data.map((item: any) => {
          return (
            <Col span={8}>
              <ProductCard {...item} />
            </Col>
          );
        })}
      </Row>
      <h1 className="fs-x-large"> جمع کل مبلغ: 120,000,000 تومان </h1>
      <Divider />
      <Input
        size="large"
        addonBefore={<Icon className="color-stroke" type="sr-search" />}
        placeholder="جستجوی محصول مورد نظر"
      />

      <Table
        rowSelection={rowSelection}
        columns={PRODUCTS_COLUMN}
        dataSource={dataSource}
      />
    </Flex>
  );
};
