import { Input, Form, Flex } from "antd";
import { Button, DatePickerFormik, Icon } from "ui";
import { useAppStore } from "store";
import { Field, FieldProps, Formik } from "formik";
import { IconSearch } from "components/icons/iconSearch";

export const ActionBar = () => {
  const [form] = Form.useForm<any>();
  const initialValues = {
    name: undefined,
    adsPage: undefined,
    url: undefined,
    status: true,
  };
  return (
    <Flex gap={10} justify="space-between">
      <Formik<any>
        onSubmit={(values) => {}}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form form={form}>
            <Flex gap={10} className="w-100">
              <Form.Item wrapperCol={{ span: 30 }}>
                <Field name="search">
                  {({ field }: FieldProps) => (
                    <Input
                      {...field}
                      addonBefore={<IconSearch />}
                      placeholder="جستجوی نام سئو شده"
                    />
                  )}
                </Field>
              </Form.Item>
              <Form.Item>
                <Field name="startDate">
                  {({ field }: FieldProps) => (
                    <DatePickerFormik label="از" {...field} />
                  )}
                </Field>
              </Form.Item>
              <Form.Item>
                <Field name="endtDate">
                  {({ field }: FieldProps) => (
                    <DatePickerFormik label="تا" {...field} />
                  )}
                </Field>
              </Form.Item>
            </Flex>
          </Form>
        )}
      </Formik>
      <Flex gap={10}>
        <Button size="large" type="success" variant="outlined">
          <Icon type="sr-download" />
          <span>خروجی اکسل</span>
        </Button>
        <Button size="large" type="primary">
          <Icon type="sr-plus" />
          <span>افزودن دسته بندی</span>
        </Button>
      </Flex>
    </Flex>
  );
};
