import { Modal } from "ui";
import { DUMMY_DATA } from "../api/dummy-data";
import { ActionBar } from "./action-bar";
import { VendeesTable } from "./table";
import { VendeeAddModal } from "./modal";

export const VendeesManagement = () => {
  return (
    <div className="box">
      <ActionBar />
      <VendeesTable data={DUMMY_DATA} />
      <VendeeAddModal />
    </div>
  );
};
