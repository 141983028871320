import React from "react";
import { Divider, Flex, Typography } from "antd";
import { Icon } from "ui";

interface fileDetailsBoxProp {
  factorId: string;
  delivery: string;
  point: string;
}
export const FileDetailsBox: React.FC<fileDetailsBoxProp> = ({
  factorId,
  delivery,
  point,
}) => {
  return (
    <div className="box h-100">
      <h1>جزئیات فایل {factorId}</h1>
      <br />
      <br />
      <Flex className="color-stroke" justify="space-between">
        <Flex align="center" gap={10}>
          <Icon type="sr-document-paper" size={21} />
          <Typography className="color-stroke"> شماره فاکتور : </Typography>
        </Flex>
        <Typography className="color-stroke"> {factorId} </Typography>
      </Flex>
      <Divider />
      <Flex className="color-stroke" justify="space-between">
        <Flex align="center" gap={10}>
          <Icon type="sr-wallet-bifold" size={21} />
          <Typography className="color-stroke"> حمل و نقل : </Typography>
        </Flex>
        <Typography className="color-stroke"> {delivery} </Typography>
      </Flex>
      <Divider />
      <Flex className="color-stroke" justify="space-between">
        <Flex align="center" gap={10}>
          <Icon type="sr-discountbadges" size={21} />
          <Typography className="color-stroke">امتیاز دریافت شده :</Typography>
        </Flex>
        <Typography className="color-stroke"> {point}&nbsp; امتیاز </Typography>
      </Flex>
    </div>
  );
};
