import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { RequestDTO } from "interface";
import { BANNER_END_POINTS } from "core/constants/endpoints";
import { bannerManagementFilter } from "../type";

type bannersRequestType = RequestDTO<bannerManagementFilter>;
const getBanners = async (
  bannersRequestType: bannersRequestType
): Promise<any> => {
  const res = await instance.post(BANNER_END_POINTS.list, bannersRequestType);

  return res.data;
};

type UseGetBannersOptions = UseQueryOptions<any, unknown, any>;
export const useGetBanners = (
  params: bannersRequestType,
  config?: UseGetBannersOptions
) => {
  return useQuery({
    queryKey: [
      BANNER_END_POINTS.list,
      params.page,
      params.pageSize,
      ...(params.inputParams.filters ? params.inputParams.filters : []),
    ],
    queryFn: () => getBanners(params),
    ...config,
  });
};
