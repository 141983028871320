export const IconFire = ({ size = 24, color = '#000000' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height={size}
    viewBox='0 0 15.796 19.306'
  >
    <path
      id='sr-fire'
      d='M12.319,1.324a.878.878,0,0,0-1.3-.067h0l0,0-.01.01-.031.032c-.025.027-.06.064-.1.112-.084.1-.2.231-.333.407a9.814,9.814,0,0,0-.95,1.522,10.322,10.322,0,0,0-.963,2.911c-.216-.344-.41-.662-.554-.9l-.183-.305L7.836,4.97l-.017-.028A.878.878,0,0,0,6.2,5.26,8.523,8.523,0,0,1,4.766,8.436l-.036.058A9.99,9.99,0,0,0,3.094,12.28a6.415,6.415,0,0,0,2.077,6.1,8.913,8.913,0,0,0,5.769,1.929,8.07,8.07,0,0,0,5.525-1.921A7.756,7.756,0,0,0,18.8,12.408a9.188,9.188,0,0,0-1.972-5.784c-.59-.806-1.291-1.6-2.044-2.451l-.2-.229C13.873,3.155,13.118,2.307,12.319,1.324ZM11.151,4.139a8.332,8.332,0,0,1,.5-.866c.568.67,1.107,1.276,1.607,1.837l.205.231c.759.853,1.405,1.592,1.939,2.321a7.445,7.445,0,0,1,1.633,4.747,6.02,6.02,0,0,1-1.757,4.679,5.209,5.209,0,0,1-1.617.989,3.5,3.5,0,0,0,.741-2.158,6.359,6.359,0,0,0-1.151-3.691,4.715,4.715,0,0,0-1.068-1.1,2.3,2.3,0,0,0-1.292-.479,2.3,2.3,0,0,0-1.292.479,4.715,4.715,0,0,0-1.068,1.1,6.359,6.359,0,0,0-1.151,3.691,3.5,3.5,0,0,0,.744,2.161,5.86,5.86,0,0,1-1.819-1.034A4.669,4.669,0,0,1,4.83,12.536,8.524,8.524,0,0,1,6.259,9.359L6.3,9.3A17.445,17.445,0,0,0,7.32,7.481a18.635,18.635,0,0,0,1.412,2,.878.878,0,0,0,1.532-.658A8.6,8.6,0,0,1,11.151,4.139ZM9.143,15.918a4.606,4.606,0,0,1,.824-2.671,2.972,2.972,0,0,1,.66-.687.978.978,0,0,1,.272-.148.978.978,0,0,1,.272.148,2.972,2.972,0,0,1,.66.687,4.606,4.606,0,0,1,.824,2.671,1.755,1.755,0,0,1-3.51,0Z'
      transform='translate(-3 -1)'
      fill={color}
      fillRule='evenodd'
    />
  </svg>
);
