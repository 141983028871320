import { DropDownProps } from "antd";
import { Dropdown as AntdDropdown } from "antd";
import classNames from "classnames";
import React, { useState } from "react";

import styles from "./index.module.scss";
 
interface DropdownProps extends DropDownProps {}
export const Dropdown: React.FC<DropdownProps> = ({ children, ...rest }) => {
  const [isHovered, setIsHovered] = useState(false);
  const dropdownLabelClass = classNames([
    styles.dropdown_label,
    isHovered && styles.hover,
  ]);

  return (
    <AntdDropdown
      {...rest}
      onOpenChange={(open) => {
        open ? setIsHovered(true) : setIsHovered(false);
      }}
    >
      <button className={dropdownLabelClass}>{children}</button>
    </AntdDropdown>
  );
};
