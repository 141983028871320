export const DUMMY_DATA = [
  {
    name: "شرکت جاکوبی",
    url: "https://abin.ir/products/indust...",
    clickCount: 10,
    status: false,
    releaseDate: "02/10/1402 02:50",
    renewalDate: "02/10/1402 02:50",
    img: "/assets/temp/user.png",
  },
  {
    name: "شرکت جاکوبی",
    url: "https://abin.ir/products/indust...",
    clickCount: 30,
    status: true,
    releaseDate: "02/10/1402 02:50",
    renewalDate: "02/10/1402 02:50",
    img: "/assets/temp/user.png",
  },
  {
    name: "شرکت جاکوبی",
    url: "https://abin.ir/products/indust...",
    clickCount: 5,
    status: true,
    releaseDate: "02/10/1402 02:50",
    renewalDate: "02/10/1402 02:50",
    img: "/assets/temp/user.png",
  },
  {
    name: "شرکت جاکوبی",
    url: "https://abin.ir/products/indust...",
    clickCount: 25,
    status: true,
    releaseDate: "02/10/1402 02:50",
    renewalDate: "02/10/1402 02:50",
    img: "/assets/temp/user.png",
  },
  {
    name: "شرکت جاکوبی",
    url: "https://abin.ir/products/indust...",
    clickCount: 10,
    status: true,
    releaseDate: "02/10/1402 02:50",
    renewalDate: "02/10/1402 02:50",
    img: "/assets/temp/user.png",
  },
  {
    name: "شرکت جاکوبی",
    url: "https://abin.ir/products/indust...",
    clickCount: 30,
    status: true,
    releaseDate: "02/10/1402 02:50",
    renewalDate: "02/10/1402 02:50",
    img: "/assets/temp/user.png",
  },
];

export const DUMMY_DATA_2 = [
  {
    id: "d1b2f7c6-1d2f-4e8b-8e50-1b4d9c8a44d0",
    name: "شرکت جاکوبی",
    url: "https://abin.ir/products/indust...",
    clickCount: 10,
    status: false,
    releaseDate: "02/10/1402 02:50",
    renewalDate: "02/10/1402 02:50",
    img: "https://picsum.photos/300?random=1",
  },
  {
    id: "a5f4b3e1-7c9f-4e9b-b267-3d0a2ff7dbd2",
    name: "شرکت مهراب",
    url: "https://example.com/products/mhr",
    clickCount: 25,
    status: true,
    releaseDate: "01/05/1401 11:00",
    renewalDate: "01/05/1401 11:00",
    img: "https://picsum.photos/300?random=2",
  },
  {
    id: "cf0b9c0e-79d2-4381-9841-5e8d6e0b8af1",
    name: "شرکت سامان",
    url: "https://saman.com/products/sm1",
    clickCount: 16,
    status: true,
    releaseDate: "05/09/1402 11:20",
    renewalDate: "05/09/1402 11:20",
    img: "https://picsum.photos/300?random=12",
  },
  {
    id: "fc3b91e8-23f9-4b8d-91ed-2e1f9a87db9d",
    name: "شرکت مهرگان",
    url: "https://mehregan.com/products/mgn1",
    clickCount: 24,
    status: false,
    releaseDate: "08/04/1401 12:30",
    renewalDate: "08/04/1401 12:30",
    img: "https://picsum.photos/300?random=13",
  },
  {
    id: "4b5e8a12-df07-4707-91c9-90e7d2b9e7e3",
    name: "شرکت آبان",
    url: "https://aban.ir/products/abn1",
    clickCount: 13,
    status: true,
    releaseDate: "18/01/1402 15:10",
    renewalDate: "18/01/1402 15:10",
    img: "https://picsum.photos/300?random=14",
  },
  {
    id: "f2e4b1a3-08d6-41e8-b3df-5e5ad8a56a78",
    name: "شرکت نوین",
    url: "https://novin.ir/products/item1",
    clickCount: 15,
    status: true,
    releaseDate: "15/08/1402 14:30",
    renewalDate: "15/08/1402 14:30",
    img: "https://picsum.photos/300?random=3",
  },
  {
    id: "7baf5c92-dc9a-4d19-87ff-26a3d735019a",
    name: "شرکت آرمان",
    url: "https://arman.com/ind/products/arm1",
    clickCount: 8,
    status: false,
    releaseDate: "10/07/1402 10:20",
    renewalDate: "10/07/1402 10:20",
    img: "https://picsum.photos/300?random=4",
  },
  {
    id: "32b3d1f2-fc6e-418b-a87a-3f4e86bf95c2",
    name: "شرکت پیشرو",
    url: "https://pishro.com/products/pr1",
    clickCount: 18,
    status: true,
    releaseDate: "30/06/1401 08:00",
    renewalDate: "30/06/1401 08:00",
    img: "https://picsum.photos/300?random=5",
  },
  {
    id: "ac7d1f4c-bbfa-4cb1-88f6-0e9d2f1f2e6b",
    name: "شرکت آینده",
    url: "https://ayandeh.ir/products/fut1",
    clickCount: 9,
    status: false,
    releaseDate: "22/09/1402 11:45",
    renewalDate: "22/09/1402 11:45",
    img: "https://picsum.photos/300?random=6",
  },
  {
    id: "f5b3a4c9-ec0d-47a1-bd3f-2b6a8d8e4d11",
    name: "شرکت سپهر",
    url: "https://sepehr.com/products/sp1",
    clickCount: 11,
    status: true,
    releaseDate: "05/03/1402 09:20",
    renewalDate: "05/03/1402 09:20",
    img: "https://picsum.photos/300?random=7",
  },
  {
    id: "dab7c81a-7ec3-4b1a-9e84-0284f3c65b7f",
    name: "شرکت کوثر",
    url: "https://kosar.com/products/ksr1",
    clickCount: 20,
    status: false,
    releaseDate: "14/11/1402 16:50",
    renewalDate: "14/11/1402 16:50",
    img: "https://picsum.photos/300?random=8",
  },
  {
    id: "f93e6a14-e3b2-49d4-b0b3-91b6d7822e32",
    name: "شرکت ایران‌زمین",
    url: "https://iranzamin.ir/products/iz1",
    clickCount: 17,
    status: true,
    releaseDate: "20/08/1401 14:10",
    renewalDate: "20/08/1401 14:10",
    img: "https://picsum.photos/300?random=9",
  },
  {
    id: "27a5e6a2-f49d-4145-b9d6-2874d4f3b3e9",
    name: "شرکت بهاران",
    url: "https://baharan.com/products/brn1",
    clickCount: 30,
    status: false,
    releaseDate: "12/07/1402 10:30",
    renewalDate: "12/07/1402 10:30",
    img: "https://picsum.photos/300?random=10",
  },
  {
    id: "e2b7c90d-df8a-4e29-973a-1a0d8a35d567",
    name: "شرکت دنیای‌نو",
    url: "https://donyayenew.ir/products/dn1",
    clickCount: 22,
    status: true,
    releaseDate: "25/02/1401 13:45",
    renewalDate: "25/02/1401 13:45",
    img: "https://picsum.photos/300?random=11",
  },
  {
    id: "cf0b9c0e-79d2-4381-9841-5e8d6e0b8af1",
    name: "شرکت سامان",
    url: "https://saman.com/products/sm1",
    clickCount: 16,
    status: true,
    releaseDate: "05/09/1402 11:20",
    renewalDate: "05/09/1402 11:20",
    img: "https://picsum.photos/300?random=12",
  },
  {
    id: "fc3b91e8-23f9-4b8d-91ed-2e1f9a87db9d",
    name: "شرکت مهرگان",
    url: "https://mehregan.com/products/mgn1",
    clickCount: 24,
    status: false,
    releaseDate: "08/04/1401 12:30",
    renewalDate: "08/04/1401 12:30",
    img: "https://picsum.photos/300?random=13",
  },
  {
    id: "4b5e8a12-df07-4707-91c9-90e7d2b9e7e3",
    name: "شرکت آبان",
    url: "https://aban.ir/products/abn1",
    clickCount: 13,
    status: true,
    releaseDate: "18/01/1402 15:10",
    renewalDate: "18/01/1402 15:10",
    img: "https://picsum.photos/300?random=14",
  },
];
