import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { LeftArrow, RightArrow } from "components/icons";
import styles from "./index.module.scss";
interface SliderProp {
  itemsWidth?: string;
  itemsHeight?: string;
  data: {
    id: string;
    title?: string;
    img?: string;
    subTitle?: string;
  }[];
  selectedItemId: (itemId?  : string) => void;
}

export const Carousel: React.FC<SliderProp> = ({
  data,
  itemsWidth = "80px",
  itemsHeight = "85px",
  selectedItemId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [activeItem, setActiveItem] = useState(0);
  const scrollToActiveItem = (index: number) => {
    if (itemRefs.current[index]) {
      itemRefs.current[index]?.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }
  };
  const handleNext = () => {
    setActiveItem((prev) => {
      const newIndex = prev + 1;
      scrollToActiveItem(newIndex);
      return newIndex;
    });
  };

  const handlePrev = () => {
    setActiveItem((prev) => {
      const newIndex = prev - 1;
      scrollToActiveItem(newIndex);
      return newIndex;
    });
  };
  useEffect(() => {
    selectedItemId(data.find((item, index) => index === activeItem)?.id);
  }, [activeItem]);
  return (
    <div className={styles.carousel_container}>
      <div className={styles.button_container}>
        <button
          onClick={handlePrev}
          className={classNames([styles.arrow, styles.right_arrow])}
          disabled={activeItem < 1}
        >
          <RightArrow />
        </button>
        <div className={styles.carousel_items_container}>
          <div className={styles.carousel_items} ref={containerRef}>
            {data.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    scrollToActiveItem(index);
                    setActiveItem(index);
                  }}
                  key={index}
                  className={styles.carousel_item}
                  ref={(el) => (itemRefs.current[index] = el)}
                  style={{ width: itemsWidth, height: itemsHeight }}
                >
                  <img src={item.img} alt={item.title} />
                  <span
                    className={classNames([
                      activeItem === index && styles.active_item,
                    ])}
                    style={{ width: itemsWidth }}
                  ></span>
                </div>
              );
            })}
          </div>
        </div>
        <button
          onClick={handleNext}
          className={classNames([
            styles.arrow,
            styles.left_arrow,
            activeItem >= 0 && styles.active_arrow,
          ])}
          disabled={activeItem > data.length - 2}
        >
          <LeftArrow />
        </button>
      </div>
      <div className={styles.title}>
        <h1>{data[activeItem].title}</h1>
        <label>{data[activeItem].subTitle}</label>
      </div>
    </div>
  );
};
