import { useEffect, useState } from "react";
import { Flex, Form, Input, Radio } from "antd";
import { AvatarUpload } from "ui";
import { rolesDescription } from "utils";
import { useGetRoles } from "pages/dashboard/role-management/api/useGetRoles";
import { user, userForm } from "../../type";
import { UploadChangeParam } from "antd/es/upload";
import { UploadFile } from "antd/lib";

interface UserFormProp {
  formData?: userForm;
  onFormChange: (value: userForm) => void;
}
export const UserForm: React.FC<UserFormProp> = ({
  formData,
  onFormChange,
}) => {
  const [avatar, setAvatar] = useState<{
    file: any;
    width: number;
    height: number;
  } | null>(null);

  const roleManagementQuery = useGetRoles({
    page: 1,
    pageSize: 999,
    inputParams: {
      filters: [],
      sort: {
        propertyName: "id",
        ascending: true,
      },
    },
  });
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("firstName", formData?.firstName);
    form.setFieldValue("lastName", formData?.lastName);
    form.setFieldValue("email", formData?.email);
    form.setFieldValue("phoneNumber", formData?.phoneNumber);
    form.setFieldValue("roleId", formData?.roleId);
    setAvatar(null);
  }, [formData, form]);

  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const file = info.fileList?.[0]?.originFileObj;
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          setAvatar({
            file: file,
            width: width,
            height: height,
          });
        };
        img.src = e.target?.result as string;
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Form
      className="form"
      form={form}
      onValuesChange={(e) => {
        onFormChange({ ...form.getFieldsValue(), avatar });
      }}
    >
      <Form.Item
        name="avatar-item"
        label="آواتار:"
        labelCol={{ span: 4 }}
        labelAlign="left"
        wrapperCol={{ span: 20 }}
      >
        <AvatarUpload
          url={formData?.avatarUrl}
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          onChange={handleUploadChange}
        />
      </Form.Item>
      <Form.Item
        label="نام"
        labelCol={{ span: 4 }}
        labelAlign="left"
        name="firstName"
        rules={[
          {
            required: true,
            message: "لطفا نام خود را وارد کنید",
          },
        ]}
      >
        <Input placeholder="نام" />
      </Form.Item>
      <Form.Item
        label="نام خانوادگی"
        labelCol={{ span: 4 }}
        labelAlign="left"
        name="lastName"
        rules={[
          {
            required: true,
            message: "لطفا نام خانوادگی خود را وارد کنید",
          },
        ]}
      >
        <Input placeholder="نام خانوادگی" />
      </Form.Item>
      <Form.Item
        name="email"
        label="ایمیل"
        labelCol={{ span: 4 }}
        labelAlign="left"
        wrapperCol={{ span: 20 }}
        rules={[{ required: true, message: "این فیلد اجباری است!" }]}
      >
        <Input placeholder="ایمیل" />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        label="شماره تماس"
        labelCol={{ span: 4 }}
        labelAlign="left"
        rules={[{ required: true, message: "این فیلد اجباری است!" }]}
      >
        <Input placeholder="شماره تماس" />
      </Form.Item>

      <Form.Item
        required
        name="roleId"
        label=" سمت شغلی:"
        labelCol={{ span: 4 }}
        labelAlign="left"
        rules={[{ required: true, message: "این فیلد اجباری است!" }]}
      >
        <Radio.Group name="roleId">
          {roleManagementQuery.data?.data.data.map((item) => {
            return (
              <Form.Item labelCol={{ span: 4 }} labelAlign="left">
                <Flex key={item.id} align="flex-start" gap={15}>
                  <Radio value={item.id} />
                  <Flex vertical gap={10}>
                    <h1>{item.title}</h1>
                    <label className="color-stroke">
                      {rolesDescription(item.name)}
                    </label>
                  </Flex>
                </Flex>
              </Form.Item>
            );
          })}
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};
