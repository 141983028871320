import { toast } from "react-toastify";
import { IconCopy } from "components/icons";
import styles from "./index.module.scss";
export const CopyButton = ({ value }: { value: string }) => {
  const copyLink = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.success("لینک کپی شد");
  };
  return (
    <div className={styles.copy_button} onClick={() => copyLink(value)}>
      <IconCopy />
      کپی کردن
    </div>
  );
};
