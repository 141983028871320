import { create } from "zustand";

type modals =
  | "LANDING_INDEX_EDIT_MODAL"
  | "LANDING_SLIDER_UPLOAD_MODAL"
  | "LANDING_BANNER_MODAL"
  | "LANDING_PRODUCT_LINE_EDIT_MODAL"
  | "LANDING_SEO_URL_MODAL"
  | "PRODUCT_SPEC_EDIT_MODAL"
  | "PRODUCT_SEO_URL_MODAL"
  | "PRODUCT_CATEGORY_BANNER_MODAL"
  | "MENU_EDIT_MODAL"
  | "SPECS_MANAGEMENT_MODAL"
  | "USER_MANAGEMENT_MODAL"
  | "VENDEE_ADD_MODAL"
  | "ADVERTISE_MANAGEMENT_MODAL"
  | "MAIN_BANNERS_MANAGEMENT_MODAL"
  | "MAIN_SLIDERS_MANAGEMNT_MODAL"
  | "BRAND_MANAGEMENT_MODAL"
  | null;

type AppStore = {
  modalvisibilty: modals;
  setModalVisibility: (modalvisibilty: modals) => void;
};

export const useAppStore = create<AppStore>((set) => {
  return {
    modalvisibilty: null,
    setModalVisibility: (value) => {
      set({ modalvisibilty: value });
    },
  };
});
