import { useMutation } from "@tanstack/react-query";
import { AUTH_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { toast } from "react-toastify";

type ForgetPasswordData = {
    otp: string,
    mobileNumber: string,
    password: string,
    confirmPassword: string
}

export const useForgotPassword = () => {
    const sendForgetOTP = useMutation({
        mutationFn: (data: string) => instance.post(AUTH_END_POINTS.forget_password_otp, {
            mobileNumber: data
        }),
        onSuccess: (res) => {
            if (res.data.StatusCode === 500) {
                toast.error(` ${res.data.Message}`);
            }
        },
        onError: (e: any) => {
            toast.error(`${e.response?.data?.Message || "خطای ناشناس"}`);
        }
    })

    const confirmForgotPassword = useMutation({
        mutationFn: (data: ForgetPasswordData) => instance.post(AUTH_END_POINTS.forget_password_confirm, {
            ...data
        }),
        onSuccess: (res) => {
            if (res.data.StatusCode === 500) {
                toast.error(` ${res.data.Message}`);
            }
        },
        onError: (e: any) => {
            toast.error(`${e.response?.data?.Message || "خطای ناشناس"}`);
        }
    })

    return {
        sendForgetOTP: sendForgetOTP.mutate,
        forgetOTPLoading: sendForgetOTP.isPending,
        confirmForgotPassword: confirmForgotPassword.mutate,
        confirmLoading: confirmForgotPassword.isPending
    }
}