import { Button, Form, Input } from "antd";
import { ErrorMessage, Field, Formik } from "formik";

export default function OtpCodeForm() {
  return (
    <Formik initialValues={{ email: "", password: "" }} onSubmit={() => {}}>
      {({ isSubmitting }) => (
        <Form className="login-form">
          <div className="login-form__email">
            <Field
              name="otp"
              type="text"
              placeholder="کد تایید را وارد نمایید"
              as={Input}
            />
            <ErrorMessage name="otp" component="div" />
          </div>

          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form__button"
            loading={isSubmitting}
          >
            بررسی کد
          </Button>
        </Form>
      )}
    </Formik>
  );
}
