import * as Yup from "yup";

// Validation schema for the login form
export const authChangePassSchema = Yup.object().shape({
  currentPassword: Yup.string().required("پسورد قبلی الزامی است"),
  newPassword: Yup.string()
    .min(6, "پسورد باید حداقل 6 کاراکتر باشد")
    .required("پسورد جدید الزامی است"),
  confirmNewPassword: Yup.string().when("newPassword", (password, field) =>
    password
      ? field
          .required("تایید پسورد الزامی است")
          .oneOf([Yup.ref("confirmNewPassword")], "تکرار پسورد همخوانی ندارد")
      : field
  ),
});
