import { HtmlHTMLAttributes, useMemo } from "react";
import styles from "./index.module.scss";
interface BrandAvatarProps extends HtmlHTMLAttributes<HTMLSpanElement> {
  img?: string | null;
  label?: string | number;
  size?: string;
}
export const BrandAvatar: React.FC<BrandAvatarProps> = ({
  img,
  size,
  className,
  label,
}) => {
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const backgroundColor = useMemo(() => getRandomColor(), []);
  const labelValue = typeof label === "string" ? label[0] : "+" + label;
  return (
    <div
      className={`${styles.brand_avatar_container} ${className}`}
      style={{ width: size, height: size, backgroundColor: backgroundColor }}
    >
      {img ? (
        <img src={img} alt={labelValue} width={"100%"} height={"100%"} />
      ) : (
        labelValue
      )}
    </div>
  );
};
