import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HOME_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { sliderForm } from "../type";

const addSlider = async ({ data }: { data: sliderForm }) => {
  const formData = new FormData();
  formData.append("Priority", String(data.priority ?? "0"));
  formData.append("AltText", "");
  formData.append("Title", data.title ?? "");
  formData.append("Link", data.link ?? "");
  formData.append("From", data.from ?? "");
  formData.append("To", data.to ?? "");
  formData.append("IsPublished", String(data.isPublished ?? "false"));
  formData.append("Description", data?.description ?? "");
  if (data.uploadImage) {
    formData.append("Width", String(data.uploadImage.width));
    formData.append("Height", String(data.uploadImage.height));
    formData.append("SizePercentage", "100");
    formData.append("File", data.uploadImage.file);
  }

  const res = await instance.post(HOME_END_POINTS.add_slider, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddSlider = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [HOME_END_POINTS.add_slider, HOME_END_POINTS.sliders_list],
    mutationFn: addSlider,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد اسلایدر"}`);
    },
    onSuccess: () => {
      toast.success(`اسلایدر با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [HOME_END_POINTS.sliders_list],
      });
    },
  });
};
