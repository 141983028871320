import { useMemo, useState } from "react";
import { Flex, Table } from "antd";
import { BrandAvatar, StatusLabel, Dropdown } from "ui";
import { useAppStore } from "store";
import { Key } from "interface";

import { toDateTime, toTime } from "core/utils/date-time";
import {
  useGetUser,
  useBlockUser,
  useDeleteUser,
  useUnBlockUser,
} from "../../api";
import { useUserManagementStore } from "../../store";
import { USER_MANAGEMENT_COLUMN } from "../../constant";

const UserManagementTable = () => {
  const setModalVisibility = useAppStore((state) => state.setModalVisibility);
  const {
    setFormData,
    setSelectedUsersId,
    selectedUsersId,
    setIsEdit,
    filter,
  } = useUserManagementStore((state) => state);
  const [page, setPage] = useState(1);
  const { isLoading, data, error } = useGetUser({
    page: page,
    pageSize: 10,
    inputParams: {
      filters: filter,
      sort: {
        propertyName: "id",
        ascending: false,
      },
    },
  });
  const { mutate: blockUser } = useBlockUser();
  const { mutate: unBlockUser } = useUnBlockUser();
  const { mutate: deleteUser } = useDeleteUser();
  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedUsersId(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedUsersId,
    onChange: onSelectChange,
  };
  const dataSource = useMemo(() => {
    return (
      (data?.data?.data ?? []).map((item) => {
        return {
          key: item.id,
          user: (
            <Flex gap={10}>
              <BrandAvatar
                img={process.env.REACT_APP_BASE_URL + item.image.url}
                label={item.firstName}
              />
              <Flex vertical align="start">
                <label className="color-primary">
                  {item.firstName} {item.lastName}
                </label>
                <label className="color-stroke">{item.email}</label>
              </Flex>
            </Flex>
          ),
          lastLoginDate: <label>ساعت {toTime(item.lastLoginDate)} </label>,
          lastLogoutDate: <label> ساعت {toTime(item.lastLogoutDate)} </label>,
          twoFactorLogin: (
            <Flex justify="center">
              <StatusLabel status={item.twoFactorLogin} />
            </Flex>
          ),
          registerDate: (
            <label className="color-stroke">
              {toDateTime(item.registerDate)}
            </label>
          ),
          action: (
            <Flex justify="center">
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      onClick: () => {
                        setIsEdit(true);
                        setFormData({
                          ...item,
                          avatarUrl:
                            process.env.REACT_APP_BASE_URL + item.image.url,
                        });
                        setModalVisibility("USER_MANAGEMENT_MODAL");
                      },
                      key: "EDIT",
                      label: "ویرایش کردن",
                    },
                    item.isBlocked
                      ? {
                          onClick: () => {
                            unBlockUser(item.id);
                          },
                          key: "UNBLOCK",
                          label: "آنبلاک کردن",
                        }
                      : {
                          onClick: () => {
                            blockUser(item.id);
                          },
                          key: "BLOCK",
                          label: "بلاک کردن",
                        },
                  ],
                }}
              >
                نوع عملیات
              </Dropdown>
            </Flex>
          ),
        };
      }) || []
    );
  }, [data]);
  return (
    <Table
      locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
      className="table"
      rowSelection={rowSelection}
      columns={USER_MANAGEMENT_COLUMN}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        showSizeChanger: false,
        total: data?.data?.totals ?? 0,
        onChange: setPage,
      }}
    />
  );
};
export default UserManagementTable;
