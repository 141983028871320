import { Divider, Flex, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Profile } from "pages/dashboard/profile";
import PopularProductList from "./PopularProductList";
import LatestContent from "./LatestContent";

const MENU_ITEMS = [
  {
    label: <Link to={"/dashboard/overview"}>نمای کلی</Link>,
    key: "/dashboard/overview",
  },
  {
    label: <Link to={"/dashboard/settings"}>تنظیمات</Link>,
    key: "/dashboard/settings",
  },
];

export const OverView = () => {
  const navigator = useNavigate();
  return (
    <Flex vertical gap={16}>
      <Profile />
      <Divider style={{ marginBottom: 0, marginTop: 0 }} />
      <Menu
        onClick={(e) => navigator(e.key)}
        defaultSelectedKeys={"/dashboard/overview"}
        mode="horizontal"
        items={MENU_ITEMS}
        style={{ fontWeight: "500" }}
      />
      <PopularProductList />
      <LatestContent />
    </Flex>
  );
};
