import { Avatar, Badge, Button, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./index.scss";
import { IconMessage, IconFire } from "../../../icons";
import { useReducer } from "react";

const { Text } = Typography;

const Header = ({ usersettings }) => {
  return (
    <div className="header">
      <div className="header__right">
        <div className="header__profile">
          <Avatar
            shape="square"
            src={usersettings?.image}
            size={50}
            icon={<UserOutlined />}
          />

          <div className="header__profile__user-info">
            <Text type="secondary">سلام</Text>
            <Text>
              {usersettings?.name} <span>{usersettings?.family}</span>{" "}
            </Text>
          </div>
        </div>

        <div className="header__links">
          <a href="#" className="header__links__button">
            <IconMessage color="#D6D7DD" size={20} />
            <Badge status="success" />
          </a>

          <a href="#" className="header__links__button">
            <IconFire color="#FF6600" size={20} />
            <Badge status="warning" />
          </a>
        </div>
      </div>

      <img
        className="header__logo"
        src="/assets/logo/logo-dashboard.svg"
        alt=""
      />
    </div>
  );
};

export default Header;
