import instance from "core/constants/request";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { RequestDTO, ResponseDTO } from "interface";
import { USER_END_POINTS } from "core/constants/endpoints";
import { user, userManagementFilter } from "../type";

type userRequestType = RequestDTO<userManagementFilter>;
const getUser = async (
  userRequestType: userRequestType
): Promise<ResponseDTO<user[]>> => {
  const res = await instance.post(USER_END_POINTS.list, userRequestType);
  return res.data;
};

type UseGetUserOptions = UseQueryOptions<
  ResponseDTO<user[]>,
  unknown,
  ResponseDTO<user[]>
>;
export const useGetUser = (
  params: userRequestType,
  config?: UseGetUserOptions
) => {
  return useQuery({
    queryKey: [
      USER_END_POINTS.list,
      params.page,
      params.pageSize,
      ...(params.inputParams.filters ? params.inputParams.filters : []),
    ],
    queryFn: () => getUser(params),
    ...config,
  });
};
