import { ComponentRef, forwardRef, useImperativeHandle, useMemo } from "react";
import { Input, Form } from "antd";
import { Field, FieldProps, useFormik, FormikProvider } from "formik";
import { Button, Select } from "ui";
import { PRIORITIES_DATA } from "core/constants/statics";

import { useMenuSettingStore } from "../../store";
import { useAddMenu } from "../../api/add-menu";
import { useListMenuParents } from "../../api/list-parents";
import { menuParentType, menuSettingForm } from "../../type";

interface RefHandler {
  getFormData: () => menuSettingForm;
  resetForm: () => void;
}
export type MenuFormRef = ComponentRef<typeof MenuForm>;

interface MenuForm {
  type: "edit" | "add";
}

export const MenuForm = forwardRef<RefHandler, MenuForm>(({ type }, ref) => {
  const { data: parents } = useListMenuParents();
  const { mutate: addMutate, isPending: adding } = useAddMenu();

  const labelColSpan = type === "add" ? 2 : 6;
  const [form] = Form.useForm<menuSettingForm>();

  const initialValues =
    type === "edit"
      ? useMenuSettingStore((state) => state.editedItem) || {
          parentId: undefined,
          name: undefined,
          priority: undefined,
          url: undefined,
        }
      : {
          parentId: undefined,
          name: undefined,
          priority: undefined,
          url: undefined,
        };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (type === "add") addMutate(values, { onSuccess: () => resetForm() });
    },
  });
  const { setFieldValue, values, submitForm } = formik;

  useImperativeHandle(ref, () => ({
    getFormData() {
      return values;
    },
    resetForm() {
      formik.resetForm();
    },
  }));

  const parentsOptions = useMemo(
    () =>
      (parents?.data ?? []).map((item: menuParentType) => ({
        label: item.name,
        value: item.id,
      })),
    [parents]
  );

  return (
    <FormikProvider value={formik}>
      <Form form={form}>
        <Form.Item
          name="parentId"
          label="والد"
          labelCol={{ span: labelColSpan }}
          labelAlign="left"
          wrapperCol={{ span: 22 }}
        >
          <Field name="parentId">
            {({ field }: FieldProps) => (
              <Select
                {...field}
                allowClear
                onChange={(value) => setFieldValue("parentId", value)}
                options={parentsOptions}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          name="name"
          label="عنوان"
          labelCol={{ span: labelColSpan }}
          labelAlign="left"
          wrapperCol={{ span: 22 }}
        >
          <Field name="name">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                value={values.name}
                placeholder="تایپ کنید"
                onChange={(e) => setFieldValue("name", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          name="url"
          label="URL یو آر ال "
          labelCol={{ span: labelColSpan }}
          labelAlign="left"
          wrapperCol={{ span: 22 }}
        >
          <Field name="url">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                value={values.url}
                placeholder="//https:"
                onChange={(e) => setFieldValue("url", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          name="priority"
          label="اولویت نمایش"
          labelCol={{ span: labelColSpan }}
          labelAlign="left"
          wrapperCol={{ span: 22 }}
        >
          <Field name="priority">
            {({ field }: FieldProps) => (
              <Select
                {...field}
                value={values.priority}
                placeholder="لطفا انتخاب کنید  "
                onChange={(value) => setFieldValue("priority", value)}
                options={PRIORITIES_DATA}
              />
            )}
          </Field>
        </Form.Item>
        {type === "add" && (
          <Button
            type="success"
            style={{ width: "100%" }}
            size="large"
            className="mt-2"
            onClick={() => submitForm()}
            loading={adding}
          >
            اضافه کردن به مگا منو
          </Button>
        )}
      </Form>
    </FormikProvider>
  );
});
