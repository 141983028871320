import { Button, Switch } from "antd";
import "./index.scss";
export default function LoginMethodPiece({
  methodName,
  children,
  methodAction,
  onAction,
}) {
  return (
    <div className="LoginMethodContainer">
      <div>
        <strong>{methodName}</strong>
        <span>{children}</span>
      </div>
      <Button onClick={() => onAction?.()}>{methodAction}</Button>
      {/* <Switch /> */}
    </div>
  );
}
