import { Card } from "antd";
import React from "react";
import AutnChangePassForm from "../../components/forms/AuthChangePassForm";
function ChangePassword() {
  return (
    <Card className="auth__card" title="تغییر پسورد">
      <AutnChangePassForm />
    </Card>
  );
}

export default ChangePassword;
