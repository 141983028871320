import { Flex } from "antd";
import { IconDrop } from "components/icons/iconDrop";
import { useSliderManagementStore } from "../../store";

import styles from "./index.module.scss";

interface headerProps {}
const Header: React.FC<headerProps> = () => {
  const { totals, isActiveTotal, totalSize } = useSliderManagementStore(
    (state) => state.headerInfo
  );

  return (
    <div className={styles.slider_header}>
      <div className={styles.image_container}>
        <img src="/assets/logo/logo-slider.svg" alt="img" />
      </div>
      <Flex align="center" gap="15px" className={styles.info_container}>
        <div>
          <IconDrop />
        </div>
        <Flex vertical gap="10px" className={styles.info}>
          <div>مدیریت اسلایدر های صفحه اصلی</div>
          <Flex className={styles.info_rows}>
            <div>حجم {`MB ${totalSize}`}</div>
            <div>{totals} اسلاید</div>
            <div>اسلاید های فعال {isActiveTotal}</div>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default Header;
