
import { AlignType } from "rc-table/lib/interface";

export const Extended_TABLE_COLUMN = [
  {
    title: "نام شرکت/ لینک تبلیغ",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => a.nameValue.localeCompare(b.name),
  },
  {
    title: "تعداد کلیک",
    dataIndex: "clickCount",
    key: "clickCount",
    align: "center" as AlignType,
    sorter: (a: any, b: any) => a.clickCount - b.clickCount,
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
  },
  {
    title: "تاریخ انتشار",
    dataIndex: "releaseDate",
    key: "releaseDate",
    align: "center" as AlignType,
    sorter: (a: any, b: any) => a.releaseDateValue.localeCompare(b.name),
  },
  {
    title: "تاریخ تمدید تبلیغ",
    dataIndex: "renewalDate",
    key: "renewalDate",
    align: "center" as AlignType,
    sorter: (a: any, b: any) => a.renewalDateValue.localeCompare(b.name),
  },
  {
    title: "جزئیات",
    dataIndex: "details",
    key: "details",
    align: "center" as AlignType,
  },
];
