import { Modal, Button } from "ui";
import { VendeeForm } from "../form";

export const VendeeAddModal = () => {
  return (
    <Modal
      name="VENDEE_ADD_MODAL"
      title={"افزودن خریدار جدید"}
      okButtonProps={{
        loading: false,
      }}
      footer={(onCancel, onOk) => (
        <>
          <Button
            size="large"
            onClick={() => {
              onCancel();
            }}
          >
            انصراف
          </Button>
          <Button type="primary" size="large">
            ذخیره
          </Button>
        </>
      )}
    >
      <VendeeForm />
    </Modal>
  );
};
