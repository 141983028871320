import { Button, Flex } from "antd";
import { useAppStore } from "store";
import { Icon } from "ui";

import styles from "../../index.module.scss";

export const AddAdvertiseCard = () => {
  const { setModalVisibility } = useAppStore((state) => state);
  return (
    <Flex
      vertical
      gap={50}
      align="center"
      className={styles.add_advertise_card_container}
    >
      <Flex vertical align="center" gap={10}>
        <h1>تبلیغ سریع و بی معطلی</h1>
        <h2>به راحتی تبلیغ رو بگذار و بازخوردش رو ببین</h2>
      </Flex>
      <img
        src="/assets/images/advertise-management.svg"
        alt="تبلیغ سریع و بی معطلی"
      />

      <Flex gap={10}>
        <Button className={styles.ads_guid_btn}>راهنمای تبلیغ</Button>
        <Button
          className={styles.new_advertise_btn}
          onClick={() => setModalVisibility("ADVERTISE_MANAGEMENT_MODAL")}
        >
          <Icon type="sr-plus" />
          تبلیغ جدید
        </Button>
      </Flex>
    </Flex>
  );
};
