import { ActionBar } from "./action-bar";
import { OrdersManagementTable } from "./table";
import { DUMMY_DATE } from "../api/dummy-data";

export const OrdersManagement = () => {
  return (
    <div className="box">
      <ActionBar />
      <OrdersManagementTable data={DUMMY_DATE} />
    </div>
  );
};
