import { AlignType } from "rc-table/lib/interface";

export const SPECS_LIST_COLUMN = [
  {
    title: "ردیف",
    dataIndex: "index",
    key: "index",
    align: "center" as AlignType,
    width: "3%",
  },
  {
    title: "نام ویژگی",
    dataIndex: "name",
    key: "name",
    width: "40%",
  },
  {
    title: "توضیحات",
    dataIndex: "description",
    key: "description",
    width: "40%",
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
    width: "40%",
  },
];
