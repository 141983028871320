import { Col, Flex, Form, Input, Row, Select } from "antd";
import { Field, FieldProps, Formik } from "formik";
 

export const DeliveryDetails = () => {
  const [form] = Form.useForm();
  const initialValues = {
    address: undefined,
    city: undefined,
    postalId: undefined,
    phoneNumber: undefined,
  };
  return (
    <Flex vertical gap={15} className="box">
      <h1>جزئیات ارسال</h1>

      <h1> آدرس ارسال بار </h1>
      <Formik<any>
        onSubmit={(values) => {}}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form form={form}>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  label="آدرس"
                  required
                >
                  <Field name="address">
                    {({ field }: FieldProps) => (
                      <Input
                        size="large"
                        {...field}
                        placeholder="آدرس را وارد نمایید"
                      />
                    )}
                  </Field>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item labelCol={{ span: 24 }} label="کد پستی" required>
                  <Field name="address">
                    {({ field }: FieldProps) => (
                      <Select
                        size="large"
                        {...field}
                        placeholder="انتخاب کنید"
                      />
                    )}
                  </Field>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item labelCol={{ span: 24 }} label="کد پستی" required>
                  <Field name="address">
                    {({ field }: FieldProps) => (
                      <Input
                        size="large"
                        {...field}
                        placeholder="آدرس را وارد نمایید"
                      />
                    )}
                  </Field>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item labelCol={{ span: 24 }} label="تلفن" required>
                  <Field name="address">
                    {({ field }: FieldProps) => (
                      <Input
                        size="large"
                        {...field}
                        placeholder="02165231446"
                      />
                    )}
                  </Field>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
