import { Button, Modal } from "ui";
import { AdvertiseForm } from "../form";

export const AdvertiseModal = () => {
  return (
    <Modal
      name="ADVERTISE_MANAGEMENT_MODAL"
      title="افزودن تبلیغ جدید"
      footer={(onCancel, onOk) => (
        <>
          <Button onClick={onCancel}>لغو</Button>
          <Button
            loading={false}
            type="primary"
            onClick={() => {
              onOk();
            }}
          >
            ثبت
          </Button>
        </>
      )}
    >
      <AdvertiseForm />
    </Modal>
  );
};
