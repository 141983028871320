import {
  QueryKey,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { PRODUCT_CATEGORIES_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ResponseDTO } from "interface";
import { ProductCategory } from "pages/catalog/AddProductCategory/type";

type QueryType = { page: number; search: string };
type OptionsType = UseQueryOptions<
  ResponseDTO<ProductCategory[]>,
  QueryType,
  ResponseDTO<ProductCategory[]>
>;

const listFn = async (
  query: QueryKey
): Promise<ResponseDTO<ProductCategory[]>> => {
  const [{ page, search } = { page: 1, search: "" }] = query as QueryType[];

  const filters =
    (search ?? "").length > 0
      ? [
          {
            propertyName: "name",
            operation: "contains",
            value: search,
          },
        ]
      : [];

  const res = await instance.post(PRODUCT_CATEGORIES_ENDPOINTS.list, {
    ...{
      request: {
        page: page,
        inputParams: {
          filters: filters,
          sort: {
            propertyName: "id",
            ascending: false,
          },
        },
      },
    },
  });

  return res.data;
};

export const useGetProductCategories = (options?: OptionsType) =>
  useQuery({
    queryKey: [PRODUCT_CATEGORIES_ENDPOINTS.list],
    queryFn: ({ queryKey }) => listFn(queryKey),
    ...options,
  });
