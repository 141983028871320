import { Col, Flex } from "antd";
import { Icon } from "ui";
import { icon } from "ui/icons/types";

interface addressProp {
  address: string;
  icon: icon;
  title: string;
}
export const Address: React.FC<addressProp> = ({ address, icon, title }) => {
  return (
    <div className="box h-100">
      <h1>{title}</h1>
      <Flex gap={25} justify="space-between">
        <Col span={14}>
          <br />
          <p>{address}</p>
        </Col>
        <Col>
          <Icon type={icon} size={115} className="color-watermark" />
        </Col>
      </Flex>
    </div>
  );
};
