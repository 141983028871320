export const IconCopy = ({ size = 24, color = "#f60" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.793"
    height="17"
    viewBox="0 0 13.793 17"
  >
    <g id="copy" transform="translate(0.026 -0.01)">
      <path
        id="Path_1402"
        data-name="Path 1402"
        d="M118.064,143.975v5.285a1.56,1.56,0,0,1-1.678,1.671h-8.248a1.562,1.562,0,0,1-1.679-1.67V138.635a1.543,1.543,0,0,1,1.245-1.58,2.055,2.055,0,0,1,.4-.029h8.324a1.544,1.544,0,0,1,1.643,1.644Q118.067,141.324,118.064,143.975Zm-1.55.012v-4.563a.782.782,0,0,0-.868-.86h-6.765a.785.785,0,0,0-.874.872v9.071a.784.784,0,0,0,.886.876h6.728a.788.788,0,0,0,.893-.888Z"
        transform="translate(-104.307 -133.92)"
        fill={color}
      />
      <path
        id="Path_1403"
        data-name="Path 1403"
        d="M83.3,120.43v-4.9a1.531,1.531,0,0,1,1.189-1.568,1.952,1.952,0,0,1,.431-.048h8.36a.776.776,0,1,1,.044,1.548h-7.6a.792.792,0,0,0-.885.882v9.015a1.451,1.451,0,0,1-.025.341.771.771,0,0,1-.8.576.758.758,0,0,1-.712-.735c-.011-.628,0-1.255,0-1.882Z"
        transform="translate(-83.32 -113.9)"
        fill={color}
      />
    </g>
  </svg>
);
