import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { PRODUCT_CATEGORIES_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { toast } from "react-toastify";

export const useAddProductCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return instance.post(PRODUCT_CATEGORIES_ENDPOINTS.add, {
        ...{
          ...data,
          url: "",
          priority: 0,
          isActive: true,
          seoUrl: "",
          imageUrl: "",
          imageWidth: 1,
          imageHeight: 1,
          imageSizePercentage: 1,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        PRODUCT_CATEGORIES_ENDPOINTS.list,
      ] as InvalidateQueryFilters);
    },
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(
        `${e.response?.data?.Message || "خطا در ایجاد دسته بندی محصول"}`
      );
    },
  });
};
