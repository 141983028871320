import { useCallback, useState } from "react";
import { Select, Table, ConfigProvider } from "antd";
import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali";
import fa_IR from "antd/lib/locale/fa_IR";
import clsx from "clsx";
import { useGetRoles } from "../../api/useGetRoles";
import { useDeleteRole } from "../../api/useDeleteRole";
import RoleManagementAddRoleModal from "../RoleManagementAddRoleModal";
import RoleManagementShowListModal from "../RoleManagementShowListModal";

import styles from "./RoleManagementTable.module.scss";
const RoleManagementTable = () => {
  const [page, setPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editRole, setRoleForEdit] = useState(null);

  const { data, isPending } = useGetRoles({
    page,
    pageSize: 20,
    inputParams: {
      filters: [],
      sort: {
        propertyName: "id",
        ascending: false,
      },
    },
  });
  const { mutate: deleteRole } = useDeleteRole();

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onActionTap = (val, role) => {
    switch (val) {
      case "Delete":
        deleteRole(role.id);
        break;
      case "Edit":
        setRoleForEdit(role);
        break;
    }
  };

  const getColumns = useCallback(
    () => [
      {
        align: "right",
        width: "20%",
        title: <div className="column">نوع نقش</div>,
        sorter: (a, b) => a.title.localeCompare(b.title),

        render: (role) => {
          return <div className={styles["primary"]}>{role?.title ?? "-"}</div>;
        },
      },
      {
        title: <div className="column">سطح نقش</div>,
        sorter: (a, b) => a.priority > b.priority,
        render: (role) => <div className="column">{role?.priority ?? "-"}</div>,
      },
      {
        title: <div className="column">لیست دسترسی</div>,
        dataIndex: "permissions",
        render: (permissions) => <RoleManagementShowListModal />,
      },
      {
        title: <div className="column">وضعیت</div>,
        render: (role) => (
          <div
            className={clsx(styles["status-tag"], {
              [styles["disabled"]]: !role?.isSystemicRole,
            })}
          >
            {role?.isSystemicRole ? "فعال" : "غیرفعال"}
          </div>
        ),
        sorter: (a, b) =>
          a.isSystemicRole
            .toString()
            .localeCompare(b.isSystemicRole.toString()),
      },
      {
        title: <div className="column">تاریخ ثبت نقش</div>,
        render: (role) => (
          <div className="column">{role?.createdAtPersian ?? "-"}</div>
        ),
      },
      {
        title: <div className="column">عملیات</div>,
        render: (role) => {
          return (
            <Select
              placeholder="نوع عملیات"
              style={{ width: 120 }}
              className={styles["primary"]}
              onChange={(selectedValue) => onActionTap(selectedValue, role)}
              value={null}
              options={[
                {
                  value: "Delete",
                  label: "حذف نقش",
                },
                {
                  value: "Edit",
                  label: "ویرایش کردن",
                },
              ]}
            />
          );
        },
      },
    ],
    [data]
  );

  return (
    <div
      style={{
        marginBottom: 16,
      }}
      className={styles["table-container"]}
    >
      <div className={styles["table_filter"]}>
        <div className={styles["filters-container"]}>
          <div>
            <ConfigProvider locale={fa_IR} direction="rtl">
              <JalaliLocaleListener />
              <DatePickerJalali format="dddd D MMMM" />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                  color: "#bfbfbf",
                }}
              >
                از
              </span>
            </ConfigProvider>
          </div>

          <div>
            <ConfigProvider locale={fa_IR} direction="rtl">
              <DatePickerJalali format="dddd D MMMM" />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                  color: "#bfbfbf",
                }}
              >
                تا
              </span>
            </ConfigProvider>
          </div>
          <div>
            <RoleManagementAddRoleModal
              editRole={editRole}
              onClose={() => {
                // reset editRole to null, when modal is closed
                if (editRole != null) setRoleForEdit(null);
              }}
            />
          </div>
        </div>
      </div>
      <Table
        locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
        className={styles["table"]}
        rowSelection={rowSelection}
        columns={getColumns()}
        dataSource={data?.data?.data ?? []}
        loading={isPending}
        rowKey={"id"}
        pagination={{
          showSizeChanger: false,
          total: data?.data.totals,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </div>
  );
};
export default RoleManagementTable;
