export const IconDocument = ({ size = 24, color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox="0 0 13.598 14.958"
  >
    <g id="sr-document-v3" transform="translate(-2 -1)">
      <path
        id="Path_211"
        name="Path 211"
        d="M7.68,5A.68.68,0,0,0,7,5.68V8.4a.68.68,0,0,0,.68.68h5.439a.68.68,0,0,0,.68-.68V5.68a.68.68,0,0,0-.68-.68Zm.68,2.72V6.36h4.079V7.72Z"
        transform="translate(-1.6 -1.28)"
        fill={color}
        fillRule="evenodd"
      />
      <path
        id="Path_212"
        name="Path 212"
        d="M7,13.68A.68.68,0,0,1,7.68,13h5.439a.68.68,0,1,1,0,1.36H7.68A.68.68,0,0,1,7,13.68Z"
        transform="translate(-1.6 -3.841)"
        fill={color}
      />
      <path
        id="Path_213"
        name="Path 213"
        d="M7.68,17a.68.68,0,0,0,0,1.36H10.4a.68.68,0,0,0,0-1.36Z"
        transform="translate(-1.6 -5.122)"
        fill={color}
      />
      <path
        id="Path_214"
        name="Path 214"
        d="M4.04,1A2.04,2.04,0,0,0,2,3.04V13.918a2.04,2.04,0,0,0,2.04,2.04h9.519a2.04,2.04,0,0,0,2.04-2.04V3.04A2.04,2.04,0,0,0,13.558,1ZM3.36,3.04a.68.68,0,0,1,.68-.68h9.519a.68.68,0,0,1,.68.68V13.918a.68.68,0,0,1-.68.68H4.04a.68.68,0,0,1-.68-.68Z"
        fill={color}
        fillRule="evenodd"
      />
    </g>
  </svg>
);
