import { AlignType } from "rc-table/lib/interface";
export const ORDERS_DETAILS_COLUMNS = [
  {
    title: "محصول",
    dataIndex: "product",
    key: "product",
    width: 491,
  },

  {
    title: "شناسه",
    dataIndex: "id",
    key: "id",
    align: "center" as AlignType,
  },
  {
    title: "مقدار",
    dataIndex: "amount",
    key: "amount",
    align: "center" as AlignType,
  },
  {
    title: "قیمت واحد (تومان)",
    dataIndex: "price",
    key: "price",
    align: "center" as AlignType,
  },
  {
    title: "جمع کل (تومان)",
    dataIndex: "totalPrice",
    key: "totalPrice",
    align: "center" as AlignType,
  },
];
