import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { SEO_MANAGEMENT_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ResponseDTO } from "interface";
import { MenuManagementType } from "../type";

type QueryType = { page: number };
type UseMenuManagement = UseQueryOptions<
  ResponseDTO<MenuManagementType[]>,
  QueryType,
  ResponseDTO<MenuManagementType[]>
>;

const listFn = async (
  query: QueryKey
): Promise<ResponseDTO<MenuManagementType[]>> => {
  const [{ page } = { page: 1 }] = query as QueryType[];

  const res = await instance.post(SEO_MANAGEMENT_ENDPOINTS.list, {
    ...{
      page,
      inputParams: { sort: { propertyName: "id", ascending: false } },
    },
  });

  return res.data;
};

export const useListMenus = (options?: UseMenuManagement) =>
  useQuery({
    queryKey: [SEO_MANAGEMENT_ENDPOINTS.list],
    queryFn: ({ queryKey }) => listFn(queryKey),
    ...options,
  });
