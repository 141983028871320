import { Form, Flex, Select } from "antd";
import { Field, FieldProps, Formik } from "formik";
import { DatePickerFormik, SearchInput } from "ui";

import styles from "../index.module.scss";

export const ActionBar = () => {
  const [form] = Form.useForm<any>();
  const initialValues = {
    name: undefined,
    adsPage: undefined,
    url: undefined,
    status: true,
  };
  return (
    <Flex gap={10} justify="space-between">
      <Formik<any>
        onSubmit={(values) => {}}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form form={form}>
            <Flex gap={10} className="w-100">
              <Form.Item wrapperCol={{ span: 30 }}>
                <Field name="search">
                  {({ field }: FieldProps) => (
                    <SearchInput
                      size="large"
                      placeholder="جستجوی برند"
                      {...field}
                    />
                  )}
                </Field>
              </Form.Item>
              <Form.Item>
                <Field name="startDate">
                  {({ field }: FieldProps) => (
                    <DatePickerFormik size="lg" label="از" {...field} />
                  )}
                </Field>
              </Form.Item>
              <Form.Item>
                <Field name="endtDate">
                  {({ field }: FieldProps) => (
                    <DatePickerFormik size="lg" label="تا" {...field} />
                  )}
                </Field>
              </Form.Item>
              <Form.Item>
                <Field name="startDate">
                  {({ field }: FieldProps) => (
                    <Select
                      className={styles.select_container}
                      size="large"
                      placeholder={"وضعیت"}
                      options={[
                        {
                          value: "done",
                          label: "پرداخت شده",
                        },
                        {
                          value: "authenticated",
                          label: "تایید شده",
                        },
                        {
                          value: "authenticated",
                          label: "در حال بررسی",
                        },
                      ]}
                      {...field}
                    />
                  )}
                </Field>
              </Form.Item>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};
