import { Divider, Flex } from "antd";
import React from "react";
import { StatusLabel } from "ui";

import styles from "../index.module.scss";

interface detailsCardProp {
  name: string;
  email: string;
  id: string;
  status: boolean;
  address: string;
  lastTransaction: string;
}
export const DetailsCard: React.FC<detailsCardProp> = ({
  name,
  email,
  id,
  status,
  address,
  lastTransaction,
}) => {
  return (
    <div className="box">
      <div className={styles.details_card_name_container}>
        <h1>{name}</h1>
        <h1>
          <strong className="color-stroke fs-small"> {id}</strong>
        </h1>
      </div>
      <Flex justify="space-between" align="center">
        <h1>جزئیات</h1>
        <StatusLabel status={status} />
      </Flex>
      <Divider />
      <Flex vertical gap={10}>
        <Flex vertical>
          <label>آی دی کاربر</label>
          <label className="color-stroke">{id}</label>
        </Flex>
        <Flex vertical>
          <label>ایمیل </label>
          <label className="color-stroke">{email}</label>
        </Flex>
        <Flex vertical>
          <label>آدرس تحویل بار</label>
          <label className="color-stroke">{address}</label>
        </Flex>
        <Flex vertical>
          <label> آخرین تراکنش انجام شده </label>
          <label className="color-stroke">{lastTransaction}</label>
        </Flex>
      </Flex>
    </div>
  );
};
