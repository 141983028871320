import { useRef } from "react";
import { Button } from "antd";
import { Modal } from "ui";
import { useSpecsListStore } from "../../store";
import {
  useAddTechnicalSpecification,
  useUpdateTechnicalSpecification,
} from "../../api";
import SpecForm, { SpecFormRef } from "../form";

const SpecModal = () => {
  const { setFormData, formData, isEdit } = useSpecsListStore((state) => state);
  const updateSpecQuery = useUpdateTechnicalSpecification();
  const addSpecQuery = useAddTechnicalSpecification();
  const loading = isEdit ? updateSpecQuery.isPending : addSpecQuery.isPending;

  const formRef = useRef<SpecFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    const values = formRef.current?.getFormData();
    setFormData(values);
    if (isEdit && values) {
      updateSpecQuery.mutate(
        {
          data: values,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
        }
      );
    } else if (values) {
      addSpecQuery.mutate(
        {
          data: values,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
        }
      );
    }
  };

  return (
    <Modal
      name="SPECS_MANAGEMENT_MODAL"
      title={isEdit ? "ویرایش ویژگی" : "افزودن ویژگی جدید"}
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <SpecForm ref={formRef} formData={formData} />
    </Modal>
  );
};
export default SpecModal;
