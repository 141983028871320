import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ErrorDTO } from "interface";
import { SETTINGS_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { AboutUsType } from "../type";

const updateData = async (data: AboutUsType) => {
  const formData = new FormData();
  formData.append("Text", data?.text ?? "");
  formData.append("Title", data?.title ?? "");
  formData.append("AltText", "");
  if (data.uploadImage) {
    formData.append("Width", String(data.uploadImage.width));
    formData.append("Height", String(data.uploadImage.height));
    formData.append("SizePercentage", "100");
    formData.append("File", data.uploadImage.file);
  }
  formData.append("DeleteFile", String(false));

  const res = await instance.put(SETTINGS_END_POINTS.update_aboutus, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useUpdateAboutUs = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [
      SETTINGS_END_POINTS.update_privacy,
      SETTINGS_END_POINTS.get_aboutus,
    ],
    mutationFn: updateData,
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data.Message || "خطا در اعمال تغییرات"}`);
    },
    onSuccess: () => {
      toast.success(`تغییرات با موفقیت اعمال گردید`);
      queryClient.invalidateQueries({
        queryKey: [SETTINGS_END_POINTS.get_aboutus],
      });
    },
  });
};
