import { useState } from "react";
import { Divider, Flex } from "antd";
import { Button, LexicalEditor } from "ui";
import { useGetPrivacy, useUpdatePrivacy } from "../api";

export const PrivacyManagement = () => {
  const [text, setText] = useState<string>("");

  const { data } = useGetPrivacy();
  const { mutate, isPending: updating } = useUpdatePrivacy();

  return (
    <div className="box">
      <h1>حریم خصوصی</h1>
      <Divider />
      <LexicalEditor
        onChange={(html: string) => setText(html)}
        value={data?.data ?? ""}
      />
      <Divider />
      <Flex justify="flex-end" gap={10}>
        <Button size="large">لغو کردن</Button>
        <Button
          type="primary"
          size="large"
          loading={updating}
          onClick={() => mutate({ text })}
        >
          ذخیره تغییرات
        </Button>
      </Flex>
    </div>
  );
};
