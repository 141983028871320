import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { USER_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { toast } from "react-toastify";

export const useUnBlockUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      instance.post(`${USER_END_POINTS.unblock}/${id}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries([
        USER_END_POINTS.list,
      ] as InvalidateQueryFilters);
      toast.success(`کاربر با موفقیت آنبلاک گردید`);
    },
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data?.Message || "خطا در آنبلاک کردن کاربر"}`);
    },
  });
};
