export const IconDelete = ({ size = 24, color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.909"
    height="17"
    viewBox="0 0 13.909 17"
  >
    <g id="sr-trashcan-v2" transform="translate(-3 -1)">
      <path
        id="Path_560"
        data-name="Path 560"
        d="M8.409,1a.773.773,0,0,0-.773.773V3.318H3.773a.773.773,0,1,0,0,1.545H16.136a.773.773,0,0,0,0-1.545H12.273V1.773A.773.773,0,0,0,11.5,1Zm2.318,2.318H9.182V2.545h1.545Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        id="Path_561"
        data-name="Path 561"
        d="M9.773,11a.773.773,0,0,1,.773.773v4.636a.773.773,0,1,1-1.545,0V11.773A.773.773,0,0,1,9.773,11Z"
        transform="translate(-1.364 -2.273)"
        fill={color}
      />
      <path
        id="Path_562"
        data-name="Path 562"
        d="M14.545,11.773a.773.773,0,1,0-1.545,0v4.636a.773.773,0,1,0,1.545,0Z"
        transform="translate(-2.273 -2.273)"
        fill={color}
      />
      <path
        id="Path_563"
        data-name="Path 563"
        d="M4.773,7A.773.773,0,0,0,4,7.773v9.273a2.318,2.318,0,0,0,2.318,2.318h7.727a2.318,2.318,0,0,0,2.318-2.318V7.773A.773.773,0,0,0,15.591,7Zm.773,10.045v-8.5h9.273v8.5a.773.773,0,0,1-.773.773H6.318A.773.773,0,0,1,5.545,17.045Z"
        transform="translate(-0.227 -1.364)"
        fill={color}
        fillRule="evenodd"
      />
    </g>
  </svg>
);
