import { DUMMY_DATA } from "../api/dummy-data";
import { ActionBar } from "./action-bar";
import { CustomersCartsTable } from "./table";

export const CustomersCarts = () => {
  return (
    <div className="box">
      <ActionBar />
      <CustomersCartsTable data={DUMMY_DATA} />
    </div>
  );
};
