import React from "react";
import classNames from "classnames";
import { ConfigProvider } from "antd";
import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali";
import fa_IR from "antd/lib/locale/fa_IR";
import styles from "./index.module.scss";

import "./index.scss";

interface DatePickerProps {
  name: string;
  format?: string;
  label?: string;
  direction?: "rtl" | "ltr";
  className?: string;
  size?: "sm" | "lg" | "md";
  placeholder?: string;
  value?: string;
  onChange?: (date: string) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  name,
  format = "dddd D MMMM",
  direction = "rtl",
  label = "تاریخ",
  className,
  size = "md",
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div
      className={classNames([
        styles.datePicker_container,
        "datePicker-container",
        size === "lg" && styles.large,
        className,
      ])}
      key={name ?? "-"}
    >
      <span className="color-stroke">{label}</span>
      <ConfigProvider locale={fa_IR} direction={direction} key={name ?? "-"}>
        <JalaliLocaleListener />
        <DatePickerJalali
          format={format}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </ConfigProvider>
    </div>
  );
};
