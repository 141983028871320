import { create } from "zustand";
import { filter, Key } from "interface";
import { brandForm, brandManagementFilter } from "../type";
type BrandManagementStore = {
  isEdit: boolean;
  formData?: brandForm;
  selectedBrandsId?: Key[];
  filter?: filter<brandManagementFilter>[];
  setIsEdit: (val: boolean) => void;
  setFormData: (formData?: brandForm) => void;
  setFilter: (filter: filter<brandManagementFilter>[]) => void;
  setSelectedBrandsId: (value: Key[]) => void;
};

export const useBrandManagementStore = create<BrandManagementStore>((set) => {
  return {
    isEdit: false,
    setFormData: (value: brandForm | undefined) => {
      set({ formData: value });
    },
    setFilter: (value: filter<brandManagementFilter>[]) => {
      set({ filter: value });
    },
    setSelectedBrandsId: (value: Key[]) => {
      set({ selectedBrandsId: value });
    },
    setIsEdit: (value: boolean) => {
      set({ isEdit: value });
    },
  };
});
