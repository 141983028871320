import { useRef } from "react";
import { Button } from "antd";
import { Modal } from "ui";
import { useBrandManagementStore } from "../../store";
import { useAddBrand } from "../../api";
import { useUpdateBrand } from "../../api";
import BrandForm, { BrandFormRef } from "../form";
import { toast } from "react-toastify";

const BrandManagementModal = () => {
  const { setFormData, formData, isEdit } = useBrandManagementStore(
    (state) => state
  );
  const updateBrandQuery = useUpdateBrand();
  const addBrandQuery = useAddBrand();
  const loading = isEdit ? updateBrandQuery.isPending : addBrandQuery.isPending;

  const formRef = useRef<BrandFormRef>(null);

  const handleOk = (onSuccess: () => void) => {
    const values = formRef.current?.getFormData();
    setFormData(values);
    if (isEdit && values) {
      updateBrandQuery.mutate(
        {
          data: values,
        },
        {
          onSuccess: () => {
            onSuccess();
            formRef.current?.resetForm();
          },
        }
      );
    } else if (values) {
      // when adding a new brand, image is not optional
      if (!values.image) {
        toast.error("لطفا تصویر برند را مشخص کنید");
        return;
      }

      addBrandQuery.mutate(
        {
          data: values,
        },
        {
          onSuccess: () => {
            onSuccess();
            formRef.current?.resetForm();
          },
        }
      );
    }
  };

  return (
    <Modal
      name="BRAND_MANAGEMENT_MODAL"
      title={isEdit ? "ویرایش برند" : "افزودن برند جدید"}
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
        formRef.current?.resetForm();
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <BrandForm ref={formRef} formData={formData} />
    </Modal>
  );
};
export default BrandManagementModal;
