import axios from "axios";
import { getAccessToken } from "core/utils/auth-utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();

    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Credentials"] = "*";

    if (token) {
      if (!config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/json";
      }
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      // intercept if needed
    }
    return res;
  },
  async (err) => {
    try {
      if (err.code === "ERR_NETWORK" || err.code === "ECONNABORTED") {
        return Promise.reject(err);
      }

      if (err.response?.status === 404) {
        return Promise.reject(err);
      }

      // If none of the conditions match, just return the error
      return Promise.reject(err);
    } catch (_error) {
      // Handle other errors or return Promise.reject(_error);
      return Promise.reject(_error);
    }
  }
);

export default instance;
