import { ComponentRef, forwardRef, useImperativeHandle, useState } from "react";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { UploadChangeParam } from "antd/es/upload";
import { Form, Input, Switch } from "antd";
import { UploadFile } from "antd/lib";
import { AvatarUpload } from "ui";
import { getRand } from "core/utils/common-utils";
import { brandForm } from "../../type";
import { getImageSrc } from "core/utils/json-utils";

interface BrandFormProp {
  formData?: brandForm;
}
interface RefHandler {
  getFormData: () => brandForm;
  resetForm: () => void;
}
export type BrandFormRef = ComponentRef<typeof BrandForm>;

const BrandForm = forwardRef<RefHandler, BrandFormProp>(({ formData }, ref) => {
  const [refreshKey, setRefreshKey] = useState("");

  const [form] = Form.useForm();

  const formik = useFormik<Partial<brandForm>>({
    initialValues: {
      id: formData?.id,
      name: formData?.name,
      localLink: formData?.localLink,
      identifier: formData?.identifier,
      image: formData?.image,
      isActive: formData?.isActive,
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });
  const { setFieldValue, values, resetForm } = formik;

  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const file = info.fileList?.[0]?.originFileObj;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          setFieldValue("image", {
            file: file,
            width: width,
            height: height,
          });
        };
        img.src = e.target?.result as string;
      };

      reader.readAsDataURL(file);
    }
  };

  useImperativeHandle(ref, () => ({
    getFormData() {
      return values as brandForm;
    },
    resetForm() {
      resetForm();
      setRefreshKey(`${getRand()}`);
      form.resetFields();
    },
  }));

  return (
    <FormikProvider value={formik}>
      <Form form={form}>
        <Form.Item
          name="avatar"
          valuePropName="logo"
          label="لوگوی برند:"
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
        >
          <AvatarUpload
            key={`avatar-${refreshKey}`}
            url={
              formData?.thumbnail?.url
                ? getImageSrc(formData?.thumbnail?.url, true)
                : undefined
            }
            name="image"
            listType="picture-card"
            className="avatar-uploader"
            onChange={handleUploadChange}
            multiple={false}
          />
        </Form.Item>

        <Form.Item
          label="نام برند"
          labelCol={{ span: 4 }}
          labelAlign="left"
          name="name"
          rules={[
            {
              required: true,
              message: "لطفا نام برند را وارد کنید",
            },
          ]}
        >
          <Field name="name">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="نام برند"
                value={values.name}
                onChange={(e) => setFieldValue("name", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="لینک برند"
          labelCol={{ span: 4 }}
          labelAlign="left"
          name="localLink"
          rules={[
            {
              required: true,
              message: "لطفا لینک برند را وارد کنید",
            },
          ]}
        >
          <Field name="localLink">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="لینک برند"
                value={values.localLink}
                onChange={(e) => setFieldValue("localLink", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>

        <Form.Item
          name="identifier"
          label="شناسه برند"
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
        >
          <Field name="identifier">
            {({ field }: FieldProps) => (
              <Input
                {...field}
                placeholder="شناسه برند"
                value={values.identifier}
                onChange={(e) => setFieldValue("identifier", e.target.value)}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          name="isActive"
          label="وضعیت برند"
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
        >
          <Field name="isActive">
            {({ field }: FieldProps) => (
              <Switch
                {...field}
                checked={values.isActive}
                onChange={(checked) => setFieldValue("isActive", checked)}
              />
            )}
          </Field>
        </Form.Item>
      </Form>
    </FormikProvider>
  );
});

export default BrandForm;
