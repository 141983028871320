export const removeNulls = (data: any) => {
  if (!data) return undefined;

  return JSON.parse(JSON.stringify(data).replace(/\:null/gi, ":false"));
};

export const getImageSrc = (src: string | undefined, addHtml = false) => {
  if ((src ?? "").includes("http")) {
    return src;
  }

  return addHtml
    ? `${process.env.REACT_APP_BASE_URL}${src}`
    : "/assets/images/alt.svg";
};

export const isObjectEmpty = (objectName: object) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};
