import { Button, Flex, Input, Table } from "antd";
import { IconSearch } from "components/icons/iconSearch";
import styles from "./index.module.scss";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetProductCategories } from "core/services/product-category/useGetProductCategories";
import AddProductCategoryModal from "../AddProductCategoryModal";
import { IconDelete } from "components/icons/iconDelete";
import { getImageSrc } from "core/utils/json-utils";
import { BrandAvatar } from "ui";
import { useDeleteProductCategory } from "core/services/product-category/useDeleteProductCategory";
import { PRODUCT_CATEGORIES_ENDPOINTS } from "core/constants/endpoints";

const ProductCategoryTable = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const { data: productCategories, isPending } = useGetProductCategories({
    queryKey: [{ page, search }, PRODUCT_CATEGORIES_ENDPOINTS.list],
  });
  const { mutate: deleteProductCategory } = useDeleteProductCategory();

  const goToEditPage = (id) => {
    navigate(`/catalog/product_category/${id}/edit`);
  };

  const getColumns = useCallback(
    () => [
      {
        title: <div className={styles["column"]}>نام دسته بندی و ویژگی</div>,
        render: (data) => {
          return (
            <Flex align="center" gap="1.5rem">
              <div>
                <BrandAvatar
                  img={getImageSrc(data?.image?.url, true)}
                  label={data?.name}
                />
              </div>
              <div className={styles["category_name_info"]}>
                <div>{data.name}</div>
                <div>محصولات صنعتی</div>
              </div>
            </Flex>
          );
        },
      },
      {
        title: <div className={styles["column"]}>نام مستعار</div>,
        render: (data) => {
          return (
            <div className={styles["featured_title"]}>
              {data?.featuredTitle ?? "-"}
            </div>
          );
        },
      },
      {
        title: <div className={styles["column"]}>تعداد نمایش سفارش</div>,
        render: (data) => {
          const tmpNumber = data?.productCount ?? 0;

          return tmpNumber < 10 ? (
            <div className={styles["low_show_count"]}>
              <div>{tmpNumber}</div>
              <div>تعداد محدود</div>
            </div>
          ) : (
            <div className={styles["high_show_count"]}>{tmpNumber}</div>
          );
        },
      },
      {
        title: <div className={styles["column"]}>محدود به فروشگاه</div>,
        algin: "center",
        render: (data) => {
          return <div className="text-center">-</div>;
        },
      },
      {
        title: <div className={styles["column"]}> عملیات</div>,
        render: (data) => (
          <div className={styles["actions"]}>
            <Button onClick={() => goToEditPage(data.id)}>درج مشخصات</Button>
            <div
              className={styles["delete"]}
              onClick={() => deleteProductCategory({ id: data.id })}
            >
              <IconDelete color="#F5222D" />
            </div>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <div
      style={{
        marginBlock: 30,
      }}
    >
      <div className={styles["table_filter"]}>
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          addonBefore={<IconSearch />}
          placeholder="جستجوی دسته بندی"
        />

        <AddProductCategoryModal />
      </div>
      <Table
        locale={{ emptyText: "هیچ داده ای وجود ندارد" }}
        className={styles["table"]}
        columns={getColumns()}
        dataSource={productCategories?.data?.data ?? []}
        loading={isPending}
        pagination={{
          showSizeChanger: false,
          total: productCategories?.data?.totals ?? 0,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />
    </div>
  );
};

export default ProductCategoryTable;
