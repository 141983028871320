import { useMemo } from "react";

import { Flex, Table } from "antd";

import { PaymentStateLabel } from "ui";
import { TRANSACTION_TABLE_COLUMN } from "../../../constant";
interface TransactionsTableProp {
  data: any;
}
export const TransactionsTable: React.FC<TransactionsTableProp> = ({
  data,
}) => {
  const dataSource = useMemo(() => {
    return data?.map((item: any) => {
      return {
        id: <label className="color-stroke">{item.id}</label>,
        status: (
          <Flex align="center" justify="center">
            <PaymentStateLabel state={item.status} />
          </Flex>
        ),
        price: <label className="color-stroke">{item.price}</label>,
        point: <label className="color-stroke">{item.point}</label>,
        date: <label className="color-stroke">{item.date}</label>,
      };
    });
  }, [data]);
  return <Table dataSource={dataSource} columns={TRANSACTION_TABLE_COLUMN} />;
};
