import { Col, Row, Tabs } from "antd";
import { DUMMY_DATA } from "../../vendees-management/api/dummy-data";
import { DetailsCard } from "./details";
import { OverView } from "./overview";
import { Setting } from "./setting";
import { AdvanceSetting } from "./advance-setting/components";

export const VendeesDetails = () => {
  const TABS = [
    {
      key: "overview",
      label: "نمای کلی",
      children: <OverView />,
    },
    {
      key: "settings",
      label: "تنظیمات ",
      children: <Setting />,
    },
    {
      key: "advance-setting",
      label: "تنظیمات پیشرفته",
      children: <AdvanceSetting />,
    },
  ];
  return (
    <div>
      <Row gutter={[25, 25]}>
        <Col span={6}>
          <DetailsCard {...DUMMY_DATA[0]} />
        </Col>
        <Col span={18}>
          <Tabs defaultActiveKey="advance-setting" items={TABS} />
        </Col>
      </Row>
    </div>
  );
};
