import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { DASHBOARD_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { UserSetting } from "domain/api/dashboard/userSetting";

type QueryOptionsWithoutQueryFn = Omit<UseQueryOptions<UserSetting>, "queryFn">;

export const useGetUserSettings = (options?: QueryOptionsWithoutQueryFn) =>
  useQuery<UserSetting>({
    retry: 0,
    ...(options ?? {}),
    queryFn: async () => {
      const result = await instance.get(DASHBOARD_END_POINTS.user_setting);

      return result.data?.data ?? {};
    },
    queryKey: options?.queryKey
      ? [DASHBOARD_END_POINTS.user_setting, ...options.queryKey]
      : [DASHBOARD_END_POINTS.user_setting],
  });
