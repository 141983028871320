import React from "react";
import { Divider, Flex, Typography } from "antd";
import { Icon } from "ui";

interface CustomerDetailsBoxProp {
  name: string;
  email: string;
  phoneNumber: string;
}
export const CustomerDetailsBox: React.FC<CustomerDetailsBoxProp> = ({
  name,
  email,
  phoneNumber,
}) => {
  return (
    <div className="box h-100">
      <h1> جزئیات مشتری</h1>
      <br />
      <br />
      <Flex className="color-stroke" justify="space-between">
        <Flex align="center" gap={10}>
          <Icon type="sr-user" size={21} />
          <Typography className="color-stroke"> مشتری : </Typography>
        </Flex>
        <Typography className="color-stroke"> {name} </Typography>
      </Flex>
      <Divider />
      <Flex className="color-stroke" justify="space-between">
        <Flex align="center" gap={10}>
          <Icon type="sr-email" size={21} />
          <Typography className="color-stroke"> ایمیل : </Typography>
        </Flex>
        <Typography className="color-stroke"> {email} </Typography>
      </Flex>
      <Divider />
      <Flex className="color-stroke" justify="space-between">
        <Flex align="center" gap={10}>
          <Icon type="sr-phonecall-ringing" size={21} />
          <Typography className="color-stroke"> شماره تماس : </Typography>
        </Flex>
        <Typography className="color-stroke"> {phoneNumber} </Typography>
      </Flex>
    </div>
  );
};
