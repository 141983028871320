export const IconMapPin = ({ size = 24, color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox="0 0 9.861 11.833"
  >
    <g transform="translate(-2)">
      <path
        d="M9.972,6a1.972,1.972,0,1,0,1.972,1.972A1.972,1.972,0,0,0,9.972,6ZM8.986,7.972a.986.986,0,1,1,.986.986A.986.986,0,0,1,8.986,7.972Z"
        transform="translate(-3.042 -3.042)"
        fill="#d6d7dd"
        fillRule="evenodd"
      />
      <path
        d="M6.931,0A4.882,4.882,0,0,0,2,4.832a7.862,7.862,0,0,0,2.153,4.8,12.526,12.526,0,0,0,1.566,1.536,6.119,6.119,0,0,0,.621.45,2.212,2.212,0,0,0,.261.14.727.727,0,0,0,.659,0,2.212,2.212,0,0,0,.261-.14,6.119,6.119,0,0,0,.621-.45A12.528,12.528,0,0,0,9.708,9.628a7.862,7.862,0,0,0,2.153-4.8A4.882,4.882,0,0,0,6.931,0ZM2.986,4.832A3.9,3.9,0,0,1,6.931.986a3.9,3.9,0,0,1,3.944,3.846A6.912,6.912,0,0,1,8.96,8.985,11.547,11.547,0,0,1,7.521,10.4,5.165,5.165,0,0,1,7,10.774l-.074.043-.074-.043A5.165,5.165,0,0,1,6.34,10.4,11.548,11.548,0,0,1,4.9,8.985,6.912,6.912,0,0,1,2.986,4.832Z"
        fill="#d6d7dd"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
