export const IconEye = ({ size = 24, color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.177"
    height="12.133"
    viewBox="0 0 16.177 12.133"
  >
    <g id="sr-eye-open" transform="translate(0 -3)">
      <path
        id="Path_564"
        data-name="Path 564"
        d="M13.74,10.37A3.37,3.37,0,1,1,10.37,7,3.37,3.37,0,0,1,13.74,10.37Zm-1.348,0A2.022,2.022,0,1,1,10.37,8.348,2.022,2.022,0,0,1,12.392,10.37Z"
        transform="translate(-2.282 -1.304)"
        fill="#30b9d3"
        fillRule="evenodd"
      />
      <path
        id="Path_565"
        data-name="Path 565"
        d="M16.177,9.066c0,1.685-3.621,6.066-8.088,6.066S0,11.088,0,9.066,3.621,3,8.088,3,16.177,7.381,16.177,9.066Zm-1.348,0h0a1.126,1.126,0,0,1-.075.261A3.963,3.963,0,0,1,14.4,10a8.407,8.407,0,0,1-1.491,1.721,7.477,7.477,0,0,1-4.822,2.059A7.579,7.579,0,0,1,3.25,11.837a7.479,7.479,0,0,1-1.467-1.678,2.461,2.461,0,0,1-.435-1.093,2.461,2.461,0,0,1,.435-1.093A7.48,7.48,0,0,1,3.25,6.3,7.58,7.58,0,0,1,8.088,4.348,7.477,7.477,0,0,1,12.91,6.407,8.408,8.408,0,0,1,14.4,8.128a3.963,3.963,0,0,1,.352.677A1.126,1.126,0,0,1,14.828,9.066Z"
        fill="#30b9d3"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
