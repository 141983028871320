import React from "react";
import { Button as AntdButton, ButtonProps } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import styles from "./index.module.scss";
import classNames from "classnames";

interface buttonProp extends Omit<ButtonProps, "type"> {
  type?:
    | "success"
    | "primary-50"
    | "success-75"
    | "warning"
    | "error"
    | ButtonProps["type"];
  size?: SizeType;
  variant?: "link" | "text" | "dashed" | "outlined" | "solid" | "filled";
  // variant?: "contained" | "outline";
}
export const Button: React.FC<buttonProp> = ({
  children,
  className,
  type = "default",
  size,
  variant = "filled",
  disabled,
  ...rest
}) => {
  const ButtonClassName = classNames(className, [
    disabled && styles[variant + "-default-disabled"],
    type === "error" && styles[variant + "-error"],
    type === "primary" && styles[variant + "-primary"],
    type === "primary-50" && styles[variant + "-primary-50"],
    type === "success" && styles[variant + "-success"],
    type === "success-75" && styles[variant + "-success-75"],
    type === "warning" && styles[variant + "-warning"],
    size === "large" && styles.large,
  ]);
  return (
    <AntdButton
      disabled={disabled}
      className={ButtonClassName}
      type={
        type === "error" ||
        type === "success" ||
        type === "success-75" ||
        type === "primary-50" ||
        type === "warning"
          ? undefined
          : type
      }
      {...rest}
    >
      {children}
    </AntdButton>
  );
};
