import { Flex, Input, Form, Divider } from "antd";
import { Field, FieldProps, useFormik, FormikProvider } from "formik";

import { Button, Icon } from "ui";

export const SecurityLogin = () => {
  const [form] = Form.useForm();
  const formik = useFormik({
    initialValues: {
      phoneNumber: "09305429530",
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });
  return (
    <div className="box">
      <h1>امنیت ورود به سیستم</h1>
      <br />
      <Flex align="center" justify="space-between">
        <label>ورود با اثر انگشت</label>
        <Button type="success-75" size="large">
          <Icon type="sr-qrscan-add" size={22}/>
          تطبیق اثر انگشت
        </Button>
      </Flex>

      <FormikProvider value={formik}>
        <Form form={form} className="box">
          <Form.Item
            name="phoneNumber"
            label="ارسال کد از طریق SMS"
            required
            labelCol={{ span: 4 }}
            labelAlign="left"
            wrapperCol={{ span: 21 }}
          >
            <Field name="phoneNumber">
              {({ field }: FieldProps) => <Input {...field} />}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>
      <Divider className="mt-0" />
      <label className="color-stroke">
        اگر دستگاه تلفن همراه یا کلید امنیتی خود را گم کردید، می توانید یک کد
        پشتیبان برای ورود به حساب خود ایجاد کنید.
      </label>
    </div>
  );
};
