import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { RequestDTO, ResponseDTO } from "interface";
import { ROLE_END_POINTS } from "core/constants/endpoints";
import { role, roleManagementFilter } from "../type";

type rolesRequestType = RequestDTO<roleManagementFilter>;
const getRoles = async (
  rolesRequestType: rolesRequestType
): Promise<ResponseDTO<role[]>> => {
  const res = await instance.post(ROLE_END_POINTS.grid, rolesRequestType);

  return res.data;
};

type UseGetUserOptions = UseQueryOptions<
  ResponseDTO<role[]>,
  unknown,
  ResponseDTO<role[]>
>;
export const useGetRoles = (
  params: rolesRequestType,
  config?: UseGetUserOptions
) => {
  return useQuery({
    queryKey: [
      ROLE_END_POINTS.grid,
      params.page,
      params.pageSize,
      ...(params.inputParams.filters ? params.inputParams.filters : []),
    ],
    queryFn: () => getRoles(params),
    ...config,
  });
};
