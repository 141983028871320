import { Button, Form, Input, Modal, Select } from "antd";
import { Icon } from "ui";
import { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useAddProduct } from "core/services/product/useAddProduct";
import { Field, FormikProvider, useFormik } from "formik";
import { FieldError } from "ui/field-error";
import InfiniteSelect from "ui/infinite-select";
import { useGetProductCategories } from "core/services/product-category/useGetProductCategories";
import { addProductSchema } from "pages/catalog/AddProduct/schema";

import styles from "./index.module.scss";

const AddProductModal = ({ onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm();

  const formik = useFormik({
    initialValues: {
      productCategoriesPage: 1,
      productCategoriesSearch: "",
      name: undefined,
      productCategoryId: undefined,
    },
    validationSchema: addProductSchema,
    onSubmit: (values) => {
      mutate(
        { name: values.name, productCategoryId: values.productCategoryId },
        {
          onSuccess: (res) => {
            toast.success("محصول با موفقیت ایجاد گردید");
            handleCancel();
          },
        }
      );
    },
  });
  const { setFieldValue, values, errors, touched, submitForm, resetForm } =
    formik;

  const { data: cats, isPending: fetchingCats } = useGetProductCategories({
    queryKey: [
      {
        page: values.productCategoriesPage,
        search: values.productCategoriesSearch,
      },
    ],
  });

  const { mutate, isPending } = useAddProduct();

  const catsOptions = useMemo(
    () =>
      (cats?.data?.data ?? []).map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [cats]
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    resetForm();
    setIsModalOpen(false);
    onClose?.();
  };

  return (
    <>
      <Button onClick={showModal}>
        <Icon type="sr-plus" />
        <span>افزودن محصول جدید</span>
      </Button>
      <Modal
        title={"افزودن محصول جدید"}
        open={isModalOpen}
        okButtonProps={{
          loading: isPending,
        }}
        onOk={() => submitForm()}
        onCancel={handleCancel}
        width={650}
        footer={() => (
          <>
            <Button onClick={handleCancel}>لغو</Button>
            <Button
              loading={isPending}
              type="primary"
              onClick={() => submitForm()}
            >
              {"ثبت"}
            </Button>
          </>
        )}
      >
        <FormikProvider value={formik}>
          <Form className={styles["add_product_form"]} form={form}>
            <Form.Item
              name="name"
              label=":نام محصول "
              labelCol={{ span: 6 }}
              labelAlign="left"
              wrapperCol={{ span: 18 }}
            >
              <Field name="name">
                {({ field }) => (
                  <Input
                    {...field}
                    className={styles["add_produc_name_modal"]}
                    value={values.name}
                    onChange={(e) => setFieldValue("name", e.target.value)}
                  />
                )}
              </Field>
              <FieldError
                show={errors.name && touched.name}
                text={errors.name}
              />
            </Form.Item>
            <Form.Item
              name="productCategoryId"
              label=" :دسته  بندی محصول "
              labelCol={{ span: 6 }}
              labelAlign="left"
              wrapperCol={{ span: 18 }}
            >
              <Field name="productCategoryId">
                {({ field }) => (
                  <InfiniteSelect
                    args={field}
                    placeholder="   لطفا انتخاب کنید  "
                    onChange={(value) =>
                      setFieldValue("productCategoryId", value?.value)
                    }
                    options={catsOptions}
                    onInputChange={(phrase) => {
                      setFieldValue("productCategoriesSearch", phrase);
                    }}
                    isLoading={fetchingCats}
                    onPageChange={(newPage) => {
                      setFieldValue("productCategoriesPage", newPage);
                    }}
                    page={values.productCategoriesPage}
                  />
                )}
              </Field>
              <FieldError
                show={errors.productCategoryId && touched.productCategoryId}
                text={errors.productCategoryId}
              />
            </Form.Item>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
};
export default AddProductModal;
