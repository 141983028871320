import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { PRODUCT_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { toast } from "react-toastify";

export const useAddProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return instance.post(PRODUCT_ENDPOINTS.add, {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        PRODUCT_ENDPOINTS.list,
      ] as InvalidateQueryFilters);
    },
    onError: (e: AxiosError<ErrorDTO>) => {
      console.log("Add Product onError", e);
      toast.error(`${e.response?.data?.Message || "خطا در ایجاد محصول"}`);
    },
  });
};
