import { AlignType } from "rc-table/lib/interface";
export const VENDEES_TABLE_COLUMN = [
  {
    title: "نام خریدار",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "ایمیل",
    dataIndex: "email",
    key: "email",
    align: "center" as AlignType,
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    align: "center" as AlignType,
  },
  {
    title: "شناسه",
    dataIndex: "id",
    key: "id",
    align: "center" as AlignType,
  },
  {
    title: "آی پی آدرس",
    dataIndex: "ipAddress",
    key: "ipAddress",
    align: "center" as AlignType,
  },
  {
    title: "تاریخ عضویت",
    dataIndex: "initialDate",
    key: "initialDate",
    align: "center" as AlignType,
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
    align: "center" as AlignType,
  },
];
