import { Flex } from "antd";
import { DoughnutChart } from "ui";

import styles from "../../index.module.scss";

export const AllAdvertiseViewsCard = () => {
    const labels= ["ضعیف", "قوی", "خیلی خوب", false] 
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Sample Data",

        data: [26, 26, 48, 26],
        backgroundColor: ["#30B9D3", "#FF6600", "#73D13D", "#E4E6EF"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Flex
      vertical
      justify="space-between"
      gap={20}
      className={`${styles.card} box`}
    >
      <div>
        <h1>15,688,372</h1>
        <label className="color-stroke">تعداد کلی تبلیغات روی سایت</label>
      </div>
      <div  >
        <DoughnutChart data={data} />
      </div>
    </Flex>
  );
};
