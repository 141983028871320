export const DUMMY_DATA = [
  {
    name: "جناب آقای هوشیار",
    email: "alikolahdozha@gmail.com",
    id: "094984111",
    ipAddress: "209.68.88.228",
    status: true,
    initialDate: "02/10/1402 02:50",
    address:
      "تهران، خیابان آرژانتین، خیابان کرمان، میدان ابوذر پلاک12واحد 12 کد پستی: 664433535",
    lastTransaction: "#14534",
  },
  {
    name: "شرکت کاله",
    email: "alikolahdozha@gmail.com",
    id: "456949845",
    ipAddress: "209.68.88.228",
    status: true,
    initialDate: "02/10/1402 02:50",
    address:
      "تهران، خیابان آرژانتین، خیابان کرمان، میدان ابوذر پلاک12واحد 12 کد پستی: 664433535",
    lastTransaction: "#14534",
  },
  {
    name: "سر کار خانم محمدی",
    email: "alikolahdozha@gmail.com",
    id: "094984111",
    ipAddress: "209.68.88.228",
    status: false,
    initialDate: "02/10/1402 02:50",
    address:
      "تهران، خیابان آرژانتین، خیابان کرمان، میدان ابوذر پلاک12واحد 12 کد پستی: 664433535",
    lastTransaction: "#14534",
  },
  {
    name: "سر کار خانم قاسمی",
    email: "alikolahdozha@gmail.com",
    id: "094436451",
    ipAddress: "209.68.88.228",
    status: true,
    initialDate: "02/10/1402 02:50",
    address:
      "تهران، خیابان آرژانتین، خیابان کرمان، میدان ابوذر پلاک12واحد 12 کد پستی: 664433535",
    lastTransaction: "#14534",
  },
  {
    name: "سر کار خانم محبی",
    email: "alikolahdozha@gmail.com",
    id: "094984111",
    ipAddress: "209.68.88.228",
    status: true,
    initialDate: "02/10/1402 02:50",
    address:
      "تهران، خیابان آرژانتین، خیابان کرمان، میدان ابوذر پلاک12واحد 12 کد پستی: 664433535",
    lastTransaction: "#14534",
  },
];
