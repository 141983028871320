import { Divider, Flex, Form, Input } from "antd";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import { BannerUpload, Button, LexicalEditor } from "ui";
import { getImageSrc } from "core/utils/json-utils";
import { useGetAboutUs, useUpdateAboutUs } from "../api";
import { AboutUsType } from "../type";

export const AboutUsManagement = () => {
  const { data } = useGetAboutUs();
  const { mutate, isPending: updating } = useUpdateAboutUs();

  const [form] = Form.useForm();
  const formik = useFormik<AboutUsType>({
    initialValues: {
      title: data?.data?.title ?? "",
      text: data?.data?.text ?? "",
      image: data?.data?.image,
      uploadImage: undefined,
    },
    enableReinitialize: true,
    onSubmit: (values) => mutate(values),
  });
  const { setFieldValue, values, submitForm } = formik;

  return (
    <div className="box">
      <h1>مدیریت بخش درباره ما</h1>
      <Divider />

      <FormikProvider value={formik}>
        <Form form={form}>
          <Form.Item
            name="uploadImage"
            valuePropName="uploadImage"
            label="تصویر بنر"
            labelAlign="left"
            labelCol={{ span: 2 }}
          >
            <BannerUpload
              key={values.image?.url ?? "-"}
              value={
                values.image?.url
                  ? {
                      width: values.image!.width,
                      height: values.image!.width,
                      preview: getImageSrc(values.image?.url, true),
                    }
                  : undefined
              }
              name="uploadImage"
              title="تصویر بنر"
              listType="picture-card"
              className="avatar-uploader"
              onBannerChange={(banner) => {
                setFieldValue("image", undefined);
                setFieldValue("uploadImage", banner);
              }}
            />
          </Form.Item>
          <Form.Item
            name="title"
            label="تیتر یا عنوان"
            labelAlign="left"
            labelCol={{ span: 2 }}
          >
            <Field name="title">
              {({ field }: FieldProps) => (
                <Input
                  {...field}
                  value={values.title}
                  placeholder="نوشتن متن"
                  onChange={(e) => setFieldValue("title", e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
          <Form.Item
            name="text"
            label="توضیحات"
            labelAlign="left"
            labelCol={{ span: 2 }}
          >
            <Field name="text">
              {({ field }: FieldProps) => (
                <LexicalEditor
                  {...field}
                  onChange={(html: string) => setFieldValue("text", html)}
                  value={data?.data?.text}
                />
              )}
            </Field>
          </Form.Item>
        </Form>
      </FormikProvider>

      <Divider />
      <Flex justify="flex-end" gap={10}>
        <Button size="large">لغو کردن</Button>
        <Button
          type="primary"
          size="large"
          loading={updating}
          onClick={() => submitForm()}
        >
          ذخیره تغییرات
        </Button>
      </Flex>
    </div>
  );
};
