import { toast } from "react-toastify";
import { Button } from "antd";
import { Modal } from "ui";

import { useAddUser } from "../../api";
import { useUserManagementStore } from "../../store";
import { UserForm } from "../form";
import { useUpdateUser } from "../../api/useUpdateUser";

const UserManagementModal = () => {
  const { setFormData, formData, isEdit } = useUserManagementStore(
    (state) => state
  );
  const updateUserQuery = useUpdateUser();
  const addUserQuery = useAddUser();
  const loading = isEdit ? updateUserQuery.isPending : addUserQuery.isPending;
  const handleOk = (onSuccess: () => void) => {
    if (formData) {
      if (isEdit) {
        updateUserQuery.mutate(
          {
            data: formData,
          },
          {
            onSuccess: () => {
              onSuccess();
            },
          }
        );
      } else {
        addUserQuery.mutate(
          {
            data: formData,
          },
          {
            onSuccess: () => {
              onSuccess();
            },
          }
        );
      }
    } else {
      toast.error("لطفا فیلد ها را کامل کنید");
    }
  };

  return (
    <Modal
      name="USER_MANAGEMENT_MODAL"
      title={isEdit ? "ویرایش کاربر" : "افزودن کاربر جدید"}
      okButtonProps={{
        loading: loading,
      }}
      onClose={() => {
        setFormData(undefined);
      }}
      footer={(cancel, ok) => (
        <>
          <Button
            onClick={() => {
              cancel();
            }}
          >
            لغو
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              handleOk(ok);
            }}
          >
            {isEdit ? "ویرایش" : "ثبت"}
          </Button>
        </>
      )}
    >
      <UserForm
        formData={formData}
        onFormChange={(newVal) => setFormData(newVal)}
      />
    </Modal>
  );
};
export default UserManagementModal;
