import { Input, Form, Switch, Space } from "antd";
import { Field, FieldProps, useFormik, FormikProvider } from "formik";

export const VendeeForm = () => {
  const [form] = Form.useForm();
  const formik = useFormik({
    initialValues: {
      addToAccount: true,
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form form={form}>
        <Form.Item
          name="name"
          label="نام"
          required
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
        >
          <Field name="name">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="email"
          label="ایمیل"
          required
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
        >
          <Field name="email">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="description"
          label="توضیحات"
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
        >
          <Field name="description">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <strong>اطلاعات حمل و نقل</strong>
        <br />
        <br />
        <Form.Item
          name="addressName"
          label="نام آدرس"
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
          required
        >
          <Field name="addressName">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="address"
          label="نام آدرس"
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
          required
        >
          <Field name="address">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="city"
          label="نام آدرس"
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
          required
        >
          <Field name="city">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="province"
          label="استان"
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
          required
        >
          <Field name="province">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="postalId"
          label="استان"
          labelCol={{ span: 4 }}
          labelAlign="left"
          wrapperCol={{ span: 20 }}
          required
        >
          <Field name="postalId">
            {({ field }: FieldProps) => <Input {...field} />}
          </Field>
        </Form.Item>
        <Form.Item
          name="addToAccount"
          label="افزودن به عنوان آدرس صورت حساب"
          labelCol={{ span: 20 }}
          labelAlign="left"
          wrapperCol={{ span: 4 }}
          required
        >
          <Field name="addToAccount">
            {({ field }: FieldProps) => (
              <Space>
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(checked) =>
                    form.setFieldValue(field.name, checked)
                  }
                />
                <label className="color-stroke">
                  {field.value ? "بله" : "خیر"}
                </label>
              </Space>
            )}
          </Field>
        </Form.Item>
      </Form>
    </FormikProvider>
  );
};
