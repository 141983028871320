import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import instance from "core/constants/request";
import { RequestDTO, ResponseDTO } from "interface";
import { BRAND_END_POINTS } from "core/constants/endpoints";
import { brand, brandManagementFilter } from "../type";

type brandsRequestType = RequestDTO<brandManagementFilter>;
const getBrands = async (
  brandsRequestType: brandsRequestType
): Promise<ResponseDTO<brand[]>> => {
  const res = await instance.post(
    BRAND_END_POINTS.brands_list,
    brandsRequestType
  );

  return res.data;
};

type UseGetUserOptions = UseQueryOptions<
  ResponseDTO<brand[]>,
  unknown,
  ResponseDTO<brand[]>
>;
export const useGetBrands = (
  params: brandsRequestType,
  config?: UseGetUserOptions
) => {
  return useQuery({
    queryKey: [
      BRAND_END_POINTS.brands_list,
      params.page,
      params.pageSize,
      ...(params.inputParams.filters ? params.inputParams.filters : []),
    ],
    queryFn: () => getBrands(params),
    ...config,
  });
};
