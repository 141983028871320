export const IconSearch = ({ size = "19.306", color = "#b5b5c3" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.306"
    height={size}
    viewBox="0 0 19.306 19.306"
  >
    <path
      id="sr-search"
      d="M15.329,16.57a8.775,8.775,0,1,1,1.241-1.241l3.479,3.479a.878.878,0,0,1-1.241,1.241ZM16.8,9.775a7.02,7.02,0,1,1-7.02-7.02A7.02,7.02,0,0,1,16.8,9.775Z"
      transform="translate(-1 -1)"
      fill="#b5b5c3"
      fillRule="evenodd"
    />
  </svg>
);
