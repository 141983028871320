import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { SEO_MANAGEMENT_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { menuSettingForm } from "../type";

const updateFn = async (data: menuSettingForm): Promise<any> =>
  instance.put(SEO_MANAGEMENT_ENDPOINTS.update, {
    id: data.id,
    parentId: data.parentId ?? null,
    name: data.name,
    description: "",
    isActive: true,
    priority: data.priority,
    hasUrl: true,
    url: data.url,
  });

export const useUpdateMenu = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateFn,
    onSuccess: () => {
      toast.success("منو با موفقیت ویرایش گردید");
      queryClient.invalidateQueries([
        SEO_MANAGEMENT_ENDPOINTS.list,
      ] as InvalidateQueryFilters);
    },
    onError: ({ response }: AxiosError<ErrorDTO>) => {
      toast.error(`${response?.data?.Message || "خطا در ویرایش منو"}`);
    },
  });
};
