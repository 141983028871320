import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BRAND_END_POINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO } from "interface";
import { brandForm } from "../type";

const addBrand = async ({ data }: { data: brandForm }) => {
  const formData = new FormData();
  formData.append("AltText", "");
  formData.append("Name", data.name);
  formData.append("Link", data.localLink);
  formData.append("Identifier", data.identifier);
  formData.append("IsActive", String(data.isActive ?? false));
  formData.append("Description", data?.description ?? "");

  if (data.image) {
    formData.append("Width", String(data.image.width));
    formData.append("Height", String(data.image.height));
    formData.append("SizePercentage", "100");
    formData.append("File", data.image.file ?? "");
    // TODO(hossein): separate thumbnail from image
    formData.append("Thumbnail.Width", String(data.image.width));
    formData.append("Thumbnail.Height", String(data.image.height));
    formData.append("Thumbnail.SizePercentage", "100");
    formData.append("Thumbnail.File", data.image.file ?? "");
  }

  const res = await instance.post(BRAND_END_POINTS.add_brand, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const useAddBrand = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [BRAND_END_POINTS.add_brand, BRAND_END_POINTS.brands_list],
    mutationFn: addBrand,
    onError: (e: AxiosError<ErrorDTO>) => {
      console.error(e);
      toast.error(`${e.response?.data.Message || "خطا در ایجاد برند"}`);
    },
    onSuccess: () => {
      toast.success(`برند با موفقیت افزوده شد`);
      queryClient.invalidateQueries({
        queryKey: [BRAND_END_POINTS.brands_list],
      });
    },
  });
};
