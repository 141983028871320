import * as Yup from "yup";

export const addProductSchema = Yup.object().shape({
  productCategoryId: Yup.number().required("لطفا دسته بندی را انتخاب کنید"),
  name: Yup.string().required("لطفا نام محصول را وارد کنید"),
});

export const productTechSpecSchema = Yup.object().shape({
  technicalSpecificationId: Yup.number().required("لطفا ویژگی را انتخاب کنید"),
  value: Yup.number().required("لطفا مقدار را وارد کنید"),
});

export const productRelationsSchema = Yup.object().shape({
  relatedProductId: Yup.number().required("لطفا محصول را انتخاب کنید"),
  description: Yup.string().required("لطفا توضیحات را وارد کنید"),
});

export const productRelationsUpdateSchema = Yup.object().shape({
  title: Yup.string().required("لطفا عنوان را وارد کنید"),
  description: Yup.string().required("لطفا توضیحات را وارد کنید"),
});

export const productPriceRangeSchema = Yup.object().shape({
  pricingMethod: Yup.number(),
  price: Yup.number().when(
    "pricingMethod",
    ([pricingMethod], schema: Yup.NumberSchema): Yup.NumberSchema =>
      pricingMethod === 0
        ? Yup.number().required("مبلغ پایه را وارد کنید")
        : schema
  ),
  minPrice: Yup.number().when(
    "pricingMethod",
    ([pricingMethod], schema: Yup.NumberSchema): Yup.NumberSchema =>
      pricingMethod === 1
        ? Yup.number().required("مبلغ حداقل را وارد کنید")
        : Yup.number()
  ),
  maxPrice: Yup.number().when(
    "pricingMethod",
    ([pricingMethod], schema: Yup.NumberSchema): Yup.NumberSchema =>
      pricingMethod === 1
        ? Yup.number().required("مبلغ حداکثر را وارد کنید")
        : Yup.number()
  ),
});

export const productDiscountSchema = Yup.object().shape({
  discountType: Yup.number(),
  discount: Yup.number().required("لطفا مقدار تخفیف را وارد کنید"),
  discountDescription: Yup.string().required("لطفا علت تخفیف را ذکر کنید"),
});
