import React from "react";
import { Flex, Select } from "antd";

import styles from "../index.module.scss";
interface DetailsFormProp {
  id: string;
}
export const DetailsForm: React.FC<DetailsFormProp> = ({ id }) => {
  return (
    <Flex vertical gap={25} className={styles.details_form_container}>
      <h1>جزئیات سفارش</h1>
      <Flex vertical gap={10}>
        <label> شماره سفارش :</label>
        <h1>{id}</h1>
      </Flex>
      <Flex vertical gap={10}>
        <h1>روش پرداخت</h1>
        <Select placeholder="یک گزینه را انتخاب کنید" />
        <label className="color-stroke">
          یکی از اروش های پرداخت را برای مشتری انتخاب کنید
        </label>
      </Flex>
      <Flex vertical gap={10}>
        <h1>روش ارسال کالا</h1>
        <Select placeholder="یک گزینه را انتخاب کنید" />
        <label className="color-stroke">نحوه ارسال بار را انتخاب کنید</label>
      </Flex>
    </Flex>
  );
};
