import { Flex } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem 15px;
  background: #ffffff;
  border-radius: 12px;

  .header-title {
    font-weight: bold;
    margin-right: 0.8rem;
  }
`;