import {
  InvalidateQueryFilters,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { TECH_SPEC_ENDPOINTS } from "core/constants/endpoints";
import instance from "core/constants/request";
import { ErrorDTO, SimpleResponseDTO } from "interface";
import { specDeleteType } from "../type";

type QueryOptionsWithoutMutationFn = Omit<
  UseMutationOptions<
    SimpleResponseDTO<number>,
    AxiosError<ErrorDTO>,
    specDeleteType,
    unknown
  >,
  "mutationFn"
>;

export const useDeleteTechnicalSpecification = (
  options?: QueryOptionsWithoutMutationFn
) => {
  const queryClient = useQueryClient();

  return useMutation<
    SimpleResponseDTO<number>,
    AxiosError<ErrorDTO>,
    specDeleteType
  >({
    retry: 0,
    ...options,
    mutationFn: async (data: specDeleteType) => {
      const result = await instance.delete<SimpleResponseDTO<number>>(
        `${TECH_SPEC_ENDPOINTS.delete}?id=${data.id}`
      );

      return result.data;
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries([
        TECH_SPEC_ENDPOINTS.list,
      ] as InvalidateQueryFilters);
      toast.success(`آیتم با موفقیت حذف گردید`);
    },
    onError: (e: AxiosError<ErrorDTO>) => {
      toast.error(`${e.response?.data?.Message || "خطا در حذف آیتم"}`);
    },
  });
};
